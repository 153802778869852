import { allowedBonds, primary, secondary } from 'models/CurrencyBond/data'
import { getFormattedDate } from './formatDate'

export const getSymbolById = (id) => {
  const currencies = primary.concat(secondary)
  const currency = currencies.find((element) => element.id === id)
  return currency?.symbol ?? ''
}

const lastUpdate = (date) => {
  const { withZero } = getFormattedDate(date)
  return `${withZero.day}.${withZero.month}.${withZero.year} - ${withZero.hour}:${withZero.minutes}`
}

const formatDataCurrencies = (currencies) => {
  return currencies
    .map((currency) => {
      const [day, month, year] = currency.data.split('/')
      const [hour, minute] = currency.hora.split(':')
      const update = lastUpdate(new Date(year, month - 1, day, hour, minute))
      return {
        id: currency.papel,
        symbol: getSymbolById(currency.papel),
        sellingRate: currency.ultimo,
        buyingRate: currency.compra,
        variation: currency.variacao,
        last: currency.fechant,
        description: currency.descricao,
        update,
      }
    })
    .filter((currency) => currency.id !== 'DLRBLE')
}

const formatDataBlueTourist = (blueTourist) => {
  const dataBlueTourist = blueTourist[0]?.data
  const prevDataBlueTourist = blueTourist[0]?.prevDate
  return [
    {
      id: 'DLRBLE',
      symbol: getSymbolById('DLRBLE'),
      sellingRate: dataBlueTourist?.blue_venta?.replace(/\$/g, '') ?? '',
      buyingRate: dataBlueTourist?.blue_compra?.replace(/\$/g, '') ?? '',
      variation: getVariation(
        dataBlueTourist?.blue_venta,
        prevDataBlueTourist?.blue_venta,
      ),
      last: prevDataBlueTourist?.blue_venta?.replace(/\$/g, '') ?? '',
      update: lastUpdate(blueTourist?.[0]?.modificated ?? new Date()),
      description: 'Dólar Blue',
    },
    {
      id: 'DLRTOUR',
      symbol: getSymbolById('DLRTOUR'),
      sellingRate: dataBlueTourist?.dolar_turista?.replace(/\$/g, '') ?? '',
      buyingRate: dataBlueTourist?.dolar_turista?.replace(/\$/g, '') ?? '',
      variation: getVariation(
        dataBlueTourist?.dolar_turista,
        prevDataBlueTourist?.dolar_turista,
      ),
      last: prevDataBlueTourist?.dolar_turista?.replace(/\$/g, '') ?? '',
      update: lastUpdate(blueTourist?.[0]?.modificated ?? new Date()),
      description: 'Dólar Turista',
    },
    {
      id: 'DLRMEP',
      symbol: getSymbolById('DLRMEP'),
      sellingRate: dataBlueTourist?.bolsa_compra.replace(/\$/g, '') ?? '',
      buyingRate: dataBlueTourist?.bolsa_venta.replace(/\$/g, '') ?? '',
      variation: getVariation(
        dataBlueTourist?.bolsa_venta,
        prevDataBlueTourist?.bolsa_venta,
      ),
      last: prevDataBlueTourist?.dolar_turista.replace(/\$/g, ''),
      update: lastUpdate(blueTourist?.[0]?.modificated ?? new Date()),
      description: 'Dólar MEP',
    },
    {
      id: 'DLRCCL',
      symbol: getSymbolById('DLRCCL'),
      sellingRate: dataBlueTourist?.contado_con_liqui_compra.replace(/\$/g, ''),
      buyingRate: dataBlueTourist?.contado_con_liqui_venta.replace(/\$/g, ''),
      variation: getVariation(
        dataBlueTourist?.contado_con_liqui_venta,
        prevDataBlueTourist?.contado_con_liqui_venta,
      ),
      last: prevDataBlueTourist?.dolar_turista?.replace(/\$/g, ''),
      update: lastUpdate(blueTourist?.[0]?.modificated ?? new Date()),
      description: 'Dólar CCL',
    },
    {
      id: 'PESO',
      symbol: getSymbolById('PESO'),
      sellingRate: '1',
      buyingRate: '1',
      update: lastUpdate(blueTourist?.[0]?.modificated ?? new Date()),
    },
  ]
}

const getVariation = (currentValue, prevValue) => {
  currentValue = parseFloat(currentValue?.replace(/\$/g, '')?.replace(',', '.'))
  prevValue = parseFloat(prevValue?.replace(/\$/g, '')?.replace(',', '.'))
  let variation = ((currentValue - prevValue) / prevValue) * 100
  let result = Math.round(variation * 100) / 100

  // return `${result > 0 ? "+" : ""}${result < 0 ? "-" : ""} ${result}`; //me esta devolviendo dos veces negativo, ejm "- -0,76%"
  return `${result}`
}

export const parseDataCalculator = (currencies = [], blueTourist = []) => {
  const dataOne = formatDataCurrencies(currencies)
  const dataTwo = formatDataBlueTourist(blueTourist)
  return dataOne.concat(dataTwo)
}

export const parseDataCurrencies = (currencies = [], blueTourist = []) => {
  const dataOne = formatDataCurrencies(currencies)

  const dataTwo = formatDataBlueTourist(blueTourist)
  const data = dataOne.concat(dataTwo)
  const primaryOrder = []
  const secondaryOrder = []
  primary
    .filter((currency) => currency.id !== 'PESO')
    .forEach((currency) => {
      let findCurrency = data.find(
        (itemCurrency) => currency.id === itemCurrency.id,
      )
      
      if(findCurrency){
       
        findCurrency.url = currency.slug
        findCurrency.name = currency.name
        findCurrency.sellingRate = parseFloat(findCurrency.sellingRate.replace('.', '').replace(',', '.')).toFixed(2).replace('.',',');
        findCurrency.buyingRate = parseFloat(findCurrency.buyingRate.replace('.', '').replace(',', '.')).toFixed(2).replace('.',',');
        primaryOrder.push(findCurrency)
      }
    })
  secondary.forEach((currency) => {
    let findCurrency = data.find(
      (itemCurrency) => currency.id === itemCurrency.id,
    )
    
    if(findCurrency){
      findCurrency.name = currency.name
      findCurrency.sellingRate = parseFloat(findCurrency.sellingRate.replace('.', '').replace(',', '.')).toFixed(2).replace('.',',');
      findCurrency.buyingRate = parseFloat(findCurrency.buyingRate.replace('.', '').replace(',', '.')).toFixed(2).replace('.',',');
      findCurrency.last = parseFloat(findCurrency.last.replace('.', '').replace(',', '.')).toFixed(3).replace('.',',');
      secondaryOrder.push(findCurrency)
    }
  })
  return { primary: primaryOrder, secondary: secondaryOrder }
}

export const parseDataBonds = (bonds) => {
  return allowedBonds.map((allowedBond) =>
    bonds.find((bond) => bond.papel === allowedBond),
  )
}
