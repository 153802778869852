export const privacy = [`
<div id="proteccionFooter" class="modalLegales"> 
    <div id="protec-datos-body" class="body-modal">
        <a href="http://www.jus.gob.ar/datos-personales.aspx" target="_blank"><img src="/img/pdp.jpg" class="img-prot" alt="PROTECCIÓN DE DATOS PERSONALES"></a>
        <p>Al registrarse en alguno de los sitios de dominio de ARTE GRAFICO EDITORIAL ARGENTINO S.A. (en adelante, "AGEA"), EL USUARIO deberá brindar información personal, prestando su consentimiento para que la misma sea almacenada directamente en una BASE DE DATOS, encontrándose protegida electrónicamente, utilizando los mecanismos de seguridad informática de protección de la información más completos y eficaces para mantenerla en total confidencialidad, conforme a la Ley Nº 25.326 de Hábeas Data, no obstante lo cual, EL USUARIO puede solicitar la eliminación o modificación de los mismos en el órgano de control de la citada norma, Dirección Nacional de Protección de Datos Personales, dependiente del Ministerio de Justicia, Seguridad y Derechos Humanos Sarmiento 1118, 5to. Piso (CP1041aax); tel. 4383-8510/12/13/15;<a href="http://www.jus.gov.ar/datospersonales/" target="_blanck"> www.jus.gov.ar/datospersonales/</a> - <a href="mailto:infodnpdpa@jus.gov.ar">infodnpdpa@jus.gov.ar</a>..</p>
        <br/>
        <p>AGEA se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales así como a prácticas de la industria. En dichos supuestos, anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.</p>
        <br/>
        <p>Mediante el presente, toda persona que se registra en un sitio de Internet de dominio de AGEA (en adelante USUARIO) y vuelque información respecto de su nombre, domicilio, sexo, fecha de nacimiento, documento nacional de identidad, identificación tributaria, teléfono, dirección de correo electrónico y/o, presta su consentimiento para que dicha información sea almacenada directamente en una BASE DE DATOS de propiedad de AGEA.</p>
        <br/>
        <p>El Usuario garantiza que los Datos Personales facilitados a AGEA son veraces y se hace responsable de comunicar a ésta cualquier modificación en los mismos.</p>
        <br/>
        <p>Se deja expresamente aclarado que ciertos Servicios prestados por éste sitio u otros sitios vinculados pueden contener Condiciones Particulares con previsiones específicas en materia de protección de Datos Personales.</p>
        <br/>
        <p>Los Datos Personales serán incorporados a una base de datos que es de titularidad de AGEA (la "Base"). </p>
        <br/>
        <p>EL USUARIO presta su consentimiento para que AGEA realice operaciones y procedimientos sistemáticos, electrónicos o no, que permitan la recolección, conservación, ordenación, almacenamiento, modificación, evaluación, bloqueo, cesión y en general, el procesamiento de sus DATOS PERSONALES (en adelante TRATAMIENTO DE DATOS PERSONALES).   </p>
        <br/>
        <p>AGEA podrá contratar a terceros para el tratamiento de Datos Personales.</p>
        <br/>
        <p>La finalidad de la recogida y tratamiento de los Datos Personales es la que se detalla a continuación:</p>
        <br/>
        <ul>
            <li>Para habilitar su participación en promociones, premios o concursos en línea.</li>
            <li>Para el desarrollo de nuevos productos y servicios que satisfagan las necesidades del Usuario.</li>
            <li>Para contactarse, vía mail o telefónicamente, con el Usuario a fin de relevar opiniones sobre el servicio y para informar sobre productos y servicios de cualquiera de los sitios editoriales o productos de AGEA.</li>
        </ul>
        <p>AGEA ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos, y ha instalado todos los medios y medidas técnicas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos Personales facilitados a AGEA. Ello no obstante, el Usuario debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables. Por tal motivo, debe tener presente que siempre que divulguen voluntariamente información personal online, ésta puede ser recogida y utilizada por otros. Por lo tanto, si bien ponemos nuestro mayor esfuerzo por proteger su información personal, AGEA no será responsable por la difusión de los datos personales de nuestros visitantes efectuada por fuentes ajenas a ésta ni será responsable por los daños y perjuicios que la misma genere.</p>
        <br/>
        <p>El Usuario tiene reconocidos los derechos de acceso, cancelación, rectificación y oposición, así como tienen reconocido el derecho a ser informados de los permisos de acceso realizados contactándose con AGEA a través del correo electrónico <a href="mailto:datospersonales@agea.com.ar">datospersonales@agea.com.ar</a> o bien puede eliminar sus datos personales de forma definitiva ingresando a <a href="https://miperfil.clarin.com/">Mi Perfil</a> (https://miperfil.clarin.com/).</p>
        <br/>
        <p>El Usuario podrá ejercer su derecho de retiro o bloqueo total o parcial de su nombre en nuestra base de datos, mediante solicitud formal enviada al mail&nbsp;<a href="mailto:datospersonales@agea.com.ar">datospersonales@agea.com.ar</a>. Para su mejor comprensión, se transcriben: 1) Artículo 27, inc. 3ro. de la ley 25326: (Archivos, registros o bancos de datos con fines de publicidad).3. El titular podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que se refiere el presente artículo; y 2) Artículo 27 - Anexo I - Decreto 1558/01: En toda comunicación con fines de publicidad que se realice por correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado, se deberá informar el nombre del responsable o usuario del banco de datos que proveyó la información.</p>
        <br/>
        <p>Las estructuras de la Base no requieren ni permite el ingreso de datos ‘sensibles’ en los términos de los artículos 7° y concordantes de la Ley 25.326 de Protección de Datos Personales y su Reglamentación.</p>
    </div>
</div>
`];