import { Service } from "models/Service";

export class Movil extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Lee Clarín desde tu celular. Bajate las Apps que Clarín tiene para vos: Diario, Tapas de Clarín y Kiosco. Vas a poder mirar videos, mapas, galerías de fotos y textos adaptados a tu pantalla.`,
        keywords: '',
        title: `Apps Clarín - Clarin.com`,
        url: `movil.html`, 
        extra: [],
      },
      section: "movil",
      sectionName: "Apps Clarín",
      template: "Movil",
      title: `Apps de Clarín`,
      widthFull: false,
      slug: "",
      titleHeader:"Apps de Clarín"
    });    
  }

}