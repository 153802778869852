export const cities = [ 
  { "realName": "Arrecifes", "lat": "-34.0667", "long": "-60.1167" },
  { "realName": "Azul", "lat": "-36.77698", "long": "-59.85854" },
  { "realName": "Buenos Aires", "lat": "-34.6083000", "long": "-58.3712000" },
  { "realName": "Chepes", "lat": "-31.3500000", "long": "-66.6000000" },
  { "realName": "Rosario", "lat": "-32.9511", "long": "-60.6664" }, 
  { "realName": "Junín", "lat": "-34.5939", "long": "-60.9464" },
  { "realName": "Bahia Blanca", "lat": "-38.71959", "long": "-62.27243" },
  { "realName": "Balcarce", "lat": "-25.58333", "long": "-63.28333" },
  { "realName": "Baradero", "lat": "-33.81105", "long": "-59.50807" },
  { "realName": "Benito Juarez", "lat": "-37.67188", "long": "-59.80653" },
  { "realName": "Bolivar", "lat": "-36.2236", "long": "-61.1139" },
  { "realName": "Bragado", "lat": "-35.15", "long": "-60.48333" },
  { "realName": "Brandsen", "lat": "-35.11667", "long": "-58.21667" },
  { "realName": "Campana", "lat": "-34.14444", "long": "-58.96583" },  
  { "realName": "Cañuelas", "lat": "-35.01667", "long": "-58.71667" },
  { "realName": "Carlos Tejedor", "lat": "-35.39265", "long": "-62.42146" },
  { "realName": "Carmen de Patagones", "lat": "-40.79828", "long": "-62.98097" },
  { "realName": "Castelli", "lat": "-36.0833", "long": "-57.8081" },
  { "realName": "Ceres", "lat": "-29.88333", "long": "-61.95" },
  { "realName": "Concordia", "lat": "-31.3922", "long": "-58.0169" },
  { "realName": "Coronel Dorrego", "lat": "-38.73333", "long": "-61.25" },
  { "realName": "Coronel Pringles", "lat": "-37.9806", "long": "-61.3639" },
  { "realName": "Coronel Suárez", "lat": "-37.4333", "long": "-67.8833" },
  { "realName": "Chamical", "lat": "-30.3600200", "long": "-66.3139900" },
  { "realName": "Chascomus", "lat": "-28.9", "long": "-62.51667" },
  { "realName": "Chivilcoy", "lat": "-34.8967", "long": "-60.0189" },
  { "realName": "Dolores", "lat": "-36.3153", "long": "-57.6753" },
  { "realName": "Gobernador Gregores", "lat": "-48.75", "long": "-70.25" },
  { "realName": "General Alvarado", "lat": "-38.2703", "long": "-57.8397" },
  { "realName": "General Alvear", "lat": "-34.9769", "long": "-67.6961" },
  { "realName": "General Belgrano", "lat": "-35.7656", "long": "-58.4972" },
  { "realName": "General Lavalle", "lat": "-36.4", "long": "-56.9667" },
  { "realName": "General Madariaga", "lat": "-36.9953", "long": "-57.1372" },
  { "realName": "General Pueyrredón", "lat": "-38", "long": "-57.75" },
  { "realName": "General Villegas", "lat": "-35.03285", "long": "-63.01252" },
  { "realName": "Gualeguaychú", "lat": "-33.0093700", "long": "-58.5172200" },
  { "realName": "Laboulaye", "lat": "-34.12662", "long": "-63.39119" },
  { "realName": "Laprida", "lat": "-37.55", "long": "-60.8167" },          
  { "realName": "Las Flores", "lat": "-36.014", "long": "-59.1003" },
  { "realName": "Lezama", "lat": "-35.9752", "long": "-57.82652" },
  { "realName": "Lincoln", "lat": "-34.865", "long": "-61.5319" },
  { "realName": "Lobería", "lat": "-42.08114", "long": "-63.75518" },
  { "realName": "Lobos", "lat": "-35.18286", "long": "-59.08975" },
  { "realName": "Maipú", "lat": "-24.15579", "long": "-62.25653" },
  { "realName": "Mar Chiquita", "lat": "-37.75", "long": "-57.41667" },
  { "realName": "Mercedes", "lat": "-34.6506", "long": "-59.4314" },
  { "realName": "Navarro", "lat": "-35.0026", "long": "-59.2681" },
  { "realName": "Necochea", "lat": "-38.54726", "long": "-58.73675" },
  { "realName": "Nueve de julio", "lat": "-35.3983000", "long": "-60.9379200" },
  { "realName": "Olavarria", "lat": "-36.8927200", "long": "-60.3225400" },
  { "realName": "Paso de los Libres", "lat": "-29.71251", "long": "-57.08771" },
  { "realName": "Pehuajó", "lat": "-35.81077", "long": "-61.89680" },
  { "realName": "Pergamino", "lat": "-33.8894", "long": "-60.5697" },
  { "realName": "Pila", "lat": "-36", "long": "-58.1408" },
  { "realName": "Rafaela", "lat": "-31.2503300", "long": "-61.4867000" },
  { "realName": "Ramallo", "lat": "-33.46667", "long": "-60.01667" },
  { "realName": "Rauch", "lat": "-36.7719", "long": "-59.0877" },
  { "realName": "Río Cuarto", "lat": "-33.1236", "long": "-64.3492" },
  { "realName": "Río Colorado", "lat": "38.9944", "long": "-64.0951" },
  { "realName": "San Antonio de Areco", "lat": "-34.2619", "long": "-59.4667" },
  { "realName": "San Antonio Oeste", "lat": "-40.73193", "long": "-64.94769" },
  { "realName": "San Cayetano", "lat": "-38.3333", "long": "-59.6167" },
  { "realName": "San Miguel del Monte", "lat": "-35.45873", "long": "-58.76384" },
  { "realName": "San Nicolás", "lat": "-33.3339", "long": "-60.2108" },
  { "realName": "San Pedro", "lat": "-33.6758", "long": "-59.6631" },
  { "realName": "Suipacha", "lat": "-34.77004", "long": "-59.68958" },
  { "realName": "Sunchales", "lat": "-30.94404", "long": "-61.56148" },
  { "realName": "Tandil", "lat": "-37.3216700", "long": "-59.1331600" },
  { "realName": "Trenque Lauquen", "lat": "-35.973279", "long": "-62.732597" },
  { "realName": "Tordillo", "lat": "-36.5197", "long": "-57.3244" },
  { "realName": "Tres Arroyos", "lat": "-38.3772", "long": "-60.2756" },
  { "realName": "Venado Tuerto", "lat": "-33.7455871", "long": "-61.9686068" },
  { "realName": "Villa Dolores", "lat": "-31.9452", "long": "-65.1902" },
  { "realName": "Victorica", "lat": "-36.2167", "long": "-65.45" },
  { "realName": "Zárate", "lat": "-34.0958", "long": "-59.0242" },
  { "realName": "Catamarca", "lat": "-28.46957", "long": "-65.78524" },
  { "realName": "Tinogasta", "lat": "-28.0631900", "long": "-67.5648800" }, 
  { "realName": "Fiamballá", "lat": "-27.68333", "long": "-67.63333" },
  { "realName": "Andalgalá", "lat": "-27.6", "long": "-66.31667" }, 
  { "realName": "Belén", "lat": "-27.6495962", "long": "-67.0263616" },
  { "realName": "Resistencia", "lat": "-27.45", "long": "-58.98333" }, 
  { "realName": "Roque Saénz Peña", "lat": "-26.78522", "long": "-60.43876" },
  { "realName": "Fuerte Esperanza", "lat": "-25.1602377", "long": "-61.8442367" },
  { "realName": "Juan José Castelli", "lat": "-25.9467", "long": "-60.62" },
  { "realName": "General José de San Martín", "lat": "-26.5374300", "long": "-59.3415800" }, 
  { "realName": "Esquel", "lat": "-42.9136", "long": "-71.3108" },
  { "realName": "Trelew", "lat": "-43.2531242", "long": "-65.3094407" },
  { "realName": "Rawson", "lat": "-43.3001600", "long": "-65.1022800" },
  { "realName": "Comodoro Rivadavia", "lat": "-45.86667", "long": "-67.5" },
  { "realName": "San Francisco", "lat": "-31.4279700", "long": "-62.0826600" },
  { "realName": "Dean Funes", "lat": "-30.4203600", "long": "-64.3498400" },
  { "realName": "Morteros", "lat": "-30.7", "long": "-62" },
  { "realName": "Jesús María", "lat": "-30.9833", "long": "-64.0933" },
  { "realName": "Alta Gracia", "lat": "-31.6529200", "long": "-64.4282600" },
  { "realName": "Oliva", "lat": "-32.05", "long": "-63.56667" },
  { "realName": "Río Primero", "lat": "-31.08333", "long": "-63.5" },
  { "realName": "Villa Totoral", "lat": "-30.8166700", "long": "-63.7166700" },
  { "realName": "Villa Dolores", "lat": "-31.9458500", "long": "-65.1895800" },
  { "realName": "Villa Carlos Paz", "lat": "-31.4183774", "long": "-64.4936985" },
  { "realName": "San Francisco del Chanar", "lat": "-29.78333", "long": "-" },
  { "realName": "General Cabrera", "lat": "-", "long": "-63.93333" },
  { "realName": "General Deheza", "lat": "-32.73333", "long": "-63.76667" },
  { "realName": "Villa Huidobro", "lat": "-34.83333", "long": "-64.58333" },
  { "realName": "La Carlota", "lat": "-33.4199300", "long": "-63.2976900" },
  { "realName": "Vicuña Mackenna", "lat": "-33.9", "long": "-64.3833333" },
  { "realName": "Río Tercero", "lat": "-32.17301", "long": "-64.11405" },
  { "realName": "Bell Ville", "lat": "-32.61667", "long": "-32.7" },
  { "realName": "Córdoba", "lat": "-31.417", "long": "-64.183" },
  { "realName": "Villa María", "lat": "-32.40751", "long": "-63.24016" },
  { "realName": "Marcos Juárez", "lat": "-32.7", "long": "-62.1" },
  { "realName": "Las varillas", "lat": "-31.86667", "long": "-62.71667" }, 
  { "realName": "Huinca Renancó", "lat": "-34.83333", "long": "-64.38333" }, 
  { "realName": "Santo Tomé", "lat": "-28.55", "long": "-56.05" },
  { "realName": "Curuzú Cuatiá", "lat": "-29.7917100", "long": "-58.0546000" },
  { "realName": "Mercedes", "lat": "-29.1818600", "long": "-58.0789500" },
  { "realName": "Goya", "lat": "-29.13995", "long": "-59.26343" },
  { "realName": "Bella Vista", "lat": "-28.5", "long": "-58.83333" },
  { "realName": "Esquina", "lat": "-30.01667", "long": "-59.53333" },
  { "realName": "Monte Caseros", "lat": "-30.25", "long": "-57.65" },
  { "realName": "Florencia", "lat": "-27.9524", "long": "-57.6095" }, 
  { "realName": "Vera", "lat": "-28.7833", "long": "-58.7167" }, 
  { "realName": "Paraná", "lat": "-31.7331", "long": "-60.53" },
  { "realName": "La Paz", "lat": "-30.75", "long": "-59.65" },
  { "realName": "Federal", "lat": "-30.95", "long": "-58.8" },
  { "realName": "Federación", "lat": "-30.981054", "long": "-57.925875" },
  { "realName": "Colón", "lat": "-32.21667", "long": "-58.13333" },
  { "realName": "Nogoyá", "lat": "-32.3933", "long": "-59.7948" },
  { "realName": "Diamante", "lat": "-32.16667", "long": "-60.41667" },
  { "realName": "Victoria", "lat": "-32.6217981", "long": "-60.1576895" },
  { "realName": "Concepción del Uruguay", "lat": "-32.48463", "long": "-58.23217" },
  { "realName": "Villaguay", "lat": "-31.85", "long": "-59.01667" },
  { "realName": "Rosario del Tala", "lat": "-32.3", "long": "-59.15" },
  { "realName": "Crespo", "lat": "-32.03333", "long": "-60.31667" },
  { "realName": "María Grande", "lat": "-31.6857", "long": "-59.6161" },
  { "realName": "Chajarí", "lat": "-30.7538", "long": "-57.9867" },
  { "realName": "Ibicuy", "lat": "-33.73333", "long": "-59.16667" },
  { "realName": "Feliciano", "lat": "-30.3845200", "long": "-58.7516700" },
  { "realName": "Formosa", "lat": "-26", "long": "-58.5" },
  { "realName": "Pirané", "lat": "-25.66667", "long": "-59.16667" },
  { "realName": "Espinillo", "lat": "-24.96667", "long": "-58.56667" },
  { "realName": "Las Lomitas", "lat": "-24.7072", "long": "-60.5944" },
  { "realName": "San Salvador del Jujuy", "lat": "-24.18333", "long": "-65.3" },
  { "realName": "Perico", "lat": "-24.3832", "long": "-65.1201" },
  { "realName": "Libertador", "lat": "-23.8", "long": "-64.7833" },
  { "realName": "General Pico", "lat": "-35.6598", "long": "-63.7557" },
  { "realName": "General Hacha", "lat": "-36.614701", "long": "-64.289989" },
  { "realName": "Macachin", "lat": "-37.1369", "long": "-63.667" },
  { "realName": "Guatraché", "lat": "-37.66667", "long": "-63.53333" },
  { "realName": "Santa Isabel", "lat": "-36.25", "long": "-66.9333" },
  { "realName": "Santa Rosa", "lat": "-36.6203", "long": "-64.2906" },
  { "realName": "Realicó", "lat": "-35.0678", "long": "-64.6839" },
  { "realName": "Chilecito", "lat": "-29.16195", "long": "-67.4974" },
  { "realName": "Mendoza", "lat": "-32.89084", "long": "-68.82717" },
  { "realName": "San Rafael", "lat": "-34.6177200", "long": "-68.3300700" },
  { "realName": "Tunuyán", "lat": "-33.5786", "long": "-69.0171" },
  { "realName": "Malargüe", "lat": "-35.4752134", "long": "-69.585934" },
  { "realName": "Posadas", "lat": "-27.3670800", "long": "-55.8960800" },
  { "realName": "El Dorado", "lat": "-26.40843", "long": "-54.69463" },
  { "realName": "Zapala", "lat": "-38.8991600", "long": "-70.0544200" },
  { "realName": "Neuquén", "lat": "-38.9514", "long": "-68.0592" },
  { "realName": "Viedma", "lat": "-40.8133", "long": "-62.9973" },
  { "realName": "Bariloche", "lat": "-41.15", "long": "-71.3000000" },
  { "realName": "General Roca", "lat": "-32.6653500", "long": "-66.4505200" },
  { "realName": "Cipoletti", "lat": "-67.98333", "long": "-38.93333" },
  { "realName": "Choele Choel", "lat": "-39.2667", "long": "-65.6667" },
  { "realName": "El Bolsón", "lat": "-41.96667", "long": "-71.51667" },
  { "realName": "Ingeniero Jacobacci", "lat": "-41.3", "long": "-69.58333" },
  { "realName": "Salta", "lat": "-24.7859000", "long": "-65.4116600" },
  { "realName": "Metán", "lat": "-25.49694444", "long": "-64.97111111" },
  { "realName": "Rosario de la Frontera", "lat": "-25.8067", "long": "--64.9667" },
  { "realName": "General Güemes", "lat": "-24.6682", "long": "-65.0482" },
  { "realName": "Caucete", "lat": "-31.65417", "long": "-68.28528" },
  { "realName": "San Juan", "lat": "-31.5375000", "long": "-68.5363900" },
  { "realName": "San Luis", "lat": "-33.29501", "long": "-66.33563" },
  { "realName": "Villa Mercedes", "lat": "-33.67571", "long": "-65.45783" },
  { "realName": "Gobernador Gregores", "lat": "-48.75", "long": "-70.25" },
  { "realName": "las Heras", "lat": "-46.54186", "long": "-68.93593" },
  { "realName": "Río Gallegos", "lat": "-51.6226100", "long": "-69.2181300" },
  { "realName": "Puerto Deseado", "lat": "-47.75", "long": "-65.9" },
  { "realName": "Puerto San Julián", "lat": "-49.3036", "long": "-67.7351" },
  { "realName": "Reconquista", "lat": "-29.1474", "long": "-59.6547" },
  { "realName": "Vera, Santa Fe", "lat": "-29.46667", "long": "-60.21667" },
  { "realName": "San Cristóbal", "lat": "-30.33333", "long": "-61.33333" },
  { "realName": "Tostado", "lat": "-29.2327906", "long": "-61.7713708" },
  { "realName": "San Javier", "lat": "-30.5833", "long": "-59.95" },
  { "realName": "San Justo", "lat": "-30.7890512", "long": "-60.5930565" },
  { "realName": "Helvecia", "lat": "-31.0992", "long": "-60.0889" },
  { "realName": "Sastre", "lat": "-31.7673", "long": "-61.8293" },
  { "realName": "Esperanza", "lat": "-31.4486", "long": "-60.9274" },
  { "realName": "Gálvez", "lat": "-33.03016", "long": "-60.64045" },
  { "realName": "Las Rosas", "lat": "-32.4769", "long": "-61.5861" },
  { "realName": "Armstrong", "lat": "-32.78167", "long": "-61.60778" },
  { "realName": "Firmat", "lat": "-33.45", "long": "-61.4833" },
  { "realName": "Las Parejas", "lat": "-32.6816", "long": "-61.521" },
  { "realName": "Coronda", "lat": "-31.9667", "long": "-60.9167" },
  { "realName": "Casilda", "lat": "-33.04417", "long": "-61.16806" },
  { "realName": "Villa Constitución", "lat": "-33.2333", "long": "-60.3333" },
  { "realName": "Peyrano", "lat": "-33.5383536", "long": "-60.799744" },
  { "realName": "Cañada de Gómez", "lat": "-32.8163600", "long": "-61.3949300" },
  { "realName": "Juan Bernabé Molina", "lat": "-33.4833", "long": "-60.5" },
  { "realName": "Melincué", "lat": "-33.65", "long": "-61.45" },
  { "realName": "Rufino", "lat": "-34.26667", "long": "-62.7" },
  { "realName": "San Lorenzo", "lat": "-32.7434258", "long": "-60.7496742" },
  { "realName": "Santa Fe", "lat": "-31.64881", "long": "-60.70868" },
  { "realName": "Santiago del Estero", "lat": "-27.79511", "long": "-64.26149" },
  { "realName": "Añatuya", "lat": "-28.46667", "long": "-62.83333" },
  { "realName": "La Banda", "lat": "-27.7333", "long": "-64.25" },
  { "realName": "Bandera", "lat": "-28.8878546", "long": "-62.2688764" },
  { "realName": "Quimilí", "lat": "-27.6333300", "long": "-62.4166700" },
  { "realName": "Sacháyoj", "lat": "-26.68333", "long": "-61.83333" },
  { "realName": "Monte Quemado", "lat": "-27.786444", "long": "-64.269996" },
  { "realName": "Frías", "lat": "-28.65", "long": "-65.15" },
  { "realName": "Tolhuin", "lat": "-54.804271", "long": "-68.313087" },
  { "realName": "Sierra Grande", "lat": "-54.5", "long": "-67" },
  { "realName": "Monteros", "lat": "-27.16667", "long": "-65.5" },
  { "realName": "Aguilares", "lat": "-27.4333", "long": "-65.6167" },
  { "realName": "San Miguel de Tucumán", "lat": "-26.8198", "long": "-65.2169" },
  { "realName": "Leales", "lat": "-27.15", "long": "-65.25" },
  { "realName": "Tafí del Valle", "lat": "-26.8667", "long": "-65.6833" },
  { "realName": "Corrientes", "lat": "-27.4711", "long": "-58.8403" },
  { "realName": "La Rioja", "lat": "-29.4131", "long": "-66.8558" }
];