import { Service } from "models/Service";

export class Searcher extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Buscador - Clarin.com`,
        keywords: '',
        title: `Buscador - Clarin.com`,
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/buscador`, 
        extra: [],
      },
      section: "Buscador",
      sectionName: "Buscador",
      template: "Searcher",
      title: `Buscador`,
      widthFull: true,
      slug: "buscador",
      titleHeader:"Buscador"
    });    
  }

}