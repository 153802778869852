import { useState } from 'react';

export const useForm = ( initialForm = {} ) => {
  
    const [ formState, setFormState ] = useState( initialForm );

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setFormState(prevState => ({
            ...prevState,
            [ name ]: value
        }));
    }

    const onInputChangeError = ({ name, value }) => {    
        setFormState(prevState => ({
            ...prevState,
            [ name ]: value
        }));
    }

    const onResetForm = () => {
        setFormState( initialForm );
    }

    const onResetFormError = () => {
        for (let key in formState) {
            if (key.endsWith('Error')) {
                delete formState[key];
            }
        }
        setFormState({ ...formState });
    }

    return {
        ...formState,
        formState,
        onInputChange,
        onInputChangeError,
        onResetForm,
        onResetFormError
    }
}
