import { getChineseHoroscopeNew, getChineseHoroscope,getChineseHoroscopeMenu } from "@/helpers/getChineseHoroscope";
import { Service } from "models/Service";

export class ChineseAstrology extends Service {
  constructor() {
    let year = new Date().getFullYear();
    super({
      adsPath: "servicios/horoscopochino",
      meta: { 
        description: `Consultá el Horóscopo Chino ${year}. Descubre cuáles son las características y predicciones para los doce animales del Horóscopo Chino: Buey, Caballo, Cabra, Dragón, Gallo, Chancho, Conejo, Mono, Perro, Rata, Serpiente, Tigre.`,
        keywords: "horóscopo, ", 
        title: `Horóscopo Chino ${year}: Características y Predicciones signo por signo`, 
        url: `horoscopo-chino`, 
        urlImage: `https://www.clarin.com/img/2022/10/28/vTOPB6087_1200x630__1.jpg`,
        extra: [] 
      },
      section: "horoscopo-chino",
      sectionAds: "horoscopochino",
      template: "Astrology",
      title: `Horóscopo Chino ${year}: Características y Predicciones signo por signo`, 
      slug: "horoscopo-chino",
      titleHeader: "Horóscopo",
      titleHeaderTag: "p",
      breadcrumb: [
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Horóscopo Chino",
          url: "/horoscopo-chino",
        }
      ]
    });
  }

  parseData = async (query) => {
    let year = new Date().getFullYear();
    const slug = this.getSlug();
    const zodiacChinese = getChineseHoroscope();
    const menu = getChineseHoroscopeMenu();
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`);

    this.setData({
      zodiacChinese: zodiacChinese ?? [],
      isZodiacChinese: true,
      //notes: notes?.data?.items ?? [],
      menu: menu ?? [],
    });
    
    if(query[0] === 'horoscopo-chino' && query[1]) {
      this.setTemplate("DailyHoroscope");

      const arrayQuery = query[1].split('-');
      let signName = arrayQuery[arrayQuery.length - 1].charAt(0).toUpperCase() + arrayQuery[arrayQuery.length - 1].slice(1);
      let sectionName = arrayQuery[arrayQuery.length - 2].charAt(0).toUpperCase() + arrayQuery[arrayQuery.length - 2].slice(1);
      let descriptionType, titleType, seoText, pronoun;
      
      const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      
      let verificar = zodiacChinese.some((value) => normalizeString(value.sign) === normalizeString(signName));
      
      if (verificar) {
          const capitalizeSectionName = (name) => name === "Caracteristicas" ? "Características" : (name === "Prediccion" ? "Predicciones" : name);
      
          sectionName = capitalizeSectionName(sectionName);
      
          const capitalizeSignName = (name) => (name === "Bufalo" ? "Búfalo" : (name === "Dragon" ? "Dragón" : name));
      
          signName = capitalizeSignName(signName);
      
          pronoun = ["Rata", "Serpiente", "Cabra"].includes(signName) ? "de la" : "del";
      
          descriptionType = `Horóscopo chino: ${capitalizeSectionName(sectionName)} ${pronoun} ${signName}, por Ludovica Squirru. Descubre las características y predicciones de este y todos los signos del Horóscopo Chino ${year}, en Clarín.com`;
          titleType = `Horóscopo Chino ${year}: ${capitalizeSectionName(sectionName)} ${pronoun} ${signName}`;
          seoText = `Cada uno de los 12 signos del horóscopo chino está asociado con un animal que lo representa, según la fecha de nacimiento. Explorá las características del animal que te representa en la astrología china, según la máxima referente de nuestro país, Ludovica Squirru. A continuación, las características de cada uno de los doce signos o animales del horóscopo chino.`;
      
          const urlNormalize = normalizeString(signName).toLowerCase();
      
          this.setTemplate("DailyHoroscope");
          this.setMeta(this.parseMeta({
              description: descriptionType,
              seoText: seoText,
              keywords: "horóscopo",
              title: titleType,
              url: `horoscopo-chino/caracteristicas-${urlNormalize}`,
              extra: []
          }));
          this.setSectionAds(urlNormalize);
          this.setTitle(titleType);
      
          const signChinese = zodiacChinese.filter((value) => normalizeString(value.sign) === normalizeString(signName));
      
          this.setBreadcrumb([
              { title: "Horóscopo", url: "/horoscopo" },
              { title: "Horóscopo Chino", url: "/horoscopo-chino" },
              { title: `${sectionName} ${signName}`, url: "" },
          ]);
      
          this.setData({
              sign: signChinese ?? [],
              zodiac: zodiacChinese ?? [],
              notes: notes?.data?.items ?? [],
              isZodiacChinese: true,
              menu: menu ?? []
          });
      } else {
          this.setData({
              code: 404,
          });
      }
    }
  }
}

