/** HELPERS START */
const getStringBetween = (str, start, end) => {
    const result = str.match(new RegExp(start + "(.*)" + end));
    return result[1];
}
//slug author
const mapper = (key) => {
    switch (key) {
        case " ":
            return "-";
        case "á":
            return "a";
        case "é":
            return "e";
        case "í":
            return "i";
        case "ó":
            return "o";
        case "ú":
            return "u";
        case "ñ":
            return "n";
    }
    return key;
}
//name joke
const mapper2 = (key) => {
    switch (key) {
        case "_":
            return " ";
        case ".jpg":
            return "";
        case "%20":
            return "";
        case ",":
            return "";
        case "&aacute;":
            return "á";
        case "&eacute;":
            return "é";
        case "&iacute;":
            return "í";
        case "&oacute;":
            return "ó";
        case "&uacute;":
            return "ú";
        case "&ntilde;":
            return "ñ";
        case "Diogenes y el linyera":
            return "Diógenes y el linyera";
        case "Ramirez":
            return "Monstriña";
        case "Maria":
            return "Monstriña";
        case "El":
            return "El Niño Rodríguez";
        case "Yo Matias":
            return "Yo, Matías";
        case "Es lo que hay Reality":
            return "Es lo que hay (Reality)";
        case "Rodriguez":
            return "El Niño Rodríguez";
        case "niNorodriguez":
            return "El Niño Rodríguez";
        case "MonstriF1a":
            return "Monstriña";
        case "Monstrina":
            return "Monstriña";
    }
    return key;
}
//slug name
const mapper3 = (key) => {
    switch (key) {
        case " ":
            return "-";
        case "á":
            return "a";
        case "é":
            return "e";
        case "í":
            return "i";
        case "ó":
            return "o";
        case "ú":
            return "u";
        case "ñ":
            return "n";
        case ",":
            return "";
        case "(":
            return "";
        case ")":
            return "";
    }
    return key;
}
const authorSlug = (author) => {
    const regex = /(?:á|é|í|ó|ú|ñ|\s)/g;
    return author.replace(regex, mapper).toLowerCase();
}
//name joke frontend
const getNamejoke = (value, file, author) => {
    let cadena = file.replace(value, "");
    const regex = /(?:Nino|Contratapa|Opinion|El_Pais|Pagina_3|Pagina 3|Pagina3|Pagina_2|clasificados|Clasificados|_apa|_ad|_opinión|_opini%F3n|_opinion|_el%20pais|_el%20pa%EDs|_el_pa%EDs|_El_pais|_El_país|_El_Pais|_El_País|_El País|_el_pais|contratapa.jpg|_la_ciudad|_pagina3|_p%E1gina_2|_pagina_2|_p%E1gina%202|_pagina 2|_pe1gina202|_pe1gina%202|opiniC3B3n|opini%C3%B3n)/g;
    cadena = cadena.replace(regex, "");
    const regex1 = /(?:_|.jpg|,|&iacute;|&aacute;|&eacute;|&oacute;|&uacute;|&ntilde;|%20)/g;
    cadena = cadena.replace(regex1, mapper2);
    const regex2 = /(?:Diogenes y el linyera|Ramirez|Maria|El|Yo Matias|Es lo que hay Reality|Rodriguez|niNorodriguez|MonstriF1a|Monstrina)/g;
    cadena = cadena.replace(regex2, mapper2);
    if (cadena.length === 0) {
        author = author.replace(regex1, mapper2);
        author = author.replace(regex2, mapper2);
        return author.trim();
    }
    return cadena.trim();
}
//name joke slug
const nameSlug = (name) => {
    const regex = /(?:á|é|í|ó|ú|ñ|,|\(|\)|\s)/g;
    return name.replace(regex, mapper3).toLowerCase();
}
//parse file
const parseFile = (file, id, date, pathDateImg) => {
    const humorDomain = "//www.clarin.com/img/humor";
    const image = file;
    
    let tmpret = {};
    //fix -> 2023-07-18Clarin_Humor_Chistes_Nino_Rodriguez_Pagina_3.jpg"
    if (file.charAt(10) !== "_") {
        file = file.slice(0, 10) + "_" + file.slice(10);
    }
    //
    const tmp = file.split('_');
    if (typeof tmp[4] === 'undefined') tmp[4] = "";
    if (typeof tmp[3] === 'undefined') tmp[3] = "";

    const tmpNombreIlustracion = tmp[3].trim().toLowerCase();
    const tmpNombreHumorista = tmp[4].trim().toLowerCase();

    if (tmp[2] === 'Humor') {
        if (tmpNombreHumorista == "el" || tmpNombreHumorista == "nino" || tmpNombreHumorista == "ninorodriguez") {
            tmpret = {
                author: "El Niño Rodríguez",
                authorSlug: authorSlug("El Niño Rodriguez"),
            };
        } else if (tmpNombreHumorista == "maria") {
            tmpret = {
                author: "María Ramirez",
                authorSlug: authorSlug("María Ramirez"),
            };
        } else if (tmpNombreHumorista == "tabare") {
            tmpret = {
                author: "Tabaré",
                authorSlug: authorSlug("Tabaré"),
            };
        } else {
            tmpret = {
                author: tmpNombreHumorista.charAt(0).toUpperCase() + tmpNombreHumorista.substring(1),
                authorSlug: authorSlug(tmpNombreHumorista),
            };
        }
        tmpret = {
            ...tmpret,
            name: getNamejoke(`${tmp[0]}_${tmp[1]}_${tmp[2]}_${tmp[3]}_${tmp[4]}_`, file, tmp[4]),
            nameSlug: nameSlug(getNamejoke(`${tmp[0]}_${tmp[1]}_${tmp[2]}_${tmp[3]}_${tmp[4]}_`, file, tmp[4])),
        }
    }
    if (tmp[2] == "Ilustracion" || tmp[2] == "IlustraciF3n") {
        if (tmpNombreIlustracion == "maria") {
            tmpret = {
                author: "María Ramirez",
                authorSlug: authorSlug("María Ramirez"),
            };
        } else if (tmpNombreIlustracion == "monstrina") {
            tmpret = {
                author: "María Verónica Ramírez",
                authorSlug: authorSlug(tmpNombreIlustracion),
            };
        } else {
            tmpret = {
                author: tmpNombreIlustracion.charAt(0).toUpperCase() + tmpNombreIlustracion.substring(1),
                authorSlug: authorSlug(tmpNombreIlustracion),
            };
        }
        tmpret = {
            ...tmpret,
            name: getNamejoke(`${tmp[0]}_${tmp[1]}_${tmp[2]}_${tmp[3]}_`, file, tmp[3]),
            nameSlug: nameSlug(getNamejoke(`${tmp[0]}_${tmp[1]}_${tmp[2]}_${tmp[3]}_`, file, tmp[3])),
        }
    }
    tmpret.imageFeatured = `${humorDomain}/${pathDateImg}/${image}`.replace(".jpg", "_2.jpg");
    tmpret.image = `${humorDomain}/${pathDateImg}/${image}`.replace(".jpg", "_1.jpg");
    tmpret.imageId = id;
    tmpret.date = date;
    
    return tmpret;
}
export { getStringBetween, parseFile, authorSlug }
/** HELPERS END */