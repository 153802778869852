import dynamic from "next/dynamic";

const Templates = {
	Login: dynamic(() => import('./Clarin/Login')),
	AutoLoginApp: dynamic(() => import('./Clarin/AutoLoginApp')),
	//home
	TemplateBuilder: dynamic(() => import("./Clarin/TemplateBuilder/index")),
	//landing
	LandingBuilder: dynamic(() => import("./Clarin/LandingBuilder/index")),
	//tipos de notas
	Nota: dynamic(() => import("./Clarin/Nota")),
	NotaPreview: dynamic(() => import("./Clarin/NotaPreview")),
	Video: dynamic(() => import("./Clarin/Video")),
	Gallery: dynamic(() => import('./Clarin/Gallery')),
	GalleryPreview: dynamic(() => import('./Clarin/GalleryPreview')),
	RecipePreview: dynamic(() => import('./Clarin/RecipePreview')),
	RecipeNews: dynamic(() => import("./Clarin/RecipeNews")),
	//listados
	Author: dynamic(() => import('./Clarin/Author')),
	BreakingNews: dynamic(() => import("./Clarin/BreakingNews")),	
	Fotogalerias: dynamic(() => import("./Clarin/Fotogalerias")),
	Tag: dynamic(() => import("./Clarin/Tag")),
	Videos: dynamic(() => import("./Clarin/Videos")),
	//secciones
	Section: dynamic(() => import("./Clarin/Section")),
	//servicios
	RainMap: dynamic(() => import("./Clarin/RainMap")),
	Service: dynamic(() => import("./Clarin/Service")),
	TapaDiario: dynamic(() => import("./Clarin/TapaDiario")),
	Humor: dynamic(() => import("./Clarin/Humor")),
	Opinion: dynamic(() => import("./Clarin/Opinion")),
	LoginLanding: dynamic(() => import("./Clarin/LoginLanding")),
	//reporte excel
	Reportes: dynamic(() => import("./Clarin/Reportes")),
	//Status de la app
	Ping: dynamic(() => import("./Clarin/Ping")),
	Healthcheck: dynamic(() => import("./Clarin/Healthcheck")),
	//elecciones
	Elections: dynamic(() => import("./Clarin/Elections")),
};

export default Templates;
