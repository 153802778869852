import { Service } from "models/Service";
import { footerData, beneficiosData } from "./data"

export class CorporateLanding extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Clarín Corporativas - Clarin.com`,
        content: `Clarín Corporativas - Sumá beneficios para tu empresa y colaboradores: disfrutá con 365 en más de 4.500 comercios - Clarin.com`,
        keywords: '',
        title: `Clarín Corporativas - Clarin.com`,
        url: `/suscripciones/corporativas`, 
        extra: [],
      },
      section: "suscripciones/corporativas",
      sectionName: "",
      template: "CorporateLanding",
      title: ``,
      widthFull: true,
      slug: "",
      titleHeader:"",
      width100: true,
      bannersOn: false,
      enableHeader : false,
      enableFooter : false
    });    
  }

  parseData = async (query, menu) => {    
    this.setTitle(`Clarín Corporativas - Clarin.com`);
    this.setData({
      footerData: footerData,
      beneficiosData: beneficiosData,
    })
    this.setTemplate("CorporateLanding");
  }

}