const celsiusTofarenheit = (celsius) => {
  return `${Math.round(celsius * 1.8 + 32)}° F`;
};

export const getDegree = (number, degree, fixed = 0) => {
  if (degree == "celsius") return `${parseFloat(number).toFixed(fixed)}°`;
  if (degree == "farenheit") return celsiusTofarenheit(parseFloat(number));
};

const kmToMi = (km) => {
  return Math.round(km * 0.621371);
}

export const getDistance = (number, type ) => {
  if(type == "km") return `${number} km`;
  if(type == "millas") return `${kmToMi(number)} millas`;
}

const kmhToMph = (kmh) => {
  return (kmh / 1.609344).toFixed(1);
}
const kmhToNm = (kmh) => {
  return (kmh / 1.852).toFixed(1);
}
export const getVelocity = (number, type, withText = true ) => {
  if(type == "km") return `${number} ${withText ? 'km/h' : ''}`;
  if(type == "millas") return `${kmhToMph(number)} ${withText ? 'Mph' : ''}`;
  if(type == "nm") return `${kmhToNm(number)} ${withText ? 'Nm' : ''}`;
}

const hpaToMmHg = (hpa) => {
  return (hpa * 0.75006375541921).toFixed(1);
}

export const getPressure = (number, type ) => {
  if(type == "hpa") return `${number} hPa`;
  if(type == "mmhg") return `${hpaToMmHg(number)} mmhg`;
}

export const mapperCity = (name) => {
  switch (name) {
    case 'Ciudad de Buenos Aires': return true;
    case 'La Plata': return true;
    case 'Catamarca': return true; //San Fernando del Valle de Catamarca
    case 'Resistencia': return true;
    case 'Rawson': return true; //Chubut
    case 'Cordoba': return true;
    case 'Corrientes': return true;
    case 'Parana': return true;
    case 'Formosa': return true;
    case 'Jujuy': return true; //San Salvador de Jujuy
    case 'Santa Rosa': return true;
    case 'La Rioja': return true;
    case 'Mendoza': return true;
    case 'Posadas': return true;
    case 'Neuquen': return true;
    case 'Viedma': return true;
    case 'Salta': return true;
    case 'San Juan': return true;
    case 'San Luis': return true;
    case 'Rio Gallegos': return true;
    case 'Santa Fe': return true;
    case 'Santiago del Estero': return true;
    case 'Ushuaia': return true;
    case 'Tucuman': return true; //San Miguel de Tucuman
  }
  return false;
}