import { Service } from "models/Service";

export class SiteMap extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Mapa del sitio - Clarin.com`,
        keywords: '',
        title: `Mapa del sitio - Clarin.com`,
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/mapa-del-sitio`, 
        extra: [],
      },
      section: "",
      sectionName: "Mapa del sitio",
      template: "SiteMap",
      title: `Mapa del sitio`,
      widthFull: true,
      slug: "",
      titleHeader:"Mapa del sitio"
    });    
  }

}