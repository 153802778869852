import { getBucketBySlug } from "@/helpers/getBuckets";
import { Service } from "models/Service";

export class RadioLive extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Ingresá a clarin.com y seguí toda la programación de AM 790`,
        keywords: '',
        title: `Escuchá Radio Mitre en vivo`,
        url: `radio-mitre-vivo`, 
        extra: [],
      },
      section: "radio-mitre-vivo",
      sectionAds: "radiomitrevivo",
      sectionName: "Radio Mitre vivo",
      template: "RadioLive",
      title: `Radio Mitre en vivo`,
      widthFull: false,
      slug: "radio-mitre-lx",
      titleHeader:"Radio Mitre en vivo",
      autorefresh: false
    });    
  }

  parseData = async () => {
    const bucket = await getBucketBySlug(this.getSlug())
    
    this.setData({
      notes: bucket ?? [],
    });
  }
}