import { Service } from "models/Service";

export class LandingDemocraciayDesarrollo extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Democracia y Desarrollo - Clarin.com`,
        keywords: '',
        title: `Democracia y Desarrollo - Clarin.com`,
        url: `/DemocraciayDesarrollo`, 
        extra: [],
      },
      section: "DemocraciayDesarrollo",
      sectionName: "",
      template: "LandingDemocraciayDesarrollo",
      title: ``,
      widthFull: true,
      slug: "",
      titleHeader:"",
      width100: true,
      bannersOn: false
    });    
  }

}