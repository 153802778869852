export const pagegrowlerConfigFrontend = {
    buttons: {
        toggleShow: true,
        copyButton: false,
        cutButton: false
    }
}

export const pagegrowlerConfigBackend = {
    debug: {
        enable: false
    }
}
