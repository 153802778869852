import { Service } from "models/Service";

export class Rss extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `RSS - Clarin.com`,
        keywords: '',
        title: `RSS - Clarin.com`,
        url: `rss.html`, 
        extra: [],
      },
      section: "rss",
      sectionName: "RSS",
      template: "Rss",
      title: `RSS`,
      widthFull: false,
      slug: "",
      titleHeader:"RSS"
    });    
  }

}