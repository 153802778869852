export const text = [`
                <div id="terminosFooter" class="modalLegales">
                    <div id="term-datos-body" class="body-modal">

                        <p>LOS PRESENTES TÉRMINOS Y CONDICIONES SE ENCUENTRAN PUBLICADOS EN EL SITIO Y SECCIONES DE PROPIEDAD DE ARTE GRÁFICO EDITORIAL ARGENTINO S.A. (EN ADELANTE “AGEA”.) CUIT: 30-50012415-2 CON DOMICILIO EN PIEDRAS 1743, CIUDAD AUTÓNOMA DE BUENOS AIRES, REPÚBLICA ARGENTINA, Y SERÁN CONSIDERADOS ACEPTADOS POR LAS PERSONAS HUMANAS Y JURIDICAS (EN ADELANTE, “USUARIO” O, EN PLURAL, “USUARIOS”) QUE UTILICEN EL SITIO WWW.CLARIN.COM (EN ADELANTE “EL SITIO”), Y/0 CUALQUIERA DE SUS SECCIONES (EN ADELANTE “SECCIONES”).</p><br />
                        <p>EL USUARIO GARANTIZA Y DECLARA SER MAYOR DE 18 AÑOS, Y QUE AL UTILIZAR EL SITIO Y LAS SECCIONES ENTIENDE Y ACEPTA LOS TÉRMINOS Y CONDICIONES DE USO, Y LAS DEMÁS CONDICIONES QUE RIGEN EL SITIO Y LAS SECCIONES.</p><br />
                        <p>EL USUARIO ACEPTA SIN CONDICIONAMIENTOS TODOS Y CADA UNO DE LOS PRESENTES TÉRMINOS Y CONDICIONES DE USO Y LA POLÍTICA DE PRIVACIDAD QUE RIGE EL SITIO Y LAS SECCIONES. LA UTILIZACIÓN DEL SITIO Y LAS SECCIONES SE EFECTÚA BAJO ÚNICA Y EXCLUSIVA RESPONSABILIDAD DEL USUARIO.</p><br />
                        
                        <p class="title">1. CONDICIONES GENERALES</p><br/>
                        <p>EL SITIO Y LAS SECCIONES ESTÁN DESTINADOS A BRINDAR UN ESPACIO INFORMATIVO Y DE ENTRETENIMIENTO PARA LOS USUARIOS.</p><br />
                        <p>LOS PRESENTES TÉRMINOS Y CONDICIONES SON GENERALES PARA EL SITIO Y SECCIONES, SIN PERJUICIO DE LOS TÉRMINOS Y CONDICIONES QUE PUEDAN EXISTIR EN CADA UNO DE ESTOS EN FORMA PARTICULAR. EL SITIO Y LAS SECCIONES PODRÁN PREVER CONDICIONES ESPECÍFICAS AL CUAL DEBERÁN SUJETARSE QUIENES HAGAN USO DE ESOS SERVICIOS O CONTENIDOS. TALES CONDICIONES PODRÁN COMPLEMENTAR PARTE DE LO AQUÍ DISPUESTO.</p><br />
                        <p>PARA ACCEDER AL CONTENIDO EL USUARIO DEBERÁ ACEPTAR PREVIAMENTE LOS TERMINOS Y CONDICIONES QUE RIGEN EL SITIO Y LAS SECCIONES. EL USUARIO QUE NO ACEPTE LOS TERMINOS Y CONDICIONES NO PODRÁ ACCEDER AL CONTENIDO NI A LOS SERVICIOS DEL SITIO Y LAS SECCIONES. ASIMISMO, ESTOS TÉRMINOS Y CONDICIONES CONSTITUYEN EL ACUERDO COMPLETO ENTRE AGEA Y EL USUARIO CON RESPECTO A TODO LO PREVISTO EN ELLOS, Y SUSTITUYE CUALQUIER COMUNICACIÓN O PROPUESTA ANTERIOR O CONTEMPORÁNEA, ORAL O ESCRITA ENTRE AGEA Y EL USUARIO, RESPECTO DE SU CONTENIDO.</p><br/>
                       
                        <p class="title">2. GRATUIDAD</p><br/>                        
                        <p>LA GRATUIDAD DEL CONTENIDO OFRECIDO EN EL SITIO Y SECCIONES ES LIMITADA. EL USUARIO PODRÁ ACCEDER AL CONTENIDO OFRECIDO EN EL SITIO Y SECCIONES EN FORMA GRATUITA PREVIA REGISTRACIÓN EN EL SITIO Y/O SECCIONES. PARA EL CASO EN QUE EXCEDA EL LIMITE DE NOTAS PERMITIDAS POR MES CALENDARIO, SE LE INFORMARÁ EN LA LANDING DE SUSCRIPCIONES, QUE PODRÁ ACCEDER A UNA CANTIDAD ILIMITADA DE NOTAS MEDIANTE EL PAGO DE UN ARANCEL.</p><br/>
                        <p>SIN PERJUICIO DE LO ANTERIOR, AGEA SE RESERVA EL DERECHO A COBRAR EN EL FUTURO A LOS USUARIOS, UN CARGO Y/O COSTO QUE A SU CRITERIO DETERMINE, EN CONCEPTO DE SERVICIOS Y/O ACCESO A CONTENIDO EDITORIAL. EL CARGO Y/O COSTO QUE EVENTUALMENTE AGEA PUDIERA IMPLEMENTAR SERÁ INFORMADO A LOS USUARIOS EN LA PAGINA WEB; Y EN EL CUERPO DE LA FACTURA SI SE TRATARA DE UN USUARIO CON SUSCRIPCIÓN PAGA, CON UNA ANTELACIÓN DE 30 DÍAS PREVIO A SU IMPLEMENTACIÓN.</p><br/>
                        <p>LAS NOTAS A LAS QUE EL USUARIO ACCEDA EN FORMA GRATUITA A TRAVÉS DE LAS DISTINTAS PLATAFORMAS, YA SEA APP MOBILE, WEB MOBILE EN SMARTPHONES Y TABLETS Y/O CUALQUIER DISPOSITIVO, NO SON ACUMULATIVAS CON AQUELLAS A LAS QUE ACCEDA A TRAVÉS DEL SITIO Y SECCIONES. ASIMISMO, EL CONTEO DE LAS NOTAS SE REINICIARÁ MENSUALMENTE. LAS NOTAS NO SE ACUMULAN MES A MES. DE ESTA MANERA, EL USUARIO COMENZARÁ EL MES CALENDARIO CON UNA CANTIDAD DE CERO (0) NOTAS LEÍDAS EN SU HABER.</p>
                        <br/>

                        <p class="title">3. REGISTRACIÓN</p><br/>                         
                        <p>PARA LA UTILIZACIÓN DEL SITIO O SECCIONES, EL USUARIO DEBERÁ REGISTRARSE Y CREAR UNA CUENTA CON SU CORREO ELECTRÓNICO Y CONTRASEÑA, Y/O A TRAVES DE SU CUENTA EN GOOGLE, FACEBOOK, APPLE ID O MICROSOFT.</p>
                        <br/>
                        <p>LA INFORMACIÓN PERSONAL QUE EL USUARIO PROPORCIONE PARA REGISTRARSE EN EL SITIO Y/O EN LAS SECCIONES ESTARÁ PROTEGIDA CON UNA CONTRASEÑA DE TAL MANERA QUE CADA USUARIO PUEDA TENER ACCESO A SU INFORMACIÓN PERSONAL. CADA USUARIO REGISTRADO PODRÁ MODIFICAR SU INFORMACIÓN UTILIZANDO SU NOMBRE DE USUARIO Y CONTRASEÑA. EL USUARIO TIENE LA OBLIGACIÓN DE NO REVELAR SU CONTRASEÑA Y DE BRINDAR INFORMACIÓN FIDEDIGNA Y EXACTA, LA QUE TENDRÁ CARÁCTER DE DECLARACIÓN JURADA. SERÁ RESPONSABILIDAD DEL USUARIO MANTENER TODA LA INFORMACIÓN SUMINISTRADA PERMENENTEMENTE ACTUALIZADA. CUANDO LA INFORMACIÓN SUMINISTRADA NO ATIENDA A LAS CIRCUNSTANCIAS REALES DE QUIEN BRINDA INFORMACIÓN, SE CONSIDERARÁ TAL USUARIO INCURSO EN INCUMPLIMIENTO DE LOS TÉRMINOS Y CONDICIONES, SIENDO RESPONSABLE POR TODOS LOS PERJUICIOS SUFRIDOS POR AGEA O TERCEROS COMO CONSECUENCIA DE LA FALTA DE VERACIDAD DE LOS DATOS PROPORCIONADOS. </p>
                        <br/>

                        <p class="title">4. CONDICIONES DE CONTRATACIÓN DE SUSCRIPCIÓN:</p><br/>
                        <p>TODA PERSONA FÍSICA Y/O JURÍDICA, MAYOR DE 18 AÑOS, NACIONAL O EXTRANJERO, CON CAPACIDAD SUFICIENTE PARA CONTRATAR, Y QUE POSEA UNA TARJETA DE CRÉDITO VISA, AMERICAN EXPRESS, MASTERCARD, TARJETA NARANJA, CABAL, PODRÁ SUSCRIBIRSE A CUALQUIERA DE LOS PRODUCTOS QUE SE DETALLAN EN EL PUNTO 4.1. EL USUARIO PODRÁ SOLICITAR DICHA SUSCRIPCIÓN DE LA SIGUIENTE MANERA INGRESANDO
                        <a href="HTTPS://SUSCRIPCION.365.CLARIN.COM/SUSCRIPCION/PLANES/CLARIN/CLARINDIGITAL">HTTPS://SUSCRIPCION.365.CLARIN.COM/SUSCRIPCION/PLANES/CLARIN/CLARINDIGITAL ,</a><a href="WWW.KIOSCO.CLARIN.COM">WWW.KIOSCO.CLARIN.COM</a> Y/O EN LA APLICACIÓN DE CLARÍN DISPONIBLE A TALES EFECTOS EN SMARTPHONES CON ANDROID E IOS COMPLETANDO LA SOLICITUD DE SUSCRIPCIÓN ALLÍ CONTENIDA, DE MANERA FEHACIENTE Y EXACTA, Y CUMPLIR CON LAS DEMÁS CONDICIONES ESTIPULADAS POR AGEA.</p>
                        <br/>

                        <p class="title">4.1 TIPOS DE SUSCRIPCIÓN:</p><br/>
                        <p>EL USUARIO PODRÁ REGISTRARSE Y CONTRATAR LOS SIGUIENTES SERVICIOS QUE SE ENCUENTRAN EN EL SITIO Y/O SECCIONES MEDIANTE EL PAGO DE UN ARANCEL:</p>
                        <br/>
                        <p>1) CLARIN.COM COMBO 1: ACCESO ILIMITADO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS)</p>
                        <br/>
                        <p>2) CLARIN.COM COMBO 2: ACCESO ILIMITADO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS)+ TARJETA 365 CLÁSICA</p>
                        <br/>
                        <p>3) CLARIN.COM COMBO 3: ACCESO ILIMITADO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS ) + TARJETA 365 PLUS</p>
                        <br/>
                        <p>4) CLARÍN EPAPER BÁSICO: ACCESO A KIOSCO CLARÍN (DIARIO EN PAPEL EN VERSIÓN DIGITAL)</p>
                        <br/>
                        <p>5) CLARÍN EPAPER COMPLETO: ACCESO A KIOSCO CLARÍN (DIARIO EN PAPEL EN VERSIÓN DIGITAL) + TARJETA 365 CLÁSICA + ACCESO ILIMITADO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS)</p>
                        <br/>
                        <p>6) CLARIN.COM COMBO 1 BONIFICADO AL 100% (SOY 365): ACCESO ILIMITADO A CLARIN.COM BONIFICADO AL 100% + ENTRETENIMIENTO (CLARIN.COM/JUEGOS) + TARJETA 365 CLÁSICA O PLUS ACTIVA
                        LOS CLIENTES CON TARJETA 365 CLÁSICA O PLUS ACTIVA TENDRÁN BONIFICADO AL 100% CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS) MIENTRAS TENGAN ACTIVO UN PRODUCTO EN PAPEL.
                        </p>
                        <br/>
                        <p>7) CLARIN.COM + OLÉ DIGITAL MENSUAL: ACCESO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS) + ACCESO A OLE.COM.AR</p>
                        <br/>
                        <p>8) FULL DIGITAL: ACCESO ILIMITADO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS)</p>
                        <br/>
                        <p>9) FULL DIGITAL + 365 (CON CREDENCIAL CLÁSICA): ACCESO ILIMITADO A CLARIN.COM + ENTRETENIMIENTO (CLARIN.COM/JUEGOS ) + EÑE Y TARJETA 365 CLÁSICA</p>
                        <br/>
                        <p>10) FULL DIGITAL + ENTRETENIMIENTO (CLARIN.COM/JUEGOS) + 365 PLUS (CON CREDENCIAL PLUS): ACCESO ILIMITADO A CLARIN.COM + EÑE + ARQ Y TARJETA 365 PLUS</p>
                        <br/>
                        <p class="title">IMPORTANTE:</p>
                        <ul>
                        <li>LOS COMBOS FULL DIGITAL, FULL DIGITAL + 365 Y FULL DIGITAL + 365 PLUS NO SE COMERCIALIZAN ACTUALMENTE, UNICAMENTE SE ENCUENTRAN VIGENTES PARA AQUELLOS CLIENTES QUE LOS HUBIESEN CONTRATADO CON ANTERIORIDAD.
                        </li>
                        <li>LOS CLIENTES CON TARJETA 365 CLÁSICA O PLUS ACTIVA TENDRÁN BONIFICADO AL 100% CLARIN.COM MIENTRAS TENGAN ACTIVO UN PRODUCTO EN PAPEL.
                        </li></ul>
                        <br/>

                        <p class="title">4.2. ACEPTACIÓN O RECHAZO DE LA SOLICITUD DE SUSCRIPCIÓN</p><br/>
                        <p>AGEA SE EXPEDIRÁ ACEPTANDO O RECHAZANDO LA SOLICITUD DE SUSCRIPCIÓN EN EL MISMO MOMENTO EN QUE LA MISMA SEA PRESENTADA POR EL USUARIO, SIN NECESIDAD DE INVOCAR FUNDAMENTO ALGUNO. EL RECHAZO DE LA SOLICITUD DE SUSCRIPCIÓN POR PARTE DE AGEA, NO DARÁ DERECHO A RECLAMO ALGUNO, BAJO NINGÚN CONCEPTO.</p>
                        <br/>
                        <p class="title">4.3. PRECIO DE LA SUSCRIPCION Y FACTURACION.</p><br/>
                        <p>LA SUSCRIPCIÓN A LOS PRODUCTOS INDICADOS EN LA CLÁUSULA 4.1. SERÁ POR UN PLAZO MÍNIMO DE UN (1) MES CONTADO DESDE LA FECHA EN QUE AGEA HA ACEPTADO LA SOLICITUD, Y SE RENOVARÁ AUTOMÁTICAMENTE POR PERÍODOS IGUALES Y SUCESIVOS, SALVO QUE AGEA Y/O EL USUARIO MANIFIESTEN LO CONTRARIO.</p>
                        <br/>
                        <p>EL PRECIO DE LA SUSCRIPCIÓN A LOS PRODUCTOS DIGITALES QUE EL USUARIO CONTRATE, SE DEFINIRÁ CONFORME A LOS VALORES VIGENTES AL MOMENTO DE COMPLETAR LA SOLICITUD, LOS CUALES PUEDEN SER CONSULTADOS EN</p> <a href="HTTPS://365.CLARIN.COM/PRECIOSVIGENTES">HTTPS://365.CLARIN.COM/PRECIOSVIGENTES</a>
                        <br/>
                        <p>LA FACTURA SERÁ ENVIADA AL DOMICILIO DECLARADO POR EL USUARIO AL COMPLETAR LA REGISTRACIÓN, SALVO QUE EL USUARIO SOLICITE EL ENVÍO MEDIANTE CORREO ELECTRÓNICO. PARA VER LOS DETALLES DE LAS FACTURAS, INCLUYENDO MÉTODOS DE PAGO Y CICLOS DE FACTURACIÓN, EL USUARIO PODRÁ VISITAR <a href="HTTPS://AUTOGESTION.365.COM.AR">HTTPS://AUTOGESTION.365.COM.AR</a> E INGRESAR CON SU CORREO ELECTRÓNICO Y CLAVE DE IDENTIFICACIÓN DE REGISTRO, ALLÍ PODRÁ VISUALIZAR EL HISTORIAL DE PAGOS Y SUS FACTURAS PENDIENTES, ESTANDO A CARGO DEL USUARIO LA REVISIÓN PERIÓDICA DE DICHA INFORMACIÓN.</p>
                        <br/>
                        <p>CUALQUIER CAMBIO EN LA TARJETA DE CRÉDITO CON LA CUAL SE HAGA EL PAGO DE LA SUSCRIPCIÓN, DEBERÁ SER INFORMADO POR EL USUARIO A AGEA, COMUNICÁNDOSE AL CENTRO DE ATENCIÓN AL CLIENTE 0810.333.0365 O AL 0800.222.2365 O A LA DIRECCIÓN DE CORREO CONTACTO365@AGEA.COM.AR. LA NUEVA TARJETA DE CRÉDITO SERÁ DADA DE ALTA EN LA BASE DE DATOS DE AGEA, A LOS SIETE (7) DÍAS HÁBILES, CONTADOS DESDE EL DÍA QUE EL USUARIO SOLICITÓ E INFORMÓ DICHA MODIFICACIÓN.</p>
                        <br/>
                        <p class="title">4.4. MEDIOS DE PAGO DE LA SUSCRIPCION:</p>
                        <br/>
                        <p>LA SUSCRIPCIÓN DE LOS PRODUCTOS DIGITALES DEBERÁ SER ABONADA CON LA TARJETA DE CRÉDITO QUE EL MISMO USUARIO HAYA DENUNCIADO A TALES EFECTOS EN LA SOLICITUD DE REGISTRO POR MEDIO DE DÉBITO AUTOMÁTICO. EN LA PRIMERA FACTURA EMITIDA, SE ADICIONARÁ UN IMPORTE FINAL POR UNICA VEZ, AL SOLO EFECTO DE PROCEDER A LA
                        VALIDACIÓN DE LA TARJETA DENUNCIADA POR EL USUARIO. DICHO IMPORTE SERÁ REINTEGRADO EN LA MISMA TARJETA DE CRÉDITO EN DONDE SE REALICE EL COBRO DE LA SUSCRIPCIÓN, Y LO VERÁ REFLEJADO EN EL RESUMEN DE CUENTA SIGUIENTE O SUBSIGUIENTE.</p>
                        <br/>
                        <p>AGEA SE RESERVA EL DERECHO DE SUSPENDER AUTOMATICAMENTE EL SERVICIO, SI LA TARJETA DE CRÉDITO DEL USUARIO VENCE Y/O RESULTA SIN LÍMITE DISPONIBLE Y/O POR CUALQUIER MOTIVO AGEA NO PUEDE REALIZAR EL DÉBITO CORRESPONDIENTE A LA SUSCRIPCIÓN.</p>
                        <br/>
                        <p class="title">4.5. SOLICITUD DE BAJA</p><br/>
                        <p>EL USUARIO PODRÁ EFECTUAR LA CANCELACIÓN DE SU SUSCRIPCIÓN EN CUALQUIER MOMENTO; (I) COMUNICÁNDOSE AL CENTRO DE ATENCIÓN AL CLIENTE DE AGEA AL 0810.333.0365 O AL 0800.222.2365 (II) A TRAVÉS DE AUTOGESTIÓN INGRESANDO A </p><a href="AUTOGESTION.365.COM.AR/AUTOGESTION/INICIO.DO">AUTOGESTION.365.COM.AR/AUTOGESTION/INICIO.DO</a>
                        <br/>
                        <p class="title">5. RESPONSABILIDAD DEL USUARIO</p><br/>                    
                        <p>EL USUARIO SERÁ RESPONSABLE DE MANTENER LA CONFIDENCIALIDAD DE SU CONTRASEÑA, LA CUAL NO DEBERÁ REVELAR A TERCEROS, Y/O A NINGÚN REPRESENTANTE DE AGEA.</p>
                        <br/>
                        <p>EL USUARIO TIENE LA OBLIGACIÓN DE CERRAR LA SESIÓN CUANDO FINALICE LA NAVEGACIÓN DENTRO DEL SITIO Y/O SECCIONES.</p>
                        <br/>
                        <p>EL USUARIO QUE UTILICE EL SITIO Y LAS SECCIONES NO PODRÁ COMPARTIR SU USUARIO Y CONTRASEÑA CON TERCEROS, PUES LA UTILIZACIÓN DEL SITIO Y/O SECCIONES ES PERSONAL E INTRASFERIBLE. ASIMISMO, DEBERÁ NOTIFICAR A AGEA DE CUALQUIER USO Y/O SOSPECHA DE USO DESAUTORIZADO DE SU CUENTA, O VIOLACIÓN DE SEGURIDAD, INCLUYENDO, PERO NO LIMITANDOSE A PÉRDIDA, ROBO O DIFUSIÓN DESAUTORIZADA DE SU CONTRASEÑA.</p>
                        <br/>
                        <p>EL USUARIO RESPONDERÁ POR LA VERACIDAD DE LOS DATOS FACILITADOS EN LA REGISTRACIÓN, RESERVÁNDOSE AGEA EL DERECHO A EXCLUIR DEL SITIO Y/O DE LAS SECCIONES A TODO USUARIO QUE HAYA FACILITADO DATOS FALSOS, SIN PERJUICIO DE LAS DEMÁS ACCIONES QUE PROCEDAN EN DERECHO. MEDIANTE EL INGRESO DEL FORMULARIO DE CONTRATACIÓN CORRESPONDIENTE, EL USUARIO ACEPTA LAS CONDICIONES DE REGISTRO AQUÍ EXPUESTAS Y SE COMPROMETE A RESPETAR EL USO Y LAS PROHIBICIONES ESTABLECIDAS EN LAS MISMAS. ASIMISMO, AL ENVIAR EL FORMULARIO DE CONTRATACIÓN, EL USUARIO SE COMPROMETE AL PAGO CORRESPONDIENTE AL PRODUCTO Y SERVICIO ADQUIRIDO.</p>
                        <br/>
                        <p>EL USUARIO ACEPTA QUE, SI SUS DATOS DE PAGO NO PERMITEN LA FACTURACIÓN Y/O COBRO POR PARTE DE AGEA, EL SERVICIO PEDIDO NO LE SEA OTORGADO O SERÁ SUSPENDIDO SI LO TUVIERA ACTIVO.</p>
                        <br/>
                        <p>EL USUARIO RECONOCE Y ACEPTA QUE EL USO DE LOS CONTENIDOS OFRECIDOS POR EL SITIO Y SECCIONES SERÁ BAJO SU EXCLUSIVO RIESGO Y/O RESPONSABILIDAD.</p>
                        <br/>
                        <p>EL USUARIO SE COMPROMETE A UTILIZAR EL SITIO Y/O SECCIONES Y TODO SU CONTENIDO DE CONFORMIDAD CON LA LEY, LA MORAL, EL ORDEN PÚBLICO, Y LOS PRESENTES TÉRMINOS Y CONDICIONES QUE RIGEN EL SITIO Y SECCIONES. ASIMISMO, SE COMPROMETE HACER UN USO ADECUADO DE LOS CONTENIDOS DEL SITIO Y SECCIONES, Y A NO EMPLEARLOS PARA REALIZAR ACTIVIDADES ILÍCITAS O CONSTITUTIVAS DE DELITO, QUE ATENTEN CONTRA LOS DERECHOS DE TERCEROS Y/O QUE INFRINJAN LA REGULACIÓN SOBRE PROPIEDAD INTELECTUAL E INDUSTRIAL, O CUALESQUIERA OTRAS NORMAS DEL ORDENAMIENTO JURÍDICO APLICABLE.</p>
                        <br/>
                        <p>SE ENCUENTRA TERMINANTEMENTE PROHIBIDO (I) SELECCIONAR O UTILIZAR LOS DATOS DE INGRESO DE OTRA PERSONA CON LA INTENCIÓN DE HACERSE PASAR POR ELLA; (II) USAR LOS DATOS DE INGRESO SOBRE LOS CUALES OTRA PERSONA TIENE DERECHOS; O (III) USAR DATOS DE INGRESO QUE SE CONSIDEREN OFENSIVOS Y/O DISCRIMINATORIOS. EL INCUMPLIMIENTO DE LAS CONDICIONES PRECEDENTES CONSTITUIRÁ UNA VIOLACIÓN DE ESTAS CONDICIONES DE SERVICIO, LO CUAL PUEDE RESULTAR EN LA SUSPENSIÓN Y/O CANCELACIÓN INMEDIATA DE LA CUENTA.</p>
                        <br/>
                        <p>EL USUARIO ES RESPONSABLE POR EL BUEN USO DEL SITIO Y/O SECCIONES, COMPROMETIÉNDOSE EXPRESAMENTE A EVITAR CUALQUIER TIPO DE ACCIÓN QUE PUEDA DAÑAR A SISTEMAS, EQUIPOS O SERVICIOS ACCESIBLES DIRECTOS O INDIRECTAMENTE A TRAVÉS DE INTERNET, INCLUYENDO LA CONGESTIÓN INTENCIONAL DE ENLACES O SISTEMAS Y DE ACUERDO A LAS PRESENTES CONDICIONES.</p>
                        <br/>
                        <p>AL ACCEDER AL SITIO Y/O SECCIONES EL USUARIO ACEPTA NO VENDER, NO PUBLICAR, NO DISTRIBUIR, NO RETRANSMITIR NI FACILITAR NINGÚN ACCESO A LOS CONTENIDOS DE AQUELLOS A TERCEROS. EL USUARIO ACEPTA NO UTILIZAR EL SITIO Y SECCIONES PARA NINGÚN PROPÓSITO ILEGAL.</p>
                        <br/>
                        <p>AGEA SE RESERVA EL DERECHO DE RESTRINGIR Y/O CANCELAR EL ACCESO AL SITIO Y/O SECCIONES SI, A SU CRITERIO Y CONSIDERACIÓN, EL USUARIO LOS UTILIZA PARA INFRINGIR ALGUNA LEY, VIOLAR DERECHOS DE TERCEROS O INCUMPLIR LAS PRESENTES CONDICIONES DE CONTRATACIÓN. EN CASO DE PRODUCIRSE DESCARGAS MASIVAS DE CONTENIDOS POR PARTE DE UN USUARIO, AGEA SE RESERVA EL DERECHO DE CANCELAR EL ACCESO AL SITIO Y SECCIONES DE DICHO USUARIO, ANULAR SU SUSCRIPCIÓN, Y/O ADOPTAR LAS ACCIONES LEGALES QUE ESTIME OPORTUNAS.</p>
                        <br/>
                        <p>EL SITIO Y SECCIONES NO TIENEN OBLIGACIÓN DE CONSERVAR INFORMACIÓN QUE HAYA HECHO DISPONIBLE A LOS USUARIOS, NI QUE LE HAYA SIDO ENVIADA POR ÉSTOS ÚLTIMOS.</p>
                        <br/>
                        <p>LA UTILIZACIÓN DEL SITIO Y SECCIONES ESTÁ EXPRESAMENTE PROHIBIDA EN TODA JURISDICCIÓN EN DONDE NO PUEDAN SER APLICADAS LAS CONDICIONES ESTABLECIDAS EN LOS PRESENTES TÉRMINOS DE USO.</p>
                        <br/>
                        <p>LOS USUARIOS QUE REALICEN COMENTARIOS EN LAS NOTAS DEL SITIO O SECCIONES SERÁN RESPONSABLES POR LAS CONSECUENCIAS QUE TALES COMENTARIOS PUDIERAN GENERAR PARA TERCEROS. AGEA DE NINGUNA MANERA SERÁ RESPONSABLE POR LOS MISMOS NI POR
                        LOS DAÑOS QUE ESTOS PUDIERAN CAUSAR. AGEA PODRÁ PROCEDER A ELIMINAR AQUELLOS COMENTARIOS QUE INCITEN A LA VIOLENCIA, QUE UTILICEN TÉRMINOS AGRESIVOS, DISCRIMINATORIOS Y/O QUE PUEDEN CONSIDERARSE QUE DIFAMEN A TERCEROS.</p>
                        <br/>
                        <p>EL USUARIO AUTORIZA EN FORMA GRATUITA A AGEA O A CUALQUIER OTRO TERCERO A REUTILIZAR Y/O REPUBLICAR EN CUALQUIER OTRO MEDIO Y/O EN CUALQUIER OTRA RED SOCIAL EL COMENTARIO VOLCADO EN CADA UNA DE LAS NOTAS.</p>
                        <br/>
                        
                        <p class="title">6. RESPONSABILIDAD DE AGEA</p><br/>                       
                        <p>AGEA PONE A DISPOSICIÓN DEL USUARIO EN EL SITIO Y SECCIONES LA INFORMACIÓN NECESARIA RELATIVA A LOS SERVICIOS QUE OFRECE Y LAS CONDICIONES DE CONTRATACIÓN. EL USUARIO ACEPTA QUE EL ACCESO AL SITIO Y SECCIONES, Y EL CONTENIDO DE LOS MISMOS ES EL EXISTENTE EN CADA MOMENTO. AGEA NO ACEPTARÁ NI RESPONDERÁ RECLAMO ALGUNO POR LOS CONTENIDOS, ACTUALIZACIONES O CONEXIONES AL SITIO Y/O SECCIONES.</p>
                        <br/>
                        <p>AGEA HARÁ SU MEJOR ESFUERZO POR ASEGURAR DE BUENA FE, CON TODOS LOS MEDIOS A SU ALCANCE, EL ACCESO AL SERVICIO CONTRATADO POR EL USUARIO. NO OBSTANTE, LO ANTERIOR, AGEA NO SE RESPONSABILIZA DE LAS FALLAS TÉCNICAS DE CONEXIÓN DE RED QUE PUEDAN PROVOCAR DAÑOS EN LOS EQUIPOS, IMPEDIR LA CONEXIÓN O LIMITAR TOTAL O PARCIALMENTE EL ACCESO DEL USUARIO AL SITIO O SECCIONES. AGEA NO SE RESPONSABILIZARÁ DE LAS AVERÍAS QUE SE PRODUZCAN POR LAS EMPRESAS SUMINISTRADORAS DE SERVICIO Y QUE PUEDAN IMPEDIR EL ACCESO AL SITIO O SECCIONES, Y EN NINGÚN CASO ASEGURARÁ EL REEMBOLSO DEL IMPORTE PAGADO EN CASO DE RECLAMO</p>
                        <br/>
                        <p>ASIMISMO, AGEA NO SE RESPONSABILIZA POR LOS NOMBRES DE USUARIOS QUE AFECTEN A PERSONAS AJENAS, ESTÉN PROTEGIDOS POR MARCAS REGISTRADAS U OTRAS LEYES O QUE RESULTAREN VULGARES U OFENSIVOS. LOS USUARIOS ACEPTAN Y RECONOCEN QUE AGEA NO CONTROLA, NI SUPERVISA, NI ASUME RESPONSABILIDAD ALGUNA POR LA CALIDAD, SEGURIDAD, CARACTERÍSTICAS Y DEMÁS ELEMENTOS DE LOS PRODUCTOS Y/O SERVICIOS PROMOCIONADOS U OFRECIDOS EN LOS SITIOS.</p>
                        <br/>
                        <p>ASIMISMO, ACEPTAN Y RECONOCEN QUE AGEA NO CONTROLA, NI SUPERVISA, NI ASUME RESPONSABILIDAD ACERCA DE LA VERACIDAD Y EXACTITUD DE LA DESCRIPCIÓN EFECTUADA POR AQUELLOS TERCEROS QUE PROMOCIONAN U OFRECEN SUS PRODUCTOS O SERVICIOS EN EL ESPACIO PUBLICITARIO BRINDADO POR AGEA EN EL SITIO Y SECCIONES, NI ACERCA DEL CUMPLIMIENTO DE LOS REQUISITOS LEGALES PARA OFRECER Y VENDER LOS PRODUCTOS O SERVICIOS, NI SOBRE LA CAPACIDAD Y LEGITIMACIÓN DE LOS OFERENTES PARA PROMOCIONAR, OFRECER Y/O VENDER SUS BIENES O SERVICIOS. EL MATERIAL PUBLICITARIO ES PROPIEDAD DE LOS SOLICITANTES DEL ESPACIO. AGEA NO ES RESPONSABLE DEL CONTENIDO Y AL RESPECTO RIGEN LAS MISMAS EXCLUSIONES QUE PARA EL MATERIAL EDITORIAL.</p>
                        <br/>
                        <p>AGEA NO SERÁ RESPONSABLE FRENTE AL USUARIO POR DAÑOS Y PERJUICIOS QUE SE PRODUJERAN AL USUARIO COMO CONSECUENCIA DEL ACCIONAR DE OTROS USUARIOS O
                        TERCEROS Y LOS QUE RESULTEN DE LA INOBSERVANCIA POR PARTE DE TERCEROS U OTROS USUARIOS DE LAS LEYES O REGLAMENTACIONES O DE OTROS HECHOS ILÍCITOS O DEL MAL USO QUE TERCEROS O USUARIOS HAGAN DEL SITIO.</p>
                        <br/>
                        <p>EL USUARIO COMPRENDE Y ACEPTA QUE, I) AGEA NO SERÁ RESPONSABLE POR NINGÚN DAÑO O PERJUICIO, DIRECTO O INDIRECTO, INCLUYENDO, SIN NINGÚN TIPO DE LIMITACIÓN, DAÑOS PRODUCIDOS POR LA PÉRDIDA Y/O DETERIORO DE INFORMACIÓN Y/O POR EL USO DEL SITIO Y SECCIONES II) LA UTILIZACIÓN DEL SITIO Y SECCIONES REQUIERE DE LA UTILIZACIÓN DE INTERNET QUE IMPLICA LA ASUNCIÓN DE RIESGOS DE POTENCIALES DAÑOS AL SOFTWARE Y AL HARDWARE DEL USUARIO. POR TAL MOTIVO, EL EQUIPO TERMINAL DESDE EL CUAL EL USUARIO ACCEDA AL SITIO O A LAS SECCIONES, PODRÍA IMPLICAR UN ATAQUE Y DAÑO POR LA ACCIÓN DE HACKERS QUIENES PODRÍAN INCLUSO ACCEDER A LA INFORMACIÓN CONTENIDA EN EL EQUIPO TERMINAL DEL USUARIO, EXTRAERLA, SUSTRAERLA Y/O DAÑARLA. EL USUARIO RENUNCIA A EFECTUAR RECLAMOS POR ESTOS MOTIVOS CONTRA AGEA.</p>
                        <br/>

                        <p class="title">7. MODIFICACIONES DE LOS TERMINOS Y CONDICIONES</p><br/>
                        <p>LA/S MODIFICACIÓN/ES CONTRACTUAL/ES QUE AGEA DECIDA REALIZAR, SERÁN COMUNICADAS Y PUBLICADAS EN EL SITIO Y SECCIONES. A LOS USUARIOS QUE TENGAN UNA SUSCRIPCIÓN PAGA, SE LE INFORMARÁ ADEMAS EN EL CUERPO DE LA FACTURA CON TREINTA (30) DÍAS CORRIDOS PREVIOS A SU IMPLEMENTACIÓN. EL USO DEL/LOS SERVICIO/S LUEGO DE EFECTUADAS LAS MODIFICACIONES IMPLICA LA EXPRESA ACEPTACIÓN DE LAS MISMAS. SI EL USUARIO NO ESTUVIERA DE ACUERDO CON LAS MODIFICACIONES EFECTUADAS, PODRÁ DAR DE BAJA EL SERVICIO, CONFORME LO INDICADO EN EL PUNTO 4.5., SIN PERJUICIO DE TENER QUE CUMPLIR CON EL OPORTUNO PAGO DEL SERVICIO QUE SE HUBIESE DEVENGADO HASTA LA EFECTIVIZACIÓN DEL PEDIDO DE BAJA.</p>
                        <br/>
                        <p>EN NINGÚN CASO SE GENERARÁN CARGOS Y/O COSTOS ADICIONALES A NOMBRE DEL USUARIO, SALVO AUTORIZACIÓN EXPRESA DE SU PARTE. ASIMISMO, EN EL SUPUESTO DE EXISTIR MODIFICACIONES EN LOS PRECIOS DE LAS SUSCRIPCIONES DIGITALES, CARGOS Y/O COSTOS ADICIONALES, LOS MISMOS SERÁN PUBLICADOS EN LA PAGINA WEB, Y EN EL CUERPO DE LA FACTURA CON UNA ANTELACIÓN DE 30 DÍAS PREVIO A SU IMPLEMENTACIÓN.</p>
                        <br/>

                        <p class="title">8. PROTECCIÓN DE DATOS PERSONALES</p><br/>
                        <p>AGEA ADOPTA LAS MEDIDAS TÉCNICAS Y ORGANIZATIVAS NECESARIAS PARA GARANTIZAR LA SEGURIDAD Y CONFIDENCIALIDAD DE LOS DATOS PERSONALES DE LOS USUARIOS DE MODO DE EVITAR LA ADULTERACIÓN, PÉRDIDA, CONSULTA O TRATAMIENTO NO AUTORIZADO DE SUS DATOS PERSONALES, Y QUE PERMITAN DETECTAR DESVIACIONES DE INFORMACIÓN INTENCIONALES O NO, DE INFORMACIÓN, YA SEA QUE LOS RIESGOS PROVENGAN DE LA ACCIÓN HUMANA O DEL MEDIO TÉCNICO UTILIZADO.</p>
                        <br/>
                        <p>PARA MÁS INFORMACIÓN CONSULTE LAS POLITICAS DE PRIVACIDAD EN LINK DE ACCESO (<a href="https://www.clarin.com/ayuda/politica-proteccion-datos-personales">https://www.clarin.com/ayuda/politica-proteccion-datos-personales</a>)</p>
                        <br/>

                        <p class="title">9. PROPIEDAD INTELECTUAL</p><br/>
                        <p>TODO EL MATERIAL QUE SE ENCUENTRA EN EL SITIO Y SECCIONES, INCLUYENDO, PERO NO LIMITANDOSE A IMÁGENES, ILUSTRACIONES, CLIPS DE AUDIO Y/O CLIPS DE VIDEO, SE ENCUENTRA PROTEGIDOS POR DERECHOS DE AUTOR, LOS CUALES SON PROPIEDAD DE, Y CONTROLADOS POR U OTRAS PARTES QUE HAN LICENCIADO SU MATERIAL A AGEA ("DERECHOS DE AUTOR").</p>
                        <br/>
                        <p>SE PERMITE ÚNICAMENTE UN USO PERSONAL E INTRANSFERIBLE DE LAS CLAVES DE ACCESO AL SITIO Y SECCIONES. TODOS LOS CONTENIDOS DEL SITIO Y SECCIONES PERTENECEN A AGEA O, EN SU CASO, A TERCERAS PERSONAS Y ESTÁN PROTEGIDOS POR LA LEGISLACIÓN SOBRE PROPIEDAD INTELECTUAL. NINGÚN CONTENIDO DEL SITIO, CUALQUIERA QUE SEA SU NATURALEZA, PODRÁ SER BAJADO, PUBLICADO, EMITIDO, RETRANSMITIDO DIRECTA O INDIRECTAMENTE EN NINGÚN MEDIO O SOPORTE PARA USO DISTINTO DEL ESTRICTAMENTE PERSONAL. POR TANTO, QUEDA TERMINANTEMENTE PROHIBIDA SU UTILIZACIÓN CON FINES COMERCIALES, SU DISTRIBUCIÓN, ASÍ COMO SU MODIFICACIÓN, ALTERACIÓN O DESCOMPILACIÓN.</p>
                        <br/>

                        <p class="title">10. LEY APLICABLE Y JURISDICCION</p>
                        <br/>
                        <p>LOS PRESENTES TÉRMINOS Y CONDICIONES SE INTERPRETARÁN Y REGIRÁN CONFORME A LA LEGISLACIÓN ARGENTINA. AGEA Y EL USUARIO SE COMPROMETEN A INTENTAR RESOLVER DE MANERA AMISTOSA CUALQUIER DESACUERDO QUE PUDIERA SURGIR EN EL DESARROLLO DEL PRESENTE CONTRATO, PREVIAMENTE A ACUDIR A LA JURISDICCIÓN CONTEMPLADA, SIENDO COMPETENTES PARA CUALQUIER CONTROVERSIA QUE PUDIERE LLEGAR A SUSCITARSE, LOS TRIBUNALES COMPETENTES CON ASIENTO EN LA REPÚBLICA ARGENTINA.</p>
                         <br/>
                                                
                        <p class="title">11. NOTIFICACIONES</p>
                        <br/>
                        <p>TODA NOTIFICACIÓN U OTRA COMUNICACIÓN QUE DEBA EFECTUARSE BAJO ESTOS TÉRMINOS Y CONDICIONES DE USO, DEBERÁ REALIZARSE POR ESCRITO: (I) AL USUARIO AL E-MAIL REGISTRADO O POR CARTA DOCUMENTO DIRIGIDA AL DOMICILIO DENUNCIADO EN SU REGISTRACIÓN; (II) AL SITIO Y/O SECCIONES A CONTACTO365@AGEA.COM.AR, Y/O PIEDRAS 1743, CIUDAD AUTÓNOMA DE BUENOS AIRES (1139). EL SITIO Y SECCIONES SON RESPONSABILIDAD DE AGEA, CUIT: 30-50012415-2 UBICADO EN PIEDRAS 1743, CAPITAL FEDERAL, CIUDAD AUTÓNOMA DE BUENOS AIRES, REPÚBLICA ARGENTINA.</p>
                    
                    
                        </div>
                </div>
`]