export const dataMetaGames = (currentUrl) => {
  let content = {"http-equiv": "Content-Type", "content": 'text/html; charset=UTF-8'};
  let viewport = {"name": 'viewport', "content": 'width=device-width, maximum-scale=5'};
  let http = {"http-equiv": 'X-UA-Compatible', "content": 'IE=edge'};
  let pageTitle = "";
  let pageDescription = "";
  let pageKeywords = "";
  let author = {"name": 'author', "content": 'Clarin.com'};
  let twitterCard = {"name": 'twitter:card', "content": 'summary_large_image'};
  let mainImg = "";
  let pageImage = "";
  let imgAlt = {"name": 'twitter:image:alt', "content": 'clarin.com'};
  let twitterSite = {"name": 'twitter:site', "content": '@clarincom'};
  let twitterCreator = {"name": 'twitter:creator', "content": '@clarin'};
  let twitterDomain = {"name": 'twitter:domain', "content": 'clarin.com'};
  let title = "";
  let twitterDescription = "";
  let ogImg = "";
  let ogTitle = "";
  let ogUrl = "";
  let ogDescription = "";
  let fbPages = {"property": 'fb:pages', "content": '156057357762712'};
  let fbapp = {"property": 'fb:app_id', "content": '437491989970687'};
  let ogType = {"property": 'og:type', "content": 'article'};
  let articleSection = {"property": 'article:section', "content": 'Clarín - Servicios'}

  if (currentUrl === "juegos/ar/reto-actualidad") {    
    pageDescription = {name: 'description', content: "Jugá y divertite con el Test de Actualidad en Clarín.com. ¡Desafía tus habilidades y comparte tu puntuación con tus amigos!"};
    pageKeywords = {name: 'keywords', content: "juegos online, juegos de actualidad, conocimientos de noticias actuales"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-retoactualidad-236.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Test de Actualidad: probá tu conocimiento actual con este desafiante juego"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/diferencias") {
    pageDescription = {name: 'description', content: "Jugá y divertite con el juego de las diferencias en Los 8 Errores en Clarín.com. ¡Desafía tus habilidades y comparte tu puntuación con tus amigos!"};
    pageKeywords = {name: 'keywords', content: "Juegos diferencias, los 8 errores, desafio de habilidades"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-8errores.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Juego de las Diferencias: encontrá los 8 errores"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/lexireto") {
    pageDescription = {name: 'description', content: "Jugá y divertite con Lexi Reto en Clarín.com. ¡Desafía tus habilidades y comparte tu puntuación con tus amigos!"};
    pageKeywords = {name: 'keywords', content: "Lexi Reto, vocabulario, juegos sobre vocabularios"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-lexireto.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Lexi Reto: poné a prueba tu vocabulario"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/letras") {
    pageDescription = {name: 'description', content: "Jugá y divertite con esta Sopa de Letras en Clarín.com. ¡Desafía tus habilidades y comparte tu puntuación con tus amigos!"};
    pageKeywords = {name: 'keywords', content: "juegos sobre vocabularios, sopa de letras"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-lletres.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Desafía tu ingenio con este juego de palabras: Letras"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/baldosas") {
    pageDescription = {name: 'description', content: "Desafía tu capacidad de resolución de problemas en este juego de baldosas. ¡Compartí tu puntuación con tus amigos!"};
    pageKeywords = {name: 'keywords', content: "resolucion de problemas, juego online de baldosas"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-baldosas.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Ordená las Baldosas y resolvé el rompecabezas"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/sudoku/facil") {
    pageDescription = {name: 'description', content: "Poné a prueba tus habilidades de resolución de problemas y lógica con este nivel amigable para principiantes."};
    pageKeywords = {name: 'keywords', content: "Sudoku basico"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-sudoku-basico.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Sudoku Básico: ¡Ejercitá tu mente con el nivel más accesible!"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/sudoku/medio") {
    pageDescription = {name: 'description', content: "Aceptá el desafío del Sudoku en su nivel avanzado y pon a prueba tus habilidades lógicas."};
    pageKeywords = {name: 'keywords', content: "Sudoku medio"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-sudoku-avanzado.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Sudoku Avanzado: ¡Superá el desafío de este nivel intermedio!"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/sudoku/avanzado") {
    pageDescription = {name: 'description', content: "Ingresá en el mundo del Sudoku experto y desafía tu mente con niveles de dificultad extrema."};
    pageKeywords = {name: 'keywords', content: "Sudoku experto"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-sudoku-experto.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Dominá el Sudoku Experto: ¡Desafía tu mente con los niveles más difíciles!"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else if (currentUrl === "juegos/ar/mini-sudoku") {
    pageDescription = {name: 'description', content: "Si buscas una versión rápida y divertida del Sudoku, prueba el Mini Sudoku. Resuelve los enigmas en menos tiempo y disfruta de la emoción de este juego de números en su versión compacta."};
    pageKeywords = {name: 'keywords', content: "Sudoku mini"};
    mainImg = {itemprop: 'image', content: "https://cdn.qualitygames.media/iconos/logo-minisudoku.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Divertido y rápido: jugá al Mini Sudoku en Clarín Juegos"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    } else {
    // Si la URL actual no coincide con ninguna de las URL definidas, se utilizan valores por defecto
    pageDescription = {name: 'description', content: "Jugá y divertite en Clarín.com. ¡Desafía tus habilidades y comparte tu puntuación con tus amigos!"};
    pageKeywords = {name: 'keywords', content: "Juegos, Lexi Reto, Los 8 errores, Claringrilla, Sudoku, básico, Sudoku avanzado, Sudoku experto, Mini Sudoku, Test actualidad, Letras Baldosas"};
    mainImg = {itemprop: 'image', content: "https://www.clarin.com/img/clarin-sharing.png"}
    pageImage = {name: 'twitter:image:src', content: mainImg.content};
    title = {name: 'twitter:title', content: "Juegos en Clarín: ¡online y divertidos!"};
    twitterDescription = {name: 'twitter:description', content: pageDescription.content};
    ogImg = {property: 'og:image', content: mainImg.content};
    ogTitle = {property: 'og:title', content: title.content};
    ogUrl = {property: 'og:url', content: process.env.NEXT_PUBLIC_DOMAIN_URL+'/'+ currentUrl};
    ogDescription = {property: 'og:description', content: pageDescription.content};
    }

return [
  content,
  viewport,
  http,
  pageDescription,
  pageKeywords,
  mainImg,
  author,
  twitterCard,
  pageImage,
  imgAlt,
  twitterSite,
  twitterCreator,
  twitterDomain,
  title,
  twitterDescription,
  ogImg,
  ogTitle,
  ogUrl,
  ogDescription,
  fbPages,
  fbapp,
  ogType,
  articleSection,
];
};

