import {
  filterByCode,
  generalGobernadorA1, 
  generalJefeGobiernoA1, 
  generalPresidenteA1, 
  balotajePresidenteA1,
  generateValidUrl, 
  gobernadorA1, 
  gobernadorA2, 
  gobernadorA3, 
  jefeGobiernoA1, 
  jefeGobiernoA2, 
  jefeGobiernoA3, 
  presidenteA1, 
  presidenteA2, 
  presidenteA3, 
  presidenteA1Balotaje, 
  getDateUpdateBalotaje} from "@/helpers/getPostElection";
import { Service } from "models/Service";
import { imagePost } from "../../helpers/getPostElection";
import { mappings } from "./data";
import { getDateFormatForHead, getDateFormatZulu } from "@/helpers/formatDate";

export class Elections extends Service {
  constructor() {
    super({
      adsPath: "politica/nota",
      meta: {
        description: `Elecciones - Clarin.com`,
        keywords: 'Elecciones, Presidenciales 2023, Presidente, Gobernador, Ganadores, Resultados, Comicios, Sergio Massa, Patricia Bullrich, Javier Milei, Axel kicillof, Néstor Grindetti, Carolina Píparo, Juntos por el cambio, La libertad avanza, Unión por la patria',
        title: `Elecciones - Clarin.com`,
        url: `elecciones`, 
        extra: [],
      },
      section: "politica",
      sectionName: "Elecciones",
      sectionAds: "politica",
      template: "Elections",
      title: `Elecciones`,
      widthFull: true,
      slug: "elecciones-clarin",
      titleHeader:"Elecciones",
      titleHeaderLink:"/elecciones",
      autorefresh : false
    });    
  }

  parseData = async (query, menu) => {
    let url = `${query[0]}/${query[1]}/${query[2]}/${query[3]}/${query[4]}`
    let typeElection = query[3]
    const queryCode = query[4]
    const parametros = queryCode.match(/_8_(.*?)\.html/);
    let codeLetter, codeNumber, districts, postCreate, urlIframe,endpoint,baseUrl,codeComplet,imgPostPri, titleMeta, descriptionMeta;
    const porcentaje = 5;
    const dateNow = new Date();
    if (typeElection == 'presidente') {
      baseUrl = `/elecciones/2023/${query[2]}/presidente/resultados-`;
      endpoint = `president.json?rv=${dateNow.getHours()}${dateNow.getMinutes()}`;
    } else {
      baseUrl = "/elecciones/2023/generales/gobernador/resultados-";
      endpoint = `governors.json?rv=${dateNow.getHours()}${dateNow.getMinutes()}`;
    }

    if (parametros) {
      codeComplet = parametros[1]; //AR-K004
      const parametros2 = codeComplet.match(/^([A-Z]+-[A-Z]+)?(\d+)?$/);
      if (parametros2) {
        codeLetter = parametros2[1] || codeComplet;
        codeNumber = parametros2[2] || "";
      }else{
        codeLetter = codeComplet;
        codeNumber = "";
      }
    }
    
    const obtenerURLImagen = (code) => {
      if (imagePost[code]) {
        return imagePost[code].url;
      } else {
        return null;
      }
    }
 
    // informacion para el sitemap
    const apiUrlPres = `http://data-ecp.clarin.com/clarin.com/2023/argentina/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/AR_president.json`;
    const provinciasPresidente = await this.fetchData(apiUrlPres);
    const apiUrlGov=`http://data-ecp.clarin.com/clarin.com/2023/argentina/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/${codeLetter}_governors.json`;
    //const provinciasGobernadorGeneral = await this.fetchData(apiUrlGov);
    let provincias = [];
    const distritosProvincia = [];
    const gobernadorSitemap = [];
    let infoPresidentOrGovernors = provinciasPresidente
    
    if (provinciasPresidente.data && Array.isArray(provinciasPresidente.data.districts) && provinciasPresidente.data.districts.length > 0) {      
      provinciasPresidente.data.districts.forEach((provincia) => {
        let url = generateValidUrl(baseUrl,provincia.name, "", provincia.code);
        
        const obj = {
          id: `presidente_${provincia.id}`,
          name: provincia.name,
          code: provincia.code,
          url: url,
          last_update: provincia.last_update,
          publication_date: "2023-10-11T07:00:00.000Z",
          title : `Elecciones 2023: Presidente en ${provincia.name}`
        };
        provincias.push(obj);
      });
    }
    
    //distirtos de la provincia
    await Promise.all(provincias.map(async (distrito) => {
      let apiUrlDist = `http://data-ecp.clarin.com/clarin.com/2023/argentina/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/${distrito.code}_governors.json`;
      const { code, data } = await this.fetchData(apiUrlDist);
      if (code === 200) {
        let dist = data?.districts
        if (Array.isArray(dist) && dist.length > 0) {
          dist.forEach((element) => {
            let url = generateValidUrl(baseUrl, element.name, "", element.code);
            
            const obj = {
              id: `distritosProvincia_${element.id}`,
              name: element.name,
              code: element.code,
              url: url,
              last_update: element.last_update,
              publication_date: "2023-10-11T07:00:00.000Z",
              title : `Elecciones 2023: Presidente en ${element.name}`
            };
            distritosProvincia.push(obj);
          });
        }
      }
    }));
    // informacion para el sitemap

    const provinciaGobernadores = ['AR-C', 'AR-K', 'AR-B',];
    const provinciasGobernador = provincias.filter((provincia) => {
      return provinciaGobernadores.includes(provincia.code);
    });
    
    // 5 - 25% mostrar alternativa 1
    // 25 - 50% mostrar alternativa 2
    // 50 - adelante alternativa 3
    const crearPost = (data, typeElection, urlIframe = "", porcentajeMesasEscutradas, modified_time) => {
      const votosEnBlanco = data?.stats ? data?.stats.filter(item => item.type === "blank") : [];
      const votosImpugnados = data?.stats ? data?.stats.filter(item => item.type === "contested") : [];
      const votosNulos = data?.stats ? data?.stats.filter(item => item.type === "annulled") : [];
      let post;
      
      const getPostByElectionType = (type) => {
        switch (type) {
          case "presidente":
            if(query[2] == "balotaje") {
              return {
                a1: presidenteA1Balotaje,
                a2: presidenteA1Balotaje,
                a3: presidenteA1Balotaje,
              };
            }
            return {
              a1: presidenteA1,
              a2: presidenteA2,
              a3: presidenteA3,
            };
          case "gobernador":
            return {
              a1: gobernadorA1,
              a2: gobernadorA2,
              a3: gobernadorA3,
            };
          case "jefeGobierno":
            return {
              a1: jefeGobiernoA1,
              a2: jefeGobiernoA2,
              a3: jefeGobiernoA3,
            };
          case "balotaje":
            return {
              a1: balotajePresidenteA1,
            }
          case "general":
          case "generalGobernadorA1":
          case "generalJefeGobiernoA1":
            return {
              a1: generalPresidenteA1,
              a2: generalGobernadorA1,
              a3: generalJefeGobiernoA1,
            };
          default:
            return null;
        }
      };
      const electionTypeFunctions = getPostByElectionType(typeElection);

      if (electionTypeFunctions) {
        let postFunction;
        if (porcentajeMesasEscutradas >= 5 && porcentajeMesasEscutradas <= 25) {
          postFunction = electionTypeFunctions.a1;
        } else if (porcentajeMesasEscutradas > 25 && porcentajeMesasEscutradas <= 50) {
          postFunction = electionTypeFunctions.a2;
        } else if (porcentajeMesasEscutradas > 50) {
          postFunction = electionTypeFunctions.a3;
        }else if (porcentajeMesasEscutradas <= 5 && typeElection == "general") {
          postFunction = electionTypeFunctions.a1;
        }else if (porcentajeMesasEscutradas <= 5 && typeElection == "generalGobernadorA1") {
          postFunction = electionTypeFunctions.a2;
        }else if (porcentajeMesasEscutradas <= 5 && typeElection == "generalJefeGobiernoA1") {
          postFunction = electionTypeFunctions.a3;
        }else if (porcentajeMesasEscutradas <= 5 && typeElection == "balotaje") {
          postFunction = electionTypeFunctions.a1;
        }
        if (postFunction) {
          post = postFunction(data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe,infoPresidentOrGovernors, codeLetter, codeNumber, modified_time, menu);
        }
      }
      return post;
    };

    urlIframe = typeElection == "presidente" ? `https://ecp.clarin.com/2023/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/index.html#/map/president/${codeLetter}/_` 
    : `https://ecp.clarin.com/2023/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/index.html#/map/governors/${codeLetter}/_`;

    if (codeLetter && codeNumber) {
      urlIframe = typeElection == "presidente" ? `https://ecp.clarin.com/2023/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/index.html#/map/president/${codeLetter}/${codeLetter}${codeNumber}` 
      : `https://ecp.clarin.com/2023/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/index.html#/map/governors/${codeLetter}/${codeLetter}${codeNumber}`;
    }
    
    const apiUrl = `http://data-ecp.clarin.com/clarin.com/2023/argentina/${query[2] == 'balotaje' ? 'balotaje' : 'general'}/${codeLetter}_${endpoint}`;
    const { code, data, error } = await this.fetchData(apiUrl);
    if (code === 200) {
      let nameProvincia =  data?.name == "Resultados Nacionales" ?  "Argentina" : data?.name
      let percentagesScrutinized = data?.tables_percent;

      imgPostPri = obtenerURLImagen(codeLetter)
      districts = data?.districts.find(item => item.code === codeComplet);
      data.districts.forEach(district => {
        let code = district?.code;
        district.url = generateValidUrl(baseUrl, nameProvincia,district.name, code);
      });
      let modified_time = ""
      let keywords = ""
      const tags =  [
        {
          "name": "Elecciones 2023",
          "slug": "elecciones-2023",
          "subject": "subject",
          "_id": "VyFmuMo-2"
        }
      ];
      if(query[2] == "balotaje") {
        modified_time = await getDateUpdateBalotaje(data?.last_update);
        keywords = `${nameProvincia}, Balotaje, Elecciones 2023, Presidenciales 2023, Presidente, Ganadores, Resultados, Comicios, Fórmula Ganadora, Sergio Massa, Javier Milei, La Libertad Avanza, Unión por la Patria, Victoria Villarruel, Agustín Rossi`;
        tags.push(
          { "name": "Sergio Massa", "slug": "sergio-massa" },
          { "name": "Javier Milei", "slug": "javier-milei" },
          { "name": "Unión por la Patria", "slug": "union-por-la-patria" },
          { "name": "La Libertad Avanza", "slug": "la-libertad-avanza" },
          { "name": "Balotaje", "slug": "balotaje" },
        );
      } else {
        modified_time = (!!data && data?.tables_percent && data.tables_percent > 0 ) ? getDateFormatZulu(data?.last_update) : "2023-10-22T19:05:00.000Z"
        keywords = 'Elecciones, Presidenciales 2023, Presidente, Gobernador, Ganadores, Resultados, Comicios, Sergio Massa, Patricia Bullrich, Javier Milei, Axel kicillof, Néstor Grindetti, Carolina Píparo, Juntos por el cambio, La libertad avanza, Unión por la patria'
      }
      const dataPost = {
        "last_update": modified_time,
        "mesasEscrutadas": codeNumber ? districts?.tables_percent : data.tables_percent,
        "provincia": nameProvincia,
        "localidad": districts?.name || "",
        "provinciaLocalidad": codeNumber ? `${nameProvincia}, ${districts?.name}` :  nameProvincia ,
        "candidatos" : data?.parties || [],
        "stats": codeNumber ? districts?.stats : data?.stats,
        "ganador": codeNumber ? districts?.parties[0] : data.parties[0],
        "segundo": codeNumber ? districts?.parties[1] : data.parties[1],
        "tercero": codeNumber ? districts?.parties[2] : data.parties[2],
        "cuarto": codeNumber ? districts?.parties[3] : data.parties[3],
        "quinto": codeNumber ? districts?.parties[4] : data.parties[4],
      };
      const instanceElection = query[2] == "generales" ? "general" : "balotaje";
      if(typeElection == "presidente"){
        if(percentagesScrutinized < porcentaje  && codeNumber === ""){
          postCreate = crearPost(dataPost,instanceElection, urlIframe, percentagesScrutinized, modified_time)
        }else if(districts?.tables_percent < porcentaje && codeNumber !== ""){
          postCreate = crearPost(dataPost,instanceElection, urlIframe,districts?.tables_percent, modified_time)
        }else if(percentagesScrutinized >= porcentaje && codeNumber === ""){
          postCreate = crearPost(dataPost,typeElection, urlIframe, percentagesScrutinized, modified_time)
        }else{
          postCreate = crearPost(dataPost,typeElection, urlIframe, districts?.tables_percent, modified_time)
        }
      }
      
      if(typeElection != "presidente"){
        if (codeLetter === "AR-C") {
          if(percentagesScrutinized < porcentaje && codeNumber === ""){
            postCreate = crearPost(dataPost,"generalJefeGobiernoA1", urlIframe, percentagesScrutinized)
          }else if(districts?.tables_percent < porcentaje && codeNumber !== ""){
            postCreate = crearPost(dataPost,"generalJefeGobiernoA1", urlIframe, districts?.tables_percent)
          }else if(percentagesScrutinized >= porcentaje && codeNumber === ""){
            postCreate = crearPost(dataPost, "jefeGobierno", urlIframe, percentagesScrutinized)
          }else{
            postCreate = crearPost(dataPost, "jefeGobierno", urlIframe, districts?.tables_percent);
          }
        } else {
          if(percentagesScrutinized < porcentaje && codeNumber === ""){
            postCreate = crearPost(dataPost,"generalGobernadorA1", urlIframe, percentagesScrutinized)
          }else if(districts?.tables_percent < porcentaje && codeNumber !== ""){
            postCreate = crearPost(dataPost,"generalGobernadorA1", urlIframe, districts?.tables_percent)
          }else if(percentagesScrutinized >= porcentaje && codeNumber === ""){
            postCreate = crearPost(dataPost, "gobernador", urlIframe, percentagesScrutinized)
          }else{
            postCreate = crearPost(dataPost, "gobernador", urlIframe, districts?.tables_percent);
          }
        }
      }
      titleMeta =  postCreate?.title ?? ""
      descriptionMeta = postCreate?.description ? postCreate?.description : postCreate?.body[0]?.value.replace(/<[^>]*>/g, '')?.trim() ?? 'Elecciones Argentina 2023',

      this.setData({
        _id: `${codeLetter}${codeNumber}`,
        post: postCreate ?? [],
        url: url,
        data: data,
        districts: query[4] === "resultados-elecciones-argentina_8_AR.html" ? [] : data?.districts ?? [],
        provincias: typeElection == "presidente" ? provincias : provinciasGobernador ?? [],
        distritosProvincia: distritosProvincia ?? [],
        gobernadorSitemap: gobernadorSitemap ?? [],
        textSeparador: `Mirá los resultados en los demás distritos de ${nameProvincia}`,
        textSeparadorProvincias:`Mirá los resultados en las demás provincias de Argentina`,
        imgPostPri:imgPostPri ?? "https://www.clarin.com/img/2023/10/06/OHw4KnSeM_1256x620__1.jpg",     
        tags: tags,   
      });

      this.setTitle(postCreate?.title || "Resultado Elecciones 2023");
      this.setTitleHeader('Elecciones');
      this.setMeta(this.parseMeta({
        description: descriptionMeta,
        title: titleMeta,
        keywords: keywords,
        url: url,
        urlImage: imgPostPri,
        extra: [
          { name: "title", content: titleMeta },
          { name: "distribution", content: "global" },
          { name: "robots", content: "max-image-preview:large" },
          { property: "article:published_time", content: query[2] == "balotaje" ? "2023-11-19T13:00:00.000Z": "2023-10-22T19:05:00.000Z" },
          { property: "article:modified_time", content: modified_time }
        ]
      }));
    }else{
      const filteredProvinceNames = [];
      const selectedProvince = mappings.provinces;
      const selectedDistricts = mappings.districts;
      for (const key in selectedProvince) {
        if (selectedProvince[key].code === codeLetter) {
          filteredProvinceNames.push(selectedProvince[key]);
        }
      }
      const filteredDistricts = selectedDistricts[filteredProvinceNames[0]?.id] || [];
      const filteredDistrictNames = filteredDistricts.find(district => district.code === codeComplet);    
      const dataPost = {
        "last_update": new Date(),
        "provincia": filteredProvinceNames[0]?.name || "Argentina",
        "provinciaLocalidad": codeNumber ? `${filteredProvinceNames[0]?.name} - ${filteredDistrictNames?.name}` : filteredProvinceNames[0]?.name || "Argentina",
        "localidad": filteredDistrictNames?.name || "",
      };
      if(typeElection == "presidente"){
        if(codeNumber === ""){
          postCreate = crearPost(dataPost,"general", "", "")
        }else if(codeNumber != ""){
          postCreate = crearPost(dataPost,"general", "", "")
        }
      }
      
      if(typeElection != "presidente"){
        if (codeLetter === "AR-C") {
          if(codeNumber === ""){
            postCreate = crearPost(dataPost,"generalJefeGobiernoA1", "", "")
          }else if(codeNumber != ""){
            postCreate = crearPost(dataPost,"generalJefeGobiernoA1", "", "")
          }
        } else {
          if(codeNumber === ""){
            postCreate = crearPost(dataPost,"generalGobernadorA1", "", "")
          }else if(codeNumber != ""){
            postCreate = crearPost(dataPost,"generalGobernadorA1", "", "")
          }
        }
      }
      const provinciaGobernadoresIds = ['01', '02', '03', '08']
      const responseGob = provinciaGobernadoresIds.map(id => selectedProvince[id]);
  
      provincias = Object.values(selectedProvince).map(item => {
        return {
          id: `presidente_${item.id}`,
          name: item.name,
          code: item.code,
          url: `/elecciones/2023/generales/presidente/resultados-${item.name.toLowerCase().replace(/\s/g, '-')}_8_${item.code}.html`,
          last_update: 1696678200000,
          publication_date: '2023-10-11T07:00:00.000Z',
          title: `Elecciones 2023: Presidente en ${item.name}`
        };
      });
  
      const provinciasGob = Object.values(responseGob).map(item => {
        return {
          id: `presidente_${item.id}`,
          name: item.name,
          code: item.code,
          url: `/elecciones/2023/generales/gobernador/resultados-${item.name.toLowerCase().replace(/\s/g, '-')}_8_${item.code}.html`,
          last_update: 1696678200000,
          publication_date: '2023-10-11T07:00:00.000Z',
          title: `Elecciones 2023: Presidente en ${item.name}`
        };
      });
      
      let verificaciongob = responseGob.find(prov => prov.code === codeLetter)
      let verificacionPre = provincias.find(prov => prov.code === codeLetter)
      
      const verificar = (typeof verificacionPre !== "undefined" &&  typeElection === "presidente") || (typeof verificaciongob !== "undefined" && typeElection !== "presidente");
      const districtsWithUrls = filteredDistricts.map(district => ({
        ...district,
        url: generateValidUrl(district.name, baseUrl, filteredProvinceNames[0]?.name, district.code)
      }));
      
      
      if(verificar){
        this.setData({
          data: [],
          url: url,
          post: postCreate ?? [],
          districts:districtsWithUrls ?? [],
          provincias: typeElection == "presidente" ? provincias : provinciasGob ?? [],
          textSeparador: `Mirá los resultados en las demás distritos de ${filteredProvinceNames[0]?.name}`,
          textSeparadorProvincias:`Mirá los resultados en las demás Provincias de Argentina`,
          imgPostPri: "https://www.clarin.com/img/2023/10/06/OHw4KnSeM_1256x620__1.jpg",
        });
  
        this.setTitle(typeElection === 'presidente' ? 'Elecciones presidente 2023' : `Elecciones Gobernador 2023`);
        this.setTitleHeader('Elecciones');
        this.setMeta(this.parseMeta({
          description: "Elecciones 2023",
          title: "Elecciones 2023",
          url: url,
          extra: [
            { name: "title", content: "Elecciones 2023" },
            { name: "distribution", content: "global" },
            { name: "robots", content: "max-image-preview:large" },
            { property: "article:published_time", content: "2023-10-22T19:05:00.000Z" },
            { property: "article:modified_time", content: "2023-10-22T19:05:00.000Z" },
          ]
        }));
      }else{
        this.setData({
          code : 404,
        });
      }
      //toda esta parte es si y solo si la consulta a la api de apiUrl el code es diferente de 200
    }
    
  }
}