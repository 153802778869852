import { useEffect, useRef, useState } from "react"

const useElementOnScreem = (options, activate, isSupportedBrowser) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }

  useEffect(() => {
    if (activate && isSupportedBrowser) {
      const observer = new IntersectionObserver(callbackFunction, options);
      if (containerRef.current) observer.observe(containerRef.current);
      return () => {
        if (containerRef.current) observer.unobserve(containerRef.current);
      }
    }
  }, [containerRef, options, activate])

  return [containerRef, isVisible]
}

export default useElementOnScreem;