import { cities } from "@/shared/Weather/Rural/cities";

const fetchWeatherRuralCity = async (lat = "-34.6083000", long = "-58.3712000") => {
    try {
      const response = await fetch(`/api/clima/rural/${lat}/${long}`); 
      const { data } = await response.json();
      return {
        code: 200,
        data: data ?? {},
        error: null,
      };
    } catch (error) {
      console.log("error-helper", error);
      return false;
    }
  };

 
  
  export const getWeatherRuralCity = async (city) => {
    const searchCity = city ? cities.find(
      (element) =>
        element.realName.normalize("NFC").toLowerCase() ===
        city.normalize("NFC").toLowerCase()
    ): "";
    const { data } = await fetchWeatherRuralCity(
      searchCity?.lat,
      searchCity?.long
    );
    return data;
  };