export const newsFromBucketToTemplateNota = (news) => {
  if (news?.images?.[0]?.value?.clippings) {
    news.images = news.images[0].value.clippings;
  } else if (news?.related?.images?.[0]?.value?.clippings) {
    news.images = news.related.images[0].value.clippings;
  } else if (news?.related?.images?.[0]?.clippings) {
    news.images = news.related.images[0].clippings;
  }
  if (news?.authors) {
    news.author = news.authors;
  }
  if (news?.sections) {
    news.section = news.sections;
  }
  delete news['selectedClippingId']; // No lo necesitamos para los listados automaticos sino pueden romper
  return news;
};
