import { Service } from "models/Service";
import { text } from "./termsConditions";
import { privacy } from "./privacy";
import { confidentiality } from "./confidentiality";

export class TermsConditions extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Términos y condiciones - Clarin.com`,
        keywords: '',
        title: `Términos y condiciones - Clarin.com`,
        url: `ayuda/terminos-condiciones.html`, 
        extra: [],
      },
      section: "terminos",
      sectionName: "Términos y condiciones",
      template: "TermsConditions",
      title: `Términos y condiciones`,
      widthFull: true,
      slug: "",
      titleHeader:"Términos y condiciones"
    });    
  }
  parseData = async (query) => {

        if (query?.[1] == "terminos-condiciones.html") {
          this.setData(text);
        } 
        if (query?.[1] == "politica-proteccion-datos-personales.html") {
          this.setData(privacy);
          this.setTitleHeader("Política de protección de datos personales");
          this.setTitle(`Política de protección de datos personales`);
          this.setMeta(this.parseMeta({ 
            description: `Política de protección de datos personales Clarín.com`, 
            keywords: "protección,privacidad ", 
            title: `Política de protección de datos personales`, 
            url: `ayuda/politica-proteccion-datos-personales.html`, 
            extra: [] 
          }));
        }
        if (query?.[1] == "normas-confidencialidad-privacidad.html") {
          this.setTitleHeader("Normas de confidencialidad / privacidad")
          this.setData(confidentiality);
          this.setTitle(`Normas de confidencialidad / privacidad`);
          this.setMeta(this.parseMeta({ 
            description: `Normas de confidencialidad / privacidad Clarín.com`, 
            keywords: "confidencialidad,privacidad ", 
            title: `Normas de confidencialidad / privacidad`, 
            url: `ayuda/normas-confidencialidad-privacidad.html`, 
            extra: [] 
          }));
        } 
  }
}