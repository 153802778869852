export const futbolData = async (competition, team) =>{
    try {
        const dataConfig = await getTournamentData(competition);
        
        if (Object.keys(dataConfig.data).length === 0) {
            return {};
        }
        
        const {competitionId,seasonId } = dataConfig.data;
        
        let competitionSeason = competitionId+seasonId
        let fixtureId;
        let fixtureFaseName;
        let matchesDetail = [];
        // Data average
        const competitionIds = [7242021, 3842021, 3842022, 7242022, 3842023, 7242023];
        const averageData = await Promise.all(competitionIds.map((id) => getTeamsData(id).then((data) => data.data)));
        // Data para las tablas
        const teamsData = await getTeamsData(competitionSeason);

        // Cup data table
        const cupIds = [3842022, 7242022];
        const orderedCup = await Promise.all(cupIds.map((id) => getTeamsData(id).then((data) => data.data)));

        // Fixture
        const searchLiga = await getLiga(competitionId);
        const dataTournament = searchLiga?.data.filter((item) => item._id === competitionSeason) || [];
        const fixture = (Array.isArray(dataTournament) ? dataTournament[0]?.fixture : dataTournament?.fixture)?.find((element) => element.status === "actual") || {};

        fixtureId = fixture?._id;
        fixtureFaseName = fixture?.faseName;
        
        const matchesData = await getMatches(fixtureId);
        const matches = matchesData?.data?.fixture?.[0]?.matches || [];
        
        const promises = matches.map(async (match) => {
            const matchesDetailData = await getMatchesDetail(match._id);
            return matchesDetailData.data !== '' ? matchesDetailData.data[0] : undefined;
        });
        
        const matchesDetailDataArray = await Promise.all(promises);
        matchesDetail.push(...matchesDetailDataArray.filter(Boolean));
        

        const ligaData = {
            liga: searchLiga.data || [],
            fixtures: fixture || [],
            fixtureId: fixture?._id || "",
            fixtureFaseName: fixtureFaseName || "",
            matches,
            matchesDetailTemp: matchesDetail || [],
            team: team || "",
        };
    
        const data = {
            tournamentConf: dataConfig.data,
            averageData,
            positionsData: teamsData.data || [],
            positionsCupData: orderedCup,
            ligaData,
        };

    return data;

    } catch (error) {
        // Handle errors if any
        console.error(error);
        return {};
    } 
}



const getTournamentData = async (competition) => {
    const options = {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'X-Web-TTL': '0s',
        'Cache-Control': 'no-cache'
      }
    };
    try {
        const response = await fetch('http://api_editoriales.agea.com.ar:8000/api/opta/v2/configs?id=tournaments_clarin&apikey=clarin', options);
        const { item } = await response.json();
        const tournamentData = item.value.find((item) => item.slug == competition  && item.estadistica == true );
        if (tournamentData) {
            const {slug,competition_id,season_id,is_cup,name,season_name} = tournamentData;
    
            return {
                code: 200,
                data: {
                    slug,
                    competitionId: competition_id,
                    competitionSeason: competition_id + season_id,
                    isCup: is_cup,
                    name,
                    seasonName: season_name,
                    seasonId: season_id
                },
                error: null
            };
        } else {
            return {
                code: 404,
                data: null,
                error: 'Tournament data not found.'
            };
        }
    } catch (err) {
        return {
            code: 500,
            data: null,
            error: err
        };
    }
}

const getTeamsData = async (competition) => {
    const options = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'X-Web-TTL': '0s',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    };
    return await fetch(`http://api_editoriales.agea.com.ar:8000/api/estadisticas/teams/?apikey=ole&tournament=${competition}&orderBy=score`, options)
    .then(async (response) => {
        const { item } = await response.json();
        return {
            code: 200,
            data: item ? item : '',
            error: null,
        };
    })
    .catch((err) => ({
        code: 500,
        data: null,
        error: err,
    }));
}

const getLiga = async (competitionId) => {
    const options = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'X-Web-TTL': '0s',
            'Cache-Control': 'no-cache'
        }
    };
    return await fetch(`http://api_editoriales.agea.com.ar:8000/api/opta/v2/tournaments?apikey=clarin&ifNotExistTournamentDelete=false&category=${competitionId}&orderBy=_id`, options)
    .then(async (response) => {
        const { items } = await response.json();
        return {
            code: 200,
            data: items ? items : '',
            error: null,
        };
    })
    .catch((err) => ({
        code: 500,
        data: null,
        error: err,
    }));
};
  
const getMatches = async (actualId) => {
    const options = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'X-Web-TTL': '0s',
            'Cache-Control': 'no-cache'
        }
    };
    return await fetch(`http://api_editoriales.agea.com.ar:8000/api/estadisticas/matchDay/?apikey=ole&_id=${actualId}`, options)
    .then(async (response) => {
        const { item } = await response.json();
        return {
            code: 200,
            data: item ? item : {},
            error: null,
        };
    })
    .catch((err) => ({
        code: 500,
        data: null,
        error: err,
    }));
    
}

const getMatchesDetail = async (matchId) => {
    const options = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'X-Web-TTL': '0s',
            'Cache-Control': 'no-cache'
        }
    };
    return await fetch(`http://api_editoriales.agea.com.ar:8000/api/mam/classic/detail/${matchId}?apikey=ole`, options)
    .then(async (response) => {
        const {items} = await response.json();     
        return {
            code: 200,
            data: items ? items : '',
            error: null,
            };
        })
        .catch((err) => ({
            code: 500,
            data: null,
            error: err,
        }));     
};
export const orderedTournament = (competition) => {
    let team = [];
    if(competition){
        competition?.teams?.map(function(item, index){
            team[item._id] = [];
            team[item._id]["id"] = item._id;
            team[item._id]["seasonId"] = competition.category.season_id;
            team[item._id]["tournamentId"] = competition.category._id;
            team[item._id]["teamName"] = item.name;
            team[item._id]["score"] = item.score ? item.score : 0;
            team[item._id]["avg"] = item.average ? item.average : 0;
            team[item._id]["PJ"] = item.matches.total ? item.matches.total : 0;
            return team;
        });
    }
    return team
}

export const orderedAvg = (data) => {
    let avg = [];
    for (let i = 0; i < data.length; i++) {
        if(i==data.length-1){
            data[i].map(function(item,index){
                let score1 = data[0][item.id]?.score ?? 0;
                let score2 = data[1][item.id]?.score ?? 0;
                let score3 = data[2][item.id]?.score ?? 0;
                let score4 = data[3][item.id]?.score ?? 0;
                let score5 = data[4][item.id]?.score ?? 0;

                let score2021 = score1 + score2;
                let score2022 = score3 + score4;
                let score2023 = score5 + item?.score;

                let matches1 = data[0][item.id]?.PJ ?? 0;
                let matches2 = data[1][item.id]?.PJ ?? 0;
                let matches3 = data[2][item.id]?.PJ ?? 0;
                let matches4 = data[3][item.id]?.PJ ?? 0;
                let matches5 = data[4][item.id]?.PJ ?? 0;

                let matches2021 = matches1 + matches2;
                let matches2022 = matches3 + matches4;
                let matches2023 = matches5 + item?.PJ;

                let sumTotalMatches = matches2021 + matches2022 + matches2023;

                let totalPoints = score2021 + score2022 + score2023;
                let average = totalPoints / sumTotalMatches;

                avg[item.id] = [];
                avg[item.id]["id"] = item.id;
                avg[item.id]["teamName"] = item.teamName;
                avg[item.id]["seasonId"] = item.seasonId;
                avg[item.id]["tournamentId"] = item.tournamentId;
                avg[item.id]["score2021"] = score2021;
                avg[item.id]["score2022"] = score2022;
                avg[item.id]["score2023"] = score2023;
                avg[item.id]["totalPoints"] = totalPoints;
                avg[item.id]["average"] = average.toFixed(3);
                avg[item.id]["matches"] = sumTotalMatches;

                return avg;
            });
        }

        avg.sort(function(a, b){
            let keyA = a.average;
            let keyB = b.average;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            if(keyA == keyB) return a.totalPoints - b.totalPoints
            
            //return -1;
        }).reverse();
    }
    return avg;
}