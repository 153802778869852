import { Service } from "models/Service";

export class VideoDocumentary extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Clarín DOCS es una propuesta de periodismo audiovisual de calidad del diario Clarín. Los videos documentales de esta serie cuentan historias cercanas e íntimas de honda calidad humana, reflejan un mundo en constante cambio y proponen nuevos enfoques y miradas sobre temas de trascendencia social.`,
        keywords: '',
        title: `Video documentales`,
        url: `videos-documentales-clarin`, 
        extra: [],
      },
      section: "documentales",
      sectionName: "Documentales",
      template: "VideoDocumentary",
      title: `Video documentales`,
      widthFull: false,
      slug: "videos-documentales-clarin",
      titleHeader:"Documentales"
    });    
  }
}