export const primary = [
  {
    id: "PESO",
    name: "Peso Argentino",
    symbol: "ARS",
  },
  {
    id: "DBNA",
    name: "Dólar Banco Nación",
    symbol: "USD",
    slug: "dolar-oficial"
  },
  {
    id: "DLRTOUR",
    name: "Dólar Turista",
    symbol: "USD",
    slug: "dolar-turista"
  },
  {
    id: "DLRBLE",
    name: "Dólar Blue",
    symbol: "USD",
    slug: "dolar-blue"
  },
  {
    id: "DLRMEP",
    name: "Dólar MEP",
    symbol: "USD",
    slug: "dolar-mep"
  },
  {
    id: "DLRCCL",
    name: "Dólar CCL",
    symbol: "USD",
    slug: "dolar-ccl"
  }
  
];

export const secondary = [
  { id: "MEXPES", name: "Peso MX", symbol: "MXN" },
  { id: "URUPES", name: "Peso Uruguayo", symbol: "UYI" },
  { id: "LIBPES", name: "Libra Esterlina", symbol: "GBP" },
  { id: "YENPES", name: "Yen", symbol: "JP" },
  { id: "EURPES", name: "Euro", symbol: "EUR" },
  { id: "CMPES", name: "Real", symbol: "BRL" },
];

export const allowedBonds = [
  "AN18",
  "AY24",
  "DICA",
  "AA37",
  "PR13",
  "PR15",
  "PARP",
  "AO20",
  "CUAP",
  "DICP",
  "NF18",
  "TVPA",
  "TVPY",
  "TVPP",
  "TVPE",
  "AA25",
  "AA46",
];
