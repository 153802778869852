export const getDataImageFromRelated = (related) => {
  let clippings = [];
  let epigraphe = "";
  if (related?.images?.[0]) {
    if (related.images[0].value) {
      clippings = related.images[0].value.clippings;
    } else if (related.images[0].clippings) {
      clippings = related.images[0].clippings;
    }
    epigraphe = related.images[0].epigraphe ? related.images[0].epigraphe : "";
  }
  return { epigraphe, clippings };
};

export const getDataVideoFromRelated = (related) => {
  if (
    related &&
    related.videos &&
    related.videos[0] &&
    related.videos[0].value &&
    related.videos[0].value.entityId
  ) {
    return {
      entityId: related.videos[0].value.entityId,
      title: related?.videos[0]?.value?.epigraph ? related?.videos[0]?.value?.epigraph : related?.videos[0]?.value?.description,
      thumbnail: related?.videos[0]?.value?.thumbnail,
      mp4: related?.videos[0]?.value?.videoFiles?.mp4 || null,
    };
  }
  return null;
};
