export const mappings = {
    electionCodes: {
      '01': 'president',
      '02': 'senators',
      '03': 'delegates',
      '04': 'governors',
      '05': 'senatorsprov',
      '06': 'delegatesprov',
      '07': 'councilors',
      '08': 'parlasur',
      '09': 'regionalparlasur',
      '10': 'mayors',
    },  
    mandates: {
      "02": {      
        "AR-B": 3,
        "AR-P": 3,
        "AR-Y": 3,
        "AR-N": 3,
        "AR-F": 3,
        "AR-D": 3,
        "AR-J": 3,
        "AR-Z": 3
      },
      "03": {      
        "AR-B": 35,     
        "AR-C": 12,
        "AR-S": 10,
        "AR-X": 9,
        "AR-M": 5,
        "AR-T": 5,
        "AR-W": 4,
        "AR-E": 4,
        "AR-N": 4,
        "AR-A": 4,
        "AR-G": 4,
        "AR-H": 3,
        "AR-U": 3,
        "AR-P": 3,
        "AR-Y": 3,
        "AR-F": 3,
        "AR-R": 3,
        "AR-J": 3,
        "AR-V": 3,
        "AR-K": 2,
        "AR-L": 2,
        "AR-Q": 2,
        "AR-Z": 2,
        "AR-D": 2
      },
      "01": {
        "AR-B": 1,
        "AR-C": 1,
        "AR-S": 1,
        "AR-X": 1,
        "AR-M": 1,
        "AR-T": 1,
        "AR-W": 1,
        "AR-E": 1,
        "AR-N": 1,
        "AR-A": 1,
        "AR-G": 1,
        "AR-H": 1,
        "AR-U": 1,
        "AR-P": 1,
        "AR-Y": 1,
        "AR-F": 1,
        "AR-R": 1,
        "AR-J": 1,
        "AR-V": 1,
        "AR-K": 1,
        "AR-L": 1,
        "AR-Q": 1,
        "AR-Z": 1,
        "AR-D": 1
      },
      "04": {
        "AR-B": 1,
        "AR-C": 1,
        "AR-E": 1,
        "AR-K": 1
      },
      "08": {
        "AR-B": 1,
        "AR-C": 1,
        "AR-S": 1,
        "AR-X": 1,
        "AR-M": 1,
        "AR-T": 1,
        "AR-W": 1,
        "AR-E": 1,
        "AR-N": 1,
        "AR-A": 1,
        "AR-G": 1,
        "AR-H": 1,
        "AR-U": 1,
        "AR-P": 1,
        "AR-Y": 1,
        "AR-F": 1,
        "AR-R": 1,
        "AR-J": 1,
        "AR-V": 1,
        "AR-K": 1,
        "AR-L": 1,
        "AR-Q": 1,
        "AR-Z": 1,
        "AR-D": 1
      },
      "09": {
        "AR-B": 1,
        "AR-C": 1,
        "AR-S": 1,
        "AR-X": 1,
        "AR-M": 1,
        "AR-T": 1,
        "AR-W": 1,
        "AR-E": 1,
        "AR-N": 1,
        "AR-A": 1,
        "AR-G": 1,
        "AR-H": 1,
        "AR-U": 1,
        "AR-P": 1,
        "AR-Y": 1,
        "AR-F": 1,
        "AR-R": 1,
        "AR-J": 1,
        "AR-V": 1,
        "AR-K": 1,
        "AR-L": 1,
        "AR-Q": 1,
        "AR-Z": 1,
        "AR-D": 1
      },
    },
    provinces: {
      '01': { id: '01', code: 'AR-C', name: 'Ciudad Autónoma Bs.As.' },
      '02': { id: '02', code: 'AR-B', name: 'Buenos Aires' },
      '03': { id: '03', code: 'AR-K', name: 'Catamarca' },
      '04': { id: '04', code: 'AR-X', name: 'Córdoba' },
      '05': { id: '05', code: 'AR-W', name: 'Corrientes' },
      '06': { id: '06', code: 'AR-H', name: 'Chaco' },
      '07': { id: '07', code: 'AR-U', name: 'Chubut' },
      '08': { id: '08', code: 'AR-E', name: 'Entre Ríos' },
      '09': { id: '09', code: 'AR-P', name: 'Formosa' },
      '10': { id: '10', code: 'AR-Y', name: 'Jujuy' },
      '11': { id: '11', code: 'AR-L', name: 'La Pampa' },
      '12': { id: '12', code: 'AR-F', name: 'La Rioja' },
      '13': { id: '13', code: 'AR-M', name: 'Mendoza' },
      '14': { id: '14', code: 'AR-N', name: 'Misiones' },
      '15': { id: '15', code: 'AR-Q', name: 'Neuquén' },
      '16': { id: '16', code: 'AR-R', name: 'Río Negro' },
      '17': { id: '17', code: 'AR-A', name: 'Salta' },
      '18': { id: '18', code: 'AR-J', name: 'San Juan' },
      '19': { id: '19', code: 'AR-D', name: 'San Luis' },
      '20': { id: '20', code: 'AR-Z', name: 'Santa Cruz' },
      '21': { id: '21', code: 'AR-S', name: 'Santa Fe' },
      '22': { id: '22', code: 'AR-G', name: 'Santiago del Estero' },
      '23': { id: '23', code: 'AR-T', name: 'Tucumán' },
      '24': { id: '24', code: 'AR-V', name: 'Tierra del Fuego' },
      '25': { id: '25', code: 'AR', name: 'Argentina' },
    },
    districts: {
      '01': [
        { id: '001', code: 'AR-C001', name: 'Comuna 1' },
        { id: '002', code: 'AR-C002', name: 'Comuna 2' },
        { id: '003', code: 'AR-C003', name: 'Comuna 3' },
        { id: '004', code: 'AR-C004', name: 'Comuna 4' },
        { id: '005', code: 'AR-C005', name: 'Comuna 5' },
        { id: '006', code: 'AR-C006', name: 'Comuna 6' },
        { id: '007', code: 'AR-C007', name: 'Comuna 7' },
        { id: '008', code: 'AR-C008', name: 'Comuna 8' },
        { id: '009', code: 'AR-C009', name: 'Comuna 9' },
        { id: '010', code: 'AR-C010', name: 'Comuna 10' },
        { id: '011', code: 'AR-C011', name: 'Comuna 11' },
        { id: '012', code: 'AR-C012', name: 'Comuna 12' },
        { id: '013', code: 'AR-C013', name: 'Comuna 13' },
        { id: '014', code: 'AR-C014', name: 'Comuna 14' },
        { id: '015', code: 'AR-C015', name: 'Comuna 15' }
      ],
      '02': [
        { id: '001', code: 'AR-B001', name: 'Adolfo Alsina' },
        { id: '002', code: 'AR-B002', name: 'Alberti' },
        { id: '003', code: 'AR-B003', name: 'Almirante Brown' },
        { id: '004', code: 'AR-B004', name: 'Avellaneda' },
        { id: '005', code: 'AR-B005', name: 'Ayacucho' },
        { id: '006', code: 'AR-B006', name: 'Azul' },
        { id: '007', code: 'AR-B007', name: 'Bahía Blanca' },
        { id: '008', code: 'AR-B008', name: 'Balcarce' },
        { id: '009', code: 'AR-B009', name: 'Baradero' },
        { id: '010', code: 'AR-B010', name: 'Arrecifes' },
        { id: '011', code: 'AR-B011', name: 'Berazategui' },
        { id: '012', code: 'AR-B012', name: 'Berisso' },
        { id: '013', code: 'AR-B013', name: 'Bolívar' },
        { id: '014', code: 'AR-B014', name: 'Bragado' },
        { id: '015', code: 'AR-B015', name: 'Brandsen' },
        { id: '016', code: 'AR-B016', name: 'Campana' },
        { id: '017', code: 'AR-B017', name: 'Cañuelas' },
        { id: '018', code: 'AR-B018', name: 'Capitán Sarmiento' },
        { id: '019', code: 'AR-B019', name: 'Carlos Casares' },
        { id: '020', code: 'AR-B020', name: 'Carlos Tejedor' },
        { id: '021', code: 'AR-B021', name: 'Carmen de Areco' },
        { id: '022', code: 'AR-B022', name: 'Patagones' },
        { id: '023', code: 'AR-B023', name: 'Castelli' },
        { id: '024', code: 'AR-B024', name: 'Colón' },
        { id: '025', code: 'AR-B025', name: 'Coronel Dorrego' },
        { id: '026', code: 'AR-B026', name: 'Coronel Pringles' },
        { id: '027', code: 'AR-B027', name: 'Coronel Rosales' },
        { id: '028', code: 'AR-B028', name: 'Coronel Suárez' },
        { id: '029', code: 'AR-B029', name: 'Chacabuco' },
        { id: '030', code: 'AR-B030', name: 'Chascomús' },
        { id: '031', code: 'AR-B031', name: 'Chivilcoy' },
        { id: '032', code: 'AR-B032', name: 'Daireaux' },
        { id: '033', code: 'AR-B033', name: 'Dolores' },
        { id: '034', code: 'AR-B034', name: 'Ensenada' },
        { id: '035', code: 'AR-B035', name: 'Escobar' },
        { id: '036', code: 'AR-B036', name: 'Esteban Echeverría' },
        { id: '037', code: 'AR-B037', name: 'Exaltación de la Cruz' },
        { id: '038', code: 'AR-B038', name: 'Florencio Varela' },
        { id: '039', code: 'AR-B039', name: 'General Alvarado' },
        { id: '040', code: 'AR-B040', name: 'General Alvear' },
        { id: '041', code: 'AR-B041', name: 'General Arenales' },
        { id: '042', code: 'AR-B042', name: 'General Belgrano' },
        { id: '043', code: 'AR-B043', name: 'General Guido' },
        { id: '044', code: 'AR-B044', name: 'General Lamadrid' },
        { id: '045', code: 'AR-B045', name: 'Las Heras' },
        { id: '046', code: 'AR-B046', name: 'General Lavalle' },
        { id: '047', code: 'AR-B047', name: 'General Madariaga' },
        { id: '048', code: 'AR-B048', name: 'General Paz' },
        { id: '049', code: 'AR-B049', name: 'General Pinto' },
        { id: '050', code: 'AR-B050', name: 'General Pueyrredón' },
        { id: '051', code: 'AR-B051', name: 'General Rodríguez' },
        { id: '052', code: 'AR-B052', name: 'General San Martín' },
        { id: '053', code: 'AR-B053', name: 'San Miguel' },
        { id: '054', code: 'AR-B054', name: 'General Viamonte' },
        { id: '055', code: 'AR-B055', name: 'General Villegas' },
        { id: '056', code: 'AR-B056', name: 'Gonzales Chaves' },
        { id: '057', code: 'AR-B057', name: 'Guamini' },
        { id: '058', code: 'AR-B058', name: 'Hipólito Yrigoyen' },
        { id: '059', code: 'AR-B059', name: 'Juárez' },
        { id: '060', code: 'AR-B060', name: 'Junín' },
        { id: '061', code: 'AR-B061', name: 'Matanza' },
        { id: '062', code: 'AR-B062', name: 'Lanús' },
        { id: '063', code: 'AR-B063', name: 'La Plata' },
        { id: '064', code: 'AR-B064', name: 'Laprida' },
        { id: '065', code: 'AR-B065', name: 'Las Flores' },
        { id: '066', code: 'AR-B066', name: 'Leandro N. Alem' },
        { id: '067', code: 'AR-B067', name: 'Lincoln' },
        { id: '068', code: 'AR-B068', name: 'Loberia' },
        { id: '069', code: 'AR-B069', name: 'Lobos' },
        { id: '070', code: 'AR-B070', name: 'Lomas de Zamora' },
        { id: '071', code: 'AR-B071', name: 'Luján' },
        { id: '072', code: 'AR-B072', name: 'Magdalena' },
        { id: '073', code: 'AR-B073', name: 'Maipú' },
        { id: '074', code: 'AR-B074', name: 'Mar Chiquita' },
        { id: '075', code: 'AR-B075', name: 'Marcos Paz' },
        { id: '076', code: 'AR-B076', name: 'Mercedes' },
        { id: '077', code: 'AR-B077', name: 'Merlo' },
        { id: '078', code: 'AR-B078', name: 'Monte' },
        { id: '079', code: 'AR-B079', name: 'Moreno' },
        { id: '080', code: 'AR-B080', name: 'Morón' },
        { id: '081', code: 'AR-B081', name: 'Navarro' },
        { id: '082', code: 'AR-B082', name: 'Necochea' },
        { id: '083', code: 'AR-B083', name: 'Nueve de Julio' },
        { id: '084', code: 'AR-B084', name: 'Olavarría' },
        { id: '085', code: 'AR-B085', name: 'Pehuajó' },
        { id: '086', code: 'AR-B086', name: 'Pellegrini' },
        { id: '087', code: 'AR-B087', name: 'Pergamino' },
        { id: '088', code: 'AR-B088', name: 'Pila' },
        { id: '089', code: 'AR-B089', name: 'Pilar' },
        { id: '090', code: 'AR-B090', name: 'Pinamar' },
        { id: '091', code: 'AR-B091', name: 'Puan' },
        { id: '092', code: 'AR-B092', name: 'Quilmes' },
        { id: '093', code: 'AR-B093', name: 'Ramallo' },
        { id: '094', code: 'AR-B094', name: 'Rauch' },
        { id: '095', code: 'AR-B095', name: 'Rivadavia' },
        { id: '096', code: 'AR-B096', name: 'Rojas' },
        { id: '097', code: 'AR-B097', name: 'Roque Pérez' },
        { id: '098', code: 'AR-B098', name: 'Saavedra' },
        { id: '099', code: 'AR-B099', name: 'Saladillo' },
        { id: '100', code: 'AR-B100', name: 'Salliqueló' },
        { id: '101', code: 'AR-B101', name: 'Salto' },
        { id: '102', code: 'AR-B102', name: 'San Andrés de Giles' },
        { id: '103', code: 'AR-B103', name: 'San Antonio de Areco' },
        { id: '104', code: 'AR-B104', name: 'San Cayetano' },
        { id: '105', code: 'AR-B105', name: 'San Fernando' },
        { id: '106', code: 'AR-B106', name: 'San Isidro' },
        { id: '107', code: 'AR-B107', name: 'San Nicolás' },
        { id: '108', code: 'AR-B108', name: 'San Pedro' },
        { id: '109', code: 'AR-B109', name: 'San Vicente' },
        { id: '110', code: 'AR-B110', name: 'Suipacha' },
        { id: '111', code: 'AR-B111', name: 'Tandil' },
        { id: '112', code: 'AR-B112', name: 'Tapalqué' },
        { id: '113', code: 'AR-B113', name: 'Tigre' },
        { id: '114', code: 'AR-B114', name: 'Tornquist' },
        { id: '115', code: 'AR-B115', name: 'Trenque Lauquén' },
        { id: '116', code: 'AR-B116', name: 'Tordillo' },
        { id: '117', code: 'AR-B117', name: 'Tres Arroyos' },
        { id: '118', code: 'AR-B118', name: 'Tres de Febrero' },
        { id: '119', code: 'AR-B119', name: 'La Costa' },
        { id: '120', code: 'AR-B120', name: 'Monte Hermoso' },
        { id: '121', code: 'AR-B121', name: 'Veinticinco de Mayo' },
        { id: '122', code: 'AR-B122', name: 'Vicente López' },
        { id: '123', code: 'AR-B123', name: 'Villa Gesell' },
        { id: '124', code: 'AR-B124', name: 'Villarino' },
        { id: '125', code: 'AR-B125', name: 'Zárate' },
        { id: '126', code: 'AR-B126', name: 'Tres Lomas' },
        { id: '127', code: 'AR-B127', name: 'F. Ameghino' },
        { id: '128', code: 'AR-B128', name: 'Presidente Perón' },
        { id: '129', code: 'AR-B129', name: 'J. C. Paz' },
        { id: '130', code: 'AR-B130', name: 'Malvinas Argentinas' },
        { id: '131', code: 'AR-B131', name: 'Punta Indio' },
        { id: '132', code: 'AR-B132', name: 'Ezeiza' },
        { id: '133', code: 'AR-B133', name: 'Ituzaingo' },
        { id: '134', code: 'AR-B134', name: 'Hurlingham' },
        { id: '135', code: 'AR-B135', name: 'Lezama' },
      ],
      '03': [
        { id: '001', code: 'AR-K001', name: 'Capital' },
        { id: '002', code: 'AR-K002', name: 'Capayan' },
        { id: '003', code: 'AR-K003', name: 'La Paz' },
        { id: '004', code: 'AR-K004', name: 'Ancasti' },
        { id: '005', code: 'AR-K005', name: 'El Alto' },
        { id: '006', code: 'AR-K006', name: 'Santa Rosa' },
        { id: '007', code: 'AR-K007', name: 'Paclin' },
        { id: '008', code: 'AR-K008', name: 'Valle Viejo' },
        { id: '009', code: 'AR-K009', name: 'Fray Mamerto Esquiú' },
        { id: '010', code: 'AR-K010', name: 'Ambato' },
        { id: '011', code: 'AR-K011', name: 'Pomán' },
        { id: '012', code: 'AR-K012', name: 'Andalgalá' },
        { id: '013', code: 'AR-K013', name: 'Belén' },
        { id: '014', code: 'AR-K014', name: 'Antofagasta de la Sierra' },
        { id: '015', code: 'AR-K015', name: 'Tinogasta' },
        { id: '016', code: 'AR-K016', name: 'Santa María' },
      ],
      '04': [
        { id: '001', code: 'AR-X001', name: 'Capital' },
        { id: '002', code: 'AR-X002', name: 'Calamuchita' },
        { id: '003', code: 'AR-X003', name: 'Colón' },
        { id: '004', code: 'AR-X004', name: 'Cruz del Eje' },
        { id: '005', code: 'AR-X005', name: 'General Roca' },
        { id: '006', code: 'AR-X006', name: 'General San Martín' },
        { id: '007', code: 'AR-X007', name: 'Ischilin' },
        { id: '008', code: 'AR-X008', name: 'Juárez Celman' },
        { id: '009', code: 'AR-X009', name: 'Marcos Juárez' },
        { id: '010', code: 'AR-X010', name: 'Minas' },
        { id: '011', code: 'AR-X011', name: 'Pocho' },
        { id: '012', code: 'AR-X012', name: 'Punilla' },
        { id: '013', code: 'AR-X013', name: 'Río Cuarto' },
        { id: '014', code: 'AR-X014', name: 'Río Primero' },
        { id: '015', code: 'AR-X015', name: 'Río Seco' },
        { id: '016', code: 'AR-X016', name: 'Río Segundo' },
        { id: '017', code: 'AR-X017', name: 'Roque Sáenz Peña' },
        { id: '018', code: 'AR-X018', name: 'San Alberto' },
        { id: '019', code: 'AR-X019', name: 'San Javier' },
        { id: '020', code: 'AR-X020', name: 'San Justo' },
        { id: '021', code: 'AR-X021', name: 'Santa María' },
        { id: '022', code: 'AR-X022', name: 'Sobremonte' },
        { id: '023', code: 'AR-X023', name: 'Tercero Arriba' },
        { id: '024', code: 'AR-X024', name: 'Totoral' },
        { id: '025', code: 'AR-X025', name: 'Tulumba' },
        { id: '026', code: 'AR-X026', name: 'Unión' },
      ],
      '05': [
        { id: '001', code: 'AR-W001', name: 'Capital' },
        { id: '002', code: 'AR-W002', name: 'Goya' },
        { id: '003', code: 'AR-W003', name: 'Curuzú Cuatiá' },
        { id: '004', code: 'AR-W004', name: 'Mercedes' },
        { id: '005', code: 'AR-W005', name: 'Esquina' },
        { id: '006', code: 'AR-W006', name: 'Lavalle' },
        { id: '007', code: 'AR-W007', name: 'Santo Tomé' },
        { id: '008', code: 'AR-W008', name: 'Paso de los Libres' },
        { id: '009', code: 'AR-W009', name: 'Monte Caseros' },
        { id: '010', code: 'AR-W010', name: 'San Luis del Palmar' },
        { id: '011', code: 'AR-W011', name: 'Bella Vista' },
        { id: '012', code: 'AR-W012', name: 'Empedrado' },
        { id: '013', code: 'AR-W013', name: 'San Roque' },
        { id: '014', code: 'AR-W014', name: 'General Paz' },
        { id: '015', code: 'AR-W015', name: 'Saladas' },
        { id: '016', code: 'AR-W016', name: 'San Martín' },
        { id: '017', code: 'AR-W017', name: 'Concepción' },
        { id: '018', code: 'AR-W018', name: 'Mburucuya' },
        { id: '019', code: 'AR-W019', name: 'Ituzaingo' },
        { id: '020', code: 'AR-W020', name: 'Sauce' },
        { id: '021', code: 'AR-W021', name: 'San Cosme' },
        { id: '022', code: 'AR-W022', name: 'General Alvear' },
        { id: '023', code: 'AR-W023', name: 'San Miguel' },
        { id: '024', code: 'AR-W024', name: 'Itati' },
        { id: '025', code: 'AR-W025', name: 'Berón de Astrada' },
      ],
      '06': [
        { id: '001', code: 'AR-H001', name: 'San Fernando' },
        { id: '002', code: 'AR-H002', name: 'Primero de Mayo' },
        { id: '003', code: 'AR-H003', name: 'Libertad' },
        { id: '004', code: 'AR-H004', name: 'General Donovan' },
        { id: '005', code: 'AR-H005', name: 'Sargento Cabral' },
        { id: '006', code: 'AR-H006', name: 'Pcia. de la Plaza' },
        { id: '007', code: 'AR-H007', name: 'Bermejo' },
        { id: '008', code: 'AR-H008', name: 'Lib. Gral. San Martín' },
        { id: '009', code: 'AR-H009', name: 'Tapenaga' },
        { id: '010', code: 'AR-H010', name: 'San Lorenzo' },
        { id: '011', code: 'AR-H011', name: 'Mayor L. J. Fontana' },
        { id: '012', code: 'AR-H012', name: 'O\'higgins' },
        { id: '013', code: 'AR-H013', name: 'Comandante Fernández' },
        { id: '014', code: 'AR-H014', name: 'Quitilipi' },
        { id: '015', code: 'AR-H015', name: '25 de Mayo' },
        { id: '016', code: 'AR-H016', name: 'Maipú' },
        { id: '017', code: 'AR-H017', name: 'Independencia' },
        { id: '018', code: 'AR-H018', name: 'General Belgrano' },
        { id: '019', code: 'AR-H019', name: '9 de Julio' },
        { id: '020', code: 'AR-H020', name: 'Chacabuco' },
        { id: '021', code: 'AR-H021', name: '12 de Octubre' },
        { id: '022', code: 'AR-H022', name: '2 de Abril' },
        { id: '023', code: 'AR-H023', name: 'Fray J. Sta. María de Oro' },
        { id: '024', code: 'AR-H024', name: 'Almirante Brown' },
        { id: '025', code: 'AR-H025', name: 'General Güemes' },
      ],
      '07': [
        { id: '001', code: 'AR-U001', name: 'Rawson' },
        { id: '002', code: 'AR-U002', name: 'Biedma' },
        { id: '003', code: 'AR-U003', name: 'Telsen' },
        { id: '004', code: 'AR-U004', name: 'Gastre' },
        { id: '005', code: 'AR-U005', name: 'Cushamen' },
        { id: '006', code: 'AR-U006', name: 'Futaleufú' },
        { id: '007', code: 'AR-U007', name: 'Languiñeo' },
        { id: '008', code: 'AR-U008', name: 'Tehuelches' },
        { id: '009', code: 'AR-U009', name: 'Paso de Indios' },
        { id: '010', code: 'AR-U010', name: 'Mártires' },
        { id: '011', code: 'AR-U011', name: 'F. Ameghino' },
        { id: '012', code: 'AR-U012', name: 'Gaiman' },
        { id: '013', code: 'AR-U013', name: 'Río Senguer' },
        { id: '014', code: 'AR-U014', name: 'Sarmiento' },
        { id: '015', code: 'AR-U015', name: 'Escalante' },
      ],
      '08': [
        { id: '001', code: 'AR-E001', name: 'Paraná' },
        { id: '003', code: 'AR-E003', name: 'La Paz' },
        { id: '004', code: 'AR-E004', name: 'Diamante' },
        { id: '005', code: 'AR-E005', name: 'Victoria' },
        { id: '006', code: 'AR-E006', name: 'Nogoyá' },
        { id: '007', code: 'AR-E007', name: 'Gualeguay' },
        { id: '008', code: 'AR-E008', name: 'Tala' },
        { id: '009', code: 'AR-E009', name: 'Uruguay' },
        { id: '010', code: 'AR-E010', name: 'Gualeguaychú' },
        { id: '011', code: 'AR-E011', name: 'Islas del Ibicuy' },
        { id: '012', code: 'AR-E012', name: 'Villaguay' },
        { id: '013', code: 'AR-E013', name: 'Concordia' },
        { id: '014', code: 'AR-E014', name: 'Federal' },
        { id: '015', code: 'AR-E015', name: 'Colón' },
        { id: '016', code: 'AR-E016', name: 'Feliciano' },
        { id: '017', code: 'AR-E017', name: 'Federación' },
        { id: '018', code: 'AR-E018', name: 'San Salvador' },
      ],
      '09': [
        { id: '001', code: 'AR-P001', name: 'Formosa' },
        { id: '002', code: 'AR-P002', name: 'Laishi' },
        { id: '003', code: 'AR-P003', name: 'Pilcomayo' },
        { id: '004', code: 'AR-P004', name: 'Pirané' },
        { id: '005', code: 'AR-P005', name: 'Pilagas' },
        { id: '006', code: 'AR-P006', name: 'Patiño' },
        { id: '007', code: 'AR-P007', name: 'Bermejo' },
        { id: '008', code: 'AR-P008', name: 'Matacos' },
        { id: '009', code: 'AR-P009', name: 'Ramón Lista' },
      ],
      '10': [
        { id: '001', code: 'AR-Y001', name: 'General Belgrano' },
        { id: '002', code: 'AR-Y002', name: 'Palpalá' },
        { id: '003', code: 'AR-Y003', name: 'San Antonio' },
        { id: '004', code: 'AR-Y004', name: 'El Carmen' },
        { id: '005', code: 'AR-Y005', name: 'San Pedro' },
        { id: '006', code: 'AR-Y006', name: 'Santa Bárbara' },
        { id: '007', code: 'AR-Y007', name: 'Ledesma' },
        { id: '008', code: 'AR-Y008', name: 'Valle Grande' },
        { id: '009', code: 'AR-Y009', name: 'Tumbaya' },
        { id: '010', code: 'AR-Y010', name: 'Tilcara' },
        { id: '011', code: 'AR-Y011', name: 'Humahuaca' },
        { id: '012', code: 'AR-Y012', name: 'Cochinoca' },
        { id: '013', code: 'AR-Y013', name: 'Rinconada' },
        { id: '014', code: 'AR-Y014', name: 'Santa Catalina' },
        { id: '015', code: 'AR-Y015', name: 'Yavi' },
        { id: '016', code: 'AR-Y016', name: 'Susques' },
      ],
      '11': [
        { id: '001', code: 'AR-L001', name: 'Atreuco' },
        { id: '002', code: 'AR-L002', name: 'Capital' },
        { id: '003', code: 'AR-L003', name: 'Caleu Caleu' },
        { id: '004', code: 'AR-L004', name: 'Catriló' },
        { id: '005', code: 'AR-L005', name: 'Chalileo' },
        { id: '006', code: 'AR-L006', name: 'Chapaleufu' },
        { id: '007', code: 'AR-L007', name: 'Chical-co' },
        { id: '008', code: 'AR-L008', name: 'Conhelo' },
        { id: '009', code: 'AR-L009', name: 'Curaco' },
        { id: '010', code: 'AR-L010', name: 'Guatrache' },
        { id: '011', code: 'AR-L011', name: 'Hucal' },
        { id: '012', code: 'AR-L012', name: 'Loventue' },
        { id: '013', code: 'AR-L013', name: 'Lihuel Calel' },
        { id: '014', code: 'AR-L014', name: 'Limay Mahuida' },
        { id: '015', code: 'AR-L015', name: 'Maraco' },
        { id: '016', code: 'AR-L016', name: 'Puelen' },
        { id: '017', code: 'AR-L017', name: 'Quemu Quemu' },
        { id: '018', code: 'AR-L018', name: 'Rancul' },
        { id: '019', code: 'AR-L019', name: 'Realicó' },
        { id: '020', code: 'AR-L020', name: 'Toay' },
        { id: '021', code: 'AR-L021', name: 'Trenel' },
        { id: '022', code: 'AR-L022', name: 'Utracán' },
      ],
      '12': [
        { id: '001', code: 'AR-F001', name: 'Capital' },
        { id: '002', code: 'AR-F002', name: 'Sanagasta' },
        { id: '003', code: 'AR-F003', name: 'Castro Barros' },
        { id: '004', code: 'AR-F004', name: 'Arauco' },
        { id: '005', code: 'AR-F005', name: 'San Blas de los Sauces' },
        { id: '006', code: 'AR-F006', name: 'Chilecito' },
        { id: '007', code: 'AR-F007', name: 'Famatina' },
        { id: '008', code: 'AR-F008', name: 'Felipe Varela' },
        { id: '009', code: 'AR-F009', name: 'General Lamadrid' },
        { id: '010', code: 'AR-F010', name: 'Vinchina' },
        { id: '011', code: 'AR-F011', name: 'Independencia' },
        { id: '012', code: 'AR-F012', name: 'Chamical' },
        { id: '013', code: 'AR-F013', name: 'Angel Vicente Peñaloza' },
        { id: '014', code: 'AR-F014', name: 'General Belgrano' },
        { id: '015', code: 'AR-F015', name: 'Juan Facundo Quiroga' },
        { id: '016', code: 'AR-F016', name: 'General Ortiz de Ocampo' },
        { id: '017', code: 'AR-F017', name: 'Rosario Vera Peñaloza' },
        { id: '018', code: 'AR-F018', name: 'General San Martín' },
      ],
      '13': [
        { id: '001', code: 'AR-M001', name: 'Capital' },
        { id: '002', code: 'AR-M002', name: 'Godoy Cruz' },
        { id: '003', code: 'AR-M003', name: 'Guaymallén' },
        { id: '004', code: 'AR-M004', name: 'Las Heras' },
        { id: '005', code: 'AR-M005', name: 'Lavalle' },
        { id: '006', code: 'AR-M006', name: 'Maipú' },
        { id: '007', code: 'AR-M007', name: 'Luján' },
        { id: '008', code: 'AR-M008', name: 'Tupungato' },
        { id: '009', code: 'AR-M009', name: 'San Martín' },
        { id: '010', code: 'AR-M010', name: 'Junín' },
        { id: '011', code: 'AR-M011', name: 'Rivadavia' },
        { id: '012', code: 'AR-M012', name: 'Tunuyán' },
        { id: '013', code: 'AR-M013', name: 'San Carlos' },
        { id: '014', code: 'AR-M014', name: 'Santa Rosa' },
        { id: '015', code: 'AR-M015', name: 'La Paz' },
        { id: '016', code: 'AR-M016', name: 'San Rafael' },
        { id: '017', code: 'AR-M017', name: 'Malargue' },
        { id: '018', code: 'AR-M018', name: 'General Alvear' },
      ],
      '14': [
        { id: '001', code: 'AR-N001', name: 'Capital' },
        { id: '002', code: 'AR-N002', name: 'Apóstoles' },
        { id: '003', code: 'AR-N003', name: 'Candelaria' },
        { id: '004', code: 'AR-N004', name: 'Leandro N. Alem' },
        { id: '005', code: 'AR-N005', name: 'Concepción de la Sierra' },
        { id: '006', code: 'AR-N006', name: 'San Javier' },
        { id: '007', code: 'AR-N007', name: 'San Ignacio' },
        { id: '008', code: 'AR-N008', name: 'Oberá' },
        { id: '009', code: 'AR-N009', name: 'L. General San Martín' },
        { id: '010', code: 'AR-N010', name: 'Cainguas' },
        { id: '011', code: 'AR-N011', name: '25 de Mayo' },
        { id: '012', code: 'AR-N012', name: 'Montecarlo' },
        { id: '013', code: 'AR-N013', name: 'Guaraní' },
        { id: '014', code: 'AR-N014', name: 'El Dorado' },
        { id: '015', code: 'AR-N015', name: 'San Pedro' },
        { id: '016', code: 'AR-N016', name: 'Iguazú' },
        { id: '017', code: 'AR-N017', name: 'Gral. Manuel Belgrano' },
      ],
      '15': [
        { id: '001', code: 'AR-Q001', name: 'Confluencia' },
        { id: '002', code: 'AR-Q002', name: 'Zapala' },
        { id: '003', code: 'AR-Q003', name: 'Añelo' },
        { id: '004', code: 'AR-Q004', name: 'Pehuenches' },
        { id: '005', code: 'AR-Q005', name: 'Chos Malal' },
        { id: '006', code: 'AR-Q006', name: 'Minas' },
        { id: '007', code: 'AR-Q007', name: 'Ñorquín' },
        { id: '008', code: 'AR-Q008', name: 'Loncopué' },
        { id: '009', code: 'AR-Q009', name: 'Picunches' },
        { id: '010', code: 'AR-Q010', name: 'Aluminé' },
        { id: '011', code: 'AR-Q011', name: 'Catan Lil' },
        { id: '012', code: 'AR-Q012', name: 'Picún Leufú' },
        { id: '013', code: 'AR-Q013', name: 'Collon Curá' },
        { id: '014', code: 'AR-Q014', name: 'Huiliches' },
        { id: '015', code: 'AR-Q015', name: 'Lácar' },
        { id: '016', code: 'AR-Q016', name: 'Los Lagos' },
      ],
      '16': [
        { id: '001', code: 'AR-R001', name: 'Adolfo Alsina' },
        { id: '002', code: 'AR-R002', name: 'Conesa' },
        { id: '003', code: 'AR-R003', name: 'San Antonio Oeste' },
        { id: '004', code: 'AR-R004', name: 'Valcheta' },
        { id: '005', code: 'AR-R005', name: 'Nueve de Julio' },
        { id: '006', code: 'AR-R006', name: '25 de Mayo' },
        { id: '007', code: 'AR-R007', name: 'Ñorquinco' },
        { id: '008', code: 'AR-R008', name: 'Pilcaniyeu' },
        { id: '009', code: 'AR-R009', name: 'Bariloche' },
        { id: '010', code: 'AR-R010', name: 'Pichi Mahuida' },
        { id: '011', code: 'AR-R011', name: 'Avellaneda' },
        { id: '012', code: 'AR-R012', name: 'General Roca' },
        { id: '013', code: 'AR-R013', name: 'El Cuy' },
      ],
      '17': [
        { id: '001', code: 'AR-A001', name: 'Capital' },
        { id: '002', code: 'AR-A002', name: 'La Caldera' },
        { id: '003', code: 'AR-A003', name: 'General Güemes' },
        { id: '004', code: 'AR-A004', name: 'Metán' },
        { id: '005', code: 'AR-A005', name: 'Anta' },
        { id: '006', code: 'AR-A006', name: 'Rivadavia' },
        { id: '007', code: 'AR-A007', name: 'Orán' },
        { id: '008', code: 'AR-A008', name: 'San Martín' },
        { id: '009', code: 'AR-A009', name: 'Iruya' },
        { id: '010', code: 'AR-A010', name: 'Santa Victoria' },
        { id: '011', code: 'AR-A011', name: 'Cerrillos' },
        { id: '012', code: 'AR-A012', name: 'Chicoana' },
        { id: '013', code: 'AR-A013', name: 'La Viña' },
        { id: '014', code: 'AR-A014', name: 'Guachipas' },
        { id: '015', code: 'AR-A015', name: 'Rosario de la Frontera' },
        { id: '016', code: 'AR-A016', name: 'La Candelaria' },
        { id: '017', code: 'AR-A017', name: 'Cafayate' },
        { id: '018', code: 'AR-A018', name: 'San Carlos' },
        { id: '019', code: 'AR-A019', name: 'Molinos' },
        { id: '020', code: 'AR-A020', name: 'Cachi' },
        { id: '021', code: 'AR-A021', name: 'Rosario de Lerma' },
        { id: '022', code: 'AR-A022', name: 'La Poma' },
        { id: '023', code: 'AR-A023', name: 'Los Andes' },
      ],
      '18': [
        { id: '001', code: 'AR-J001', name: 'Capital' },
        { id: '002', code: 'AR-J002', name: 'Santa Lucía' },
        { id: '003', code: 'AR-J003', name: 'Chimbas' },
        { id: '004', code: 'AR-J004', name: 'Rivadavia' },
        { id: '005', code: 'AR-J005', name: 'Zonda' },
        { id: '006', code: 'AR-J006', name: 'Rawson' },
        { id: '007', code: 'AR-J007', name: 'Nueve de Julio' },
        { id: '008', code: 'AR-J008', name: 'San Martín' },
        { id: '009', code: 'AR-J009', name: 'Angaco' },
        { id: '010', code: 'AR-J010', name: 'Albardón' },
        { id: '011', code: 'AR-J011', name: 'Ullúm' },
        { id: '012', code: 'AR-J012', name: 'Pocito' },
        { id: '013', code: 'AR-J013', name: 'Sarmiento' },
        { id: '014', code: 'AR-J014', name: '25 de Mayo' },
        { id: '015', code: 'AR-J015', name: 'Caucete' },
        { id: '016', code: 'AR-J016', name: 'Valle Fértil' },
        { id: '017', code: 'AR-J017', name: 'Jáchal' },
        { id: '018', code: 'AR-J018', name: 'Iglesia' },
        { id: '019', code: 'AR-J019', name: 'Calingasta' },
      ],
      '19': [
        { id: '001', code: 'AR-D001', name: 'Pueyrredon' },
        { id: '002', code: 'AR-D002', name: 'Pringles' },
        { id: '003', code: 'AR-D003', name: 'Pedernera' },
        { id: '004', code: 'AR-D004', name: 'Chacabuco' },
        { id: '005', code: 'AR-D005', name: 'San Martín' },
        { id: '006', code: 'AR-D006', name: 'Junín' },
        { id: '007', code: 'AR-D007', name: 'Ayacucho' },
        { id: '008', code: 'AR-D008', name: 'Belgrano' },
        { id: '009', code: 'AR-D009', name: 'Gobernador Dupuy' },
      ],
      '20': [
        { id: '001', code: 'AR-Z001', name: 'Deseado' },
        { id: '002', code: 'AR-Z002', name: 'Lago Buenos Aires' },
        { id: '003', code: 'AR-Z003', name: 'Magallanes' },
        { id: '004', code: 'AR-Z004', name: 'Río Chico' },
        { id: '005', code: 'AR-Z005', name: 'Corpen Aike' },
        { id: '006', code: 'AR-Z006', name: 'Lago Argentino' },
        { id: '007', code: 'AR-Z007', name: 'Güer Aike' },
      ],
      '21': [
        { id: '017', code: 'AR-S001', name: 'Belgrano' },
        { id: '014', code: 'AR-S002', name: 'Caseros' },
        { id: '005', code: 'AR-S003', name: 'Castellanos' },
        { id: '015', code: 'AR-S004', name: 'Constitución' },
        { id: '004', code: 'AR-S005', name: 'Garay' },
        { id: '016', code: 'AR-S006', name: 'General López' },
        { id: '009', code: 'AR-S007', name: 'General Obligado' },
        { id: '018', code: 'AR-S008', name: 'Iriondo' },
        { id: '001', code: 'AR-S009', name: 'La Capital' },
        { id: '002', code: 'AR-S011', name: 'Las Colonias' },
        { id: '012', code: 'AR-S012', name: '9 de Julio' },
        { id: '013', code: 'AR-S013', name: 'Rosario' },
        { id: '007', code: 'AR-S016', name: 'San Cristóbal' },
        { id: '011', code: 'AR-S017', name: 'San Javier' },
        { id: '003', code: 'AR-S018', name: 'San Jerónimo' },
        { id: '008', code: 'AR-S019', name: 'San Justo' },
        { id: '019', code: 'AR-S020', name: 'San Lorenzo' },
        { id: '006', code: 'AR-S021', name: 'San Martín' },
        { id: '010', code: 'AR-S022', name: 'Vera' },
      ],
      '22': [
        { id: '001', code: 'AR-G001', name: 'Capital' },
        { id: '002', code: 'AR-G002', name: 'Avellaneda' },
        { id: '003', code: 'AR-G003', name: 'Aguirre' },
        { id: '004', code: 'AR-G004', name: 'Alberdi' },
        { id: '005', code: 'AR-G005', name: 'Atamisqui' },
        { id: '006', code: 'AR-G006', name: 'Banda' },
        { id: '007', code: 'AR-G007', name: 'Belgrano' },
        { id: '008', code: 'AR-G008', name: 'Copo' },
        { id: '009', code: 'AR-G009', name: 'Choya' },
        { id: '010', code: 'AR-G010', name: 'Figueroa' },
        { id: '011', code: 'AR-G011', name: 'Guasayán' },
        { id: '012', code: 'AR-G012', name: 'Jiménez' },
        { id: '013', code: 'AR-G013', name: 'Loreto' },
        { id: '014', code: 'AR-G014', name: 'Juan F. Ibarra' },
        { id: '015', code: 'AR-G015', name: 'Mitre' },
        { id: '016', code: 'AR-G016', name: 'Moreno' },
        { id: '017', code: 'AR-G017', name: 'Ojo de Agua' },
        { id: '018', code: 'AR-G018', name: 'Pellegrini' },
        { id: '019', code: 'AR-G019', name: 'Quebrachos' },
        { id: '020', code: 'AR-G020', name: 'Rivadavia' },
        { id: '021', code: 'AR-G021', name: 'Robles' },
        { id: '022', code: 'AR-G022', name: 'Río Hondo' },
        { id: '023', code: 'AR-G023', name: 'Silipica' },
        { id: '024', code: 'AR-G024', name: 'San Martín' },
        { id: '025', code: 'AR-G025', name: 'Salavina' },
        { id: '026', code: 'AR-G026', name: 'Sarmiento' },
        { id: '027', code: 'AR-G027', name: 'Taboada' },
      ],
      '23': [
        { id: '001', code: 'AR-T001', name: 'Capital' },
        { id: '002', code: 'AR-T002', name: 'Lules' },
        { id: '003', code: 'AR-T003', name: 'Famaillá' },
        { id: '004', code: 'AR-T004', name: 'Monteros' },
        { id: '005', code: 'AR-T005', name: 'Chicligasta' },
        { id: '006', code: 'AR-T006', name: 'Río Chico' },
        { id: '007', code: 'AR-T007', name: 'Alberdi' },
        { id: '008', code: 'AR-T008', name: 'La Cocha' },
        { id: '009', code: 'AR-T009', name: 'Graneros' },
        { id: '010', code: 'AR-T010', name: 'Simoca' },
        { id: '011', code: 'AR-T011', name: 'Leales' },
        { id: '012', code: 'AR-T012', name: 'Cruz Alta' },
        { id: '013', code: 'AR-T013', name: 'Burruyacu' },
        { id: '014', code: 'AR-T014', name: 'Trancas' },
        { id: '015', code: 'AR-T015', name: 'Yerba Buena' },
        { id: '016', code: 'AR-T016', name: 'Tafí Viejo' },
        { id: '017', code: 'AR-T017', name: 'Tafí del Valle' },
      ],
      '24': [
        { id: '001', code: 'AR-V001', name: 'Ushuaia' },
        { id: '002', code: 'AR-V002', name: 'Río Grande' },
      ],
    }
  }