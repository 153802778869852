import HttpsProxyAgent from 'https-proxy-agent';
import { getDateFormatZulu } from './formatDate';

const agent = new HttpsProxyAgent('http://192.168.248.178:5889');

const formatProvinciaLocalidad = (cadena) => {
    const partes = cadena.split(", ");
    if (partes.length === 2) {
        const [provincia, localidad] = partes;
        return `${localidad}, ${provincia}`;
    } else {
        return cadena; 
    }
}

export const presidenteA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe, infoPresidentOrGovernors) => {
    const firstPlace = infoPresidentOrGovernors.data.parties[0]
    const winnerCandidate = firstPlace.candidates[0].fullname
    const winnerParty = firstPlace.name
    const winnerPercentage = firstPlace.votes_percent
    const secondPlace = infoPresidentOrGovernors.data.parties[1]
    const secondPlaceCandidate = secondPlace.candidates[0].fullname
    const secondPercentage = secondPlace.votes_percent
    const thirdPlace = infoPresidentOrGovernors.data.parties[2]
    const thirdPlacePlaceCandidate = thirdPlace.candidates[0].fullname
    const thirdPlacePercentage = thirdPlace.votes_percent
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)

    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);

    return {
        "title": `Resultados Elecciones 2023: quién gana para Presidente en ${formatedProvinciaLocalidad}`,
        "summary": `
       <ul>
           <li>Qué candidato fue el más votado este domingo 22 de octubre.</li>
           <li>Toda la información actualizada y el mapa electoral de los comicios presidenciales en ${formatedProvinciaLocalidad}, minuto a minuto.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Los ciudadanos de <strong>${formatedProvinciaLocalidad}</strong> acudieron nuevamente a las urnas 
               este domingo con motivo de las Elecciones presidenciales 2023 en Argentina. Según los resultados oficiales, 
               con el <strong>${data.mesasEscrutadas}%</strong> de mesas escrutadas, <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>,
                obtenía  <strong>${data.ganador.votes}</strong> votos, que representan el 
                <strong>${data.ganador.votes_percent}</strong> por ciento de los votos en este distrito.</p>`
            },
            {
                "type": "text",
                "value": `<p>En segundo lugar se ubicaba  <strong>${data.segundo.candidates[0].fullname}</strong>, de <strong>${data.segundo.name}</strong> 
             con  <strong>${data.segundo.votes}</strong> votos, que representan el <strong>${data.segundo.votes_percent}%</strong> por ciento de los sufragios. 
             En la tercera posición <strong>${data.tercero.candidates[0].fullname}</strong>, de <strong>${data.tercero.name}</strong>, alcanzaba 
             <strong>${data.tercero.votes}</strong> votos y un <strong>${data.tercero.votes_percent}</strong> por ciento de los sufragios.</p>`
            },



            {
                "type": "text",
                "value": `<p>De acuerdo con el escrutinio provisorio,  <strong>${data.cuarto.candidates[0].fullname}</strong>, de <strong>${data.cuarto.name}</strong>,
             se ubicaba cuarto con <strong>${data.cuarto.votes}</strong> votos (<strong>${data.cuarto.votes_percent}</strong> por ciento)
             y, en la quinta posición, <strong>${data.quinto.candidates[0].fullname}</strong>, de <strong>${data.quinto.name}</strong>,
              con <strong>${data.quinto.votes}</strong> votos (<strong>${data.quinto.votes_percent}</strong> por ciento). </p>`
            },
            {
                "type": "text",
                "value": `<p>A nivel nacional, <strong>${winnerCandidate} (${winnerParty})</strong> es quien
            lideraba los resultados electorales con el <strong>${winnerPercentage}</strong> por ciento de los sufragios,
            mientras que en segundo lugar se encontraba <strong>${secondPlaceCandidate}</strong>  con el <strong>${secondPercentage}</strong> por
            ciento, seguido por <strong>${thirdPlacePlaceCandidate}</strong>, que obtenía el <strong>${thirdPlacePercentage}</strong> por ciento de los
            votos, <strong>${infoPresidentOrGovernors.data.tables_percent}</strong>% de mesas escrutadas</p>`
            },
            {
                "type": "text",
                "value": `<p>En tanto, el <strong>${votosEnBlanco[0].votes_percent}</strong> por ciento de los votos eran en blanco y un 
               <strong>${votosNulos[0].votes_percent}</strong> por ciento corresponde a votos nulos.</p>`
            },
            {
                "type": "text",
                "value": `<p>El futuro Presidente asumirá su cargo el 10 de diciembre, en reemplazo de Alberto Fernández. Coincidentemente,
                ese día se cumplirán 40 años de democracia ininterrumpidos en la Argentina, luego de que Raúl Alfonsín iniciara su mandato en 1983.</p>`
            },

            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                    <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                    <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,                   
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023: qué pasa si no voté</a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
            De cuánto es la multa por no votar </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-votos-necesitan-ganar-primera-vuelta_0_5GZaXJgLnt.html\" target=\"_blank\">
            Elecciones 2023: ¿cuántos votos se necesitan para ganar en primera vuelta?
            </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-balotaje_0_lsy8JH3XN3.html">
            ¿Qué es el balotaje o segunda vuelta?
            </a></h2>`
            },
        ]
    }
};

export const gobernadorA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {
  
    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);

    return {
        "title": `Resultados Elecciones 2023 en ${formatProvinciaLocalidad(data.provinciaLocalidad)}: quién fue el candidato a gobernador más votado`,
        "summary": `
           <ul>
               <li>Quién gana los comicios provinciales este domingo 22 de octubre.</li>
               <li>Toda la información actualizada y el mapa electoral, minuto a minuto.</li>
           </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Con el <strong>${data.mesasEscrutadas}</strong> por ciento de las mesas escrutadas,
             el escrutinio provisorio de las elecciones provinciales 2023 en ${formatProvinciaLocalidad(data.provinciaLocalidad)}
             determinaba que el candidato más votado a gobernador era  <strong>${data.ganador.candidates[0].fullname}</strong>, 
             de <strong>${data.ganador.name}</strong>, quien sumaba <strong>${data.ganador.votes} votos</strong>,
              obteniendo el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos.
            </p>`
            },
            {
                "type": "text",
                "value": `<p>En segundo lugar para la gobernación se encontraba 
            <strong>${data.segundo.candidates[0].fullname}</strong>, de <strong>${data.segundo.name}</strong>,  que sumaba <strong>${data.segundo.votes}</strong> votos,
             logrando el <strong>${data.segundo.votes_percent}</strong>  
             por ciento de los sufragios; mientras que en la tercera posición aparecía <strong>${data.tercero.candidates[0].fullname}</strong>, de <strong>${data.tercero.name}</strong>, 
             con <strong>${data.tercero.votes}</strong>  votos (<strong>${data.tercero.votes_percent}</strong> por ciento).</p>`
            },

            {
                "type": "text",
                "value": `<p>En tanto, el <strong>${votosEnBlanco[0].votes_percent}%</strong> de los votos eran en blanco y un 
               <strong>${votosNulos[0].votes_percent}%</strong> (*) correspondía a votos nulos.</p>`
            },

            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },



            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                        <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,               
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023: qué pasa si no voté</a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
            De cuánto es la multa por no votar </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-votos-necesitan-ganar-primera-vuelta_0_5GZaXJgLnt.html\" target=\"_blank\">
            Elecciones 2023: ¿cuántos votos se necesitan para ganar en primera vuelta?
            </a></h2>`
            },
        ]
    }
};

export const jefeGobiernoA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {

    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);

    return {
        "title": `Resultados Elecciones 2023 en CABA: quién es el candidato a Jefe de Gobierno más votado ${data.localidad != "" ? `en la ${data.localidad}` : "" }`,
        "summary": `
       <ul>
           <li>Quién gana los comicios en la Ciudad de Buenos Aires este domingo 22 de octubre.</li>
           <li>Toda la información actualizada y el mapa electoral, minuto a minuto.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Con el <strong>${data.mesasEscrutadas}%</strong> de mesas escrutadas en el marco de las Elecciones 2023 desarrolladas este
                domingo en la Ciudad de Buenos Aires (CABA), el candidato a jefe de Gobierno porteño más votado  ${data.localidad != "" ? `en la <strong>${data.localidad}</strong>` : "" } 
                era <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>, quien obtenía <strong>${data.ganador.votes}</strong>
                 votos, lo que representa el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos.</p>`
            },

            {
                "type": "text",
                "value": `<p>En segundo lugar en CABA se encontraba  <strong>${data.segundo.candidates[0].fullname}</strong>, de <strong>${data.segundo.name}</strong>, 
               con <strong>${data.segundo.votes}</strong> votos, logrando el <strong>${data.segundo.votes_percent}</strong> 
                por ciento de los sufragios, mientras que en la tercera posición se encontraba <strong>${data.tercero.candidates[0].fullname}</strong>, de <strong>${data.tercero.name}</strong>, 
               con <strong>${data.tercero.votes}</strong> votos (<strong>${data.tercero.votes_percent}</strong> por ciento).</p>`
            },


            {
                "type": "text",
                "value": `<p>En tanto, el <strong>${votosEnBlanco[0].votes_percent}%</strong> de los votos son en blanco y un 
               <strong>${votosNulos[0].votes_percent}%</strong> (*) corresponde a votos nulos.</p>`
            },

            {
                "type": "text",
                "value": `<p>En paralelo a las elecciones para Presidente y jefe de Gobierno, 
               los porteños también debieron elegir 30 legisladores de la Ciudad (se renueva
                 la mitad de la Legislatura) y las 15 Juntas Comunales.</p>`
            },
            {
                "type": "text",
                "value": `<p>En paralelo a las elecciones para Presidente y jefe de Gobierno,
             los porteños también debieron elegir 30 legisladores de 
             la Ciudad (se renueva la mitad de la Legislatura) y las 15 Juntas Comunales.</p>`
            },
            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                        <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,                   
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023: qué pasa si no voté</a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
            De cuánto es la multa por no votar </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-votos-necesitan-ganar-primera-vuelta_0_5GZaXJgLnt.html\" target=\"_blank\">
            Elecciones 2023: ¿cuántos votos se necesitan para ganar en primera vuelta?
            </a></h2>`
            },
        ]
    }
};

// segunda alternativa

export const presidenteA2 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe, infoPresidentOrGovernors) => {

    const firstPlace = infoPresidentOrGovernors.data.parties[0]
    const winnerCandidate = firstPlace.candidates[0].fullname
    const winnerParty = firstPlace.name
    const winnerPercentage = firstPlace.votes_percent
    const secondPlace = infoPresidentOrGovernors.data.parties[1]
    const secondPlaceCandidate = secondPlace.candidates[0].fullname
    const secondPercentage = secondPlace.votes_percent
    const thirdPlace = infoPresidentOrGovernors.data.parties[2]
    const thirdPlacePlaceCandidate = thirdPlace.candidates[0].fullname
    const thirdPlacePercentage = thirdPlace.votes_percent
    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)


    return {
        "title": `Resultados Elecciones 2023 en ${formatedProvinciaLocalidad}: quién gana para Presidente`,
        "summary": `
       <ul>
           <li>Qué candidato es el más votado esta jornada democrática.</li>
           <li>Minuto a minuto, toda la información actualizada y el mapa electoral de los comicios presidenciales.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Los electores de <strong>${formatedProvinciaLocalidad}</strong> acudieron nuevamente a las urnas
             este domingo en el marco de las Elecciones presidenciales 2023. Según los datos oficiales del escrutinio provisorio, 
             <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>, 
            era la persona más votada para Presidente en este distrito, 
            con <strong>${data.ganador.votes}</strong> votos, lo que representa el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos.</p>`
            },
            {
                "type": "text",
                "value": `<p><strong>Con el ${data.mesasEscrutadas} </strong> por ciento de las mesas escrutadas,<strong> 
            los resultados de las elecciones 2023 en 
            ${formatedProvinciaLocalidad} son: </strong></p>`
            },
            {
                "type": "text",
                "value": `<ol>
                        <li>${data.ganador.candidates[0].fullname}, de ${data.ganador.name}, con ${data.ganador.votes} y el ${data.ganador.votes_percent}% de los votos</li>
                        <li>${data.segundo.candidates[0].fullname}, de ${data.segundo.name}, con ${data.segundo.votes} y el ${data.segundo.votes_percent}% de los votos</li>
                        <li>${data.tercero.candidates[0].fullname}, de ${data.tercero.name}, con ${data.tercero.votes} y el ${data.tercero.votes_percent}% de los votos</li>
                        <li>${data.cuarto.candidates[0].fullname}, de ${data.cuarto.name}, con ${data.cuarto.votes} y el ${data.cuarto.votes_percent}% de los votos</li>
                        <li>${data.quinto.candidates[0].fullname}, de ${data.quinto.name}, con ${data.quinto.votes} y el ${data.quinto.votes_percent}% de los votos</li>
                        <li>Votos en blanco: el ${votosEnBlanco[0].votes_percent}% fueron votos en blanco</li>
                        <li>Votos nulos: el ${votosNulos[0].votes_percent}%%</li>
                    </ol>`
            },



            {
                "type": "text",
                "value": `<p>A nivel nacional, <strong>${winnerCandidate} (${winnerParty})</strong> es quien
            lideraba los resultados electorales con el <strong>${winnerPercentage}</strong> por ciento de los sufragios,
            mientras que en segundo lugar se encontraba <strong>${secondPlaceCandidate}</strong>  con el <strong>${secondPercentage}</strong> por
            ciento, seguido por <strong>${thirdPlacePlaceCandidate}</strong>, que obtenía el <strong>${thirdPlacePercentage}</strong> por ciento de los
            votos, con el<strong>${infoPresidentOrGovernors.data.tables_percent}</strong>% de mesas escrutadas</p>`
            },


            {
                "type": "text",
                "value": `<p>El futuro Presidente asumirá su cargo el 10 de diciembre, en reemplazo de Alberto Fernández. Coincidentemente,
                ese día se cumplirán 40 años de democracia ininterrumpidos en la Argentina, luego de que Raúl Alfonsín iniciara su mandato en 1983.</p>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                        <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,                   
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023: qué pasa si no voté</a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
            De cuánto es la multa por no votar </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-votos-necesitan-ganar-primera-vuelta_0_5GZaXJgLnt.html\" target=\"_blank\">
            Elecciones 2023: ¿cuántos votos se necesitan para ganar en primera vuelta?
            </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-balotaje_0_lsy8JH3XN3.html">
            ¿Qué es el balotaje o segunda vuelta?
            </a></h2>`
            },
        ]
    }
};

export const gobernadorA2 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {

    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });
    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)


    return {
        "title": `Resultados Elecciones 2023: quién fue el candidato a gobernador más votado en ${formatProvinciaLocalidad(data.provinciaLocalidad)}`,
        "summary": `
       <ul>
           <li>Comicios provinciales: quién gana en esta jornada de votación.</li>
           <li>Toda la información actualizada y el mapa electoral, minuto a minuto.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>El escrutinio provisorio de las elecciones provinciales 2023 desarrolladas este domingo en <strong>${formatedProvinciaLocalidad}</strong> determina que el candidato 
            más votado a gobernador es <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>, quien obtiene <strong>${data.ganador.votes}</strong> votos, 
            lo que representa el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos,
            con el <strong>${data.mesasEscrutadas}</strong>  por ciento de las mesas escrutadas.</p>`
            },

            {
                "type": "text",
                "value": `<p>En segundo lugar para la gobernación se encuentra
               <strong>${data.segundo.candidates[0].fullname}</strong>, de <strong>${data.segundo.name}</strong>, que suma
               <strong>${data.segundo.votes}</strong> votos, alcanzando el <strong>${data.segundo.votes_percent}%</strong> por
               ciento de los sufragios, mientras que en la tercera posición aparece
               <strong>${data.tercero.candidates[0].fullname}</strong>, de <strong>${data.tercero.name}</strong>,
                con <strong>${data.tercero.votes}</strong> votos (<strong>${data.tercero.votes_percent}%</strong>).</p>`
            },

            {
                "type": "text",
                "value": `<p>En tanto, el <strong>${votosEnBlanco[0].votes_percent}%</strong> de los votos son en blanco y un
               <strong>${votosNulos[0].votes_percent}%</strong> (*) corresponde a votos nulos.</p>`
            },
            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },

            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                        <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`, 
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class=" class="more-links"">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023 en <strong>${formatedProvinciaLocalidad}</strong>: qué pasa si no voté</a></h2>`
            },
          
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
               Multa por no votar: cuánto es el valor</a></h2>`
            },
         
          
        ]
    }
};

export const jefeGobiernoA2 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {
   
    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);

    return {
        "title": `Resultados Elecciones 2023 en Capital ${data.localidad}: quién es el candidato a Jefe de Gobierno más votado`,
        "summary": `
       <ul>
           <li>Quién gana los comicios en CABA.</li>
           <li>Toda la información actualizada y el mapa electoral, minuto a minuto.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>En el marco de las Elecciones 2023 desarrolladas este domingo en la Ciudad de Buenos
               Aires, el candidato a Jefe de Gobierno porteño más votado en la ${data.localidad} es 
               <strong>${data.ganador.candidates[0].fullname}</strong>, de   <strong>${data.ganador.name}</strong>, quien obtiene
                 <strong>${data.ganador.votes}</strong> votos, que representan el <strong>${data.ganador.votes_percent}%</strong> de
               los votos, con el   <strong>${data.mesasEscrutadas}</strong>  por ciento de las mesas escrutadas.</p>`
            },
            {
                "type": "text",
                "value": `<p>En segundo lugar  está <strong>${data.segundo.candidates[0].fullname}</strong>, de 
               <strong>${data.segundo.name}</strong>, que logra <strong>${data.segundo.votes}</strong> votos y el
               <strong>${data.segundo.votes_percent}%</strong> de los sufragios, mientras que en la tercera
               posición se encuentra <strong>${data.tercero.candidates[0].fullname}</strong>, de 
               <strong>${data.tercero.name}</strong>, con <strong>${data.tercero.votes}</strong> votos
               (<strong>${data.tercero.votes_percent}</strong>  por ciento).</p>`
            },

            {
                "type": "text",
                "value": `<p>En tanto, el <strong>${votosEnBlanco[0].votes_percent}%</strong> de los votos son en blanco y un
               <strong>${votosNulos[0].votes_percent}%</strong> (*) corresponde a votos nulos.</p>`
            },
            {
                "type": "text",
                "value": `<p>En paralelo a las elecciones para Presidente y jefe de Gobierno, los porteños también
               debieron elegir 30 legisladores de la Ciudad (se renueva la mitad de la Legislatura) y las 15
               Juntas Comunales.</p>`
            },
            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },
                        
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                        <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,        
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones CABA 2023: qué pasa si no voté</a></h2>`
            },
           
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
               De cuánto es la multa por no votar en CABA</a></h2>`
            },
           
        ]
    }
};

// tercera alternativa

export const presidenteA3 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe, infoPresidentOrGovernors) => {
    
    const firstPlace = infoPresidentOrGovernors.data.parties[0]
    const winnerCandidate = firstPlace.candidates[0].fullname
    const winnerParty = firstPlace.name
    const winnerPercentage = firstPlace.votes_percent
    const secondPlace = infoPresidentOrGovernors.data.parties[1]
    const secondPlaceCandidate = secondPlace.candidates[0].fullname
    const secondPercentage = secondPlace.votes_percent
    const thirdPlace = infoPresidentOrGovernors.data.parties[2]
    const thirdPlacePlaceCandidate = thirdPlace?.candidates?.[0]?.fullname
    const thirdPlacePercentage = thirdPlace?.votes_percent
    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)

    let candidatesList = "<ol>";

    if (data.ganador && data.ganador.candidates && data.ganador.candidates.length > 0) {
        candidatesList += `<li>${data.ganador.candidates[0].fullname}, de ${data.ganador.name}, con ${data.ganador.votes} y el ${data.ganador.votes_percent}% de los votos</li>`;
    }

    if (data.segundo && data.segundo.candidates && data.segundo.candidates.length > 0) {
        candidatesList += `<li>${data.segundo.candidates[0].fullname}, de ${data.segundo.name}, con ${data.segundo.votes} y el ${data.segundo.votes_percent}% de los votos</li>`;
    }

    if (data.tercero && data.tercero.candidates && data.tercero.candidates.length > 0) {
        candidatesList += `<li>${data.tercero.candidates[0].fullname}, de ${data.tercero.name}, con ${data.tercero.votes} y el ${data.tercero.votes_percent}% de los votos</li>`;
    }

    if (data.cuarto && data.cuarto.candidates && data.cuarto.candidates.length > 0) {
        candidatesList += `<li>${data.cuarto.candidates[0].fullname}, de ${data.cuarto.name}, con ${data.cuarto.votes} y el ${data.cuarto.votes_percent}% de los votos</li>`;
    }

    if (data.quinto && data.quinto.candidates && data.quinto.candidates.length > 0) {
        candidatesList += `<li>${data.quinto.candidates[0].fullname}, de ${data.quinto.name}, con ${data.quinto.votes} y el ${data.quinto.votes_percent}% de los votos</li>`;
    }

    const formattedCandidatesList = candidatesList + "</ol>";

    return {
        "title": `Resultados Elecciones 2023: quién gana para Presidente en ${formatedProvinciaLocalidad}`,
        "summary": `
       <ul>
           <li>Qué candidato fue el más votado en ${formatedProvinciaLocalidad}.</li>
           <li>Toda la información actualizada y el mapa electoral de los comicios presidenciales, minuto a minuto.
           </li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Los ciudadanos de <strong>${formatedProvinciaLocalidad}</strong> acudieron nuevamente a las urnas 
            este domingo con motivo de las Elecciones presidenciales 2023 en Argentina. Según los resultados oficiales del escrutinio provisorio, 
            <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>,
            es la persona más votada para Presidente en este distrito, con <strong>${data.ganador.votes}</strong> votos, que representan el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos.
            </p>`
            },
            {
                "type": "text",
                "value": `<p><strong>Con el ${data.mesasEscrutadas} </strong> por ciento de las mesas escrutadas,<strong> 
            los resultados de las elecciones 2023 en 
            ${formatedProvinciaLocalidad} son: </strong></p>`
            },
            {
                "type": "text",
                "value": formattedCandidatesList
            },

            {
                "type": "text",
                "value": `<p>Asimismo, el <strong>${votosEnBlanco[0].votes_percent}</strong> por ciento de los votos son en blanco y un
               <strong>${votosNulos[0].votes_percent}</strong> por ciento corresponde a votos nulos.</p>`
            },
            {
                "type": "text",
                "value": `<p>A nivel nacional, <strong>${winnerCandidate} (${winnerParty})</strong> es quien
            lideraba los resultados electorales con el <strong>${winnerPercentage}</strong> por ciento de los sufragios,
            mientras que en segundo lugar se encuentra <strong>${secondPlaceCandidate}</strong>  con el <strong>${secondPercentage}</strong> por
            ciento, seguido por <strong>${thirdPlacePlaceCandidate}</strong>, que obtiene el <strong>${thirdPlacePercentage}</strong> por ciento de los
            votos.</p>`
            },

            {
                "type": "text",
                "value": `<p>El futuro Presidente asumirá su cargo el 10 de diciembre, en reemplazo de Alberto Fernández. Coincidentemente,
                ese día se cumplirán 40 años de democracia ininterrumpidos en la Argentina, luego de que Raúl Alfonsín iniciara su mandato en 1983.</p>`
            },

            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                    <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>
                `,
                "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },

            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023: qué pasa si no voté</a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
            De cuánto es la multa por no votar </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-votos-necesitan-ganar-primera-vuelta_0_5GZaXJgLnt.html\" target=\"_blank\">
                Cuántos votos necesita un candidato para ganar en primera vuelta
            </a></h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/elecciones-2023-balotaje_0_lsy8JH3XN3.html">
            Qué es el balotaje o segunda vuelta
            </a></h2>`
            },
        ]
    }
};

export const gobernadorA3 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {
  
    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)

    return {
        "title": `Resultados Elecciones 2023, ${formatProvinciaLocalidad(data.provinciaLocalidad)}: quién fue el candidato a Gobernador más votado`,
        "summary": `
       <ul>
           <li>Quién gana los comicios en ${formatedProvinciaLocalidad}.</li>
           <li>Toda la información actualizada y el mapa electoral, minuto a minuto.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>El escrutinio provisorio de las elecciones provinciales 2023 desarrolladas este domingo en <strong>${formatedProvinciaLocalidad}</strong> determina que el candidato 
            más votado a gobernador es <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>, quien obtiene <strong>${data.ganador.votes}</strong> votos, 
            lo que representa el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos,
            con el <strong>${data.mesasEscrutadas}</strong>  por ciento de las mesas escrutadas.</p>`
            },

            {
                "type": "text",
                "value": `<p>En segundo lugar para la gobernación se encuentra <strong>${data.segundo.candidates[0].fullname}</strong>,
                 de <strong>${data.segundo.name}</strong>, que logra <strong>${data.segundo.votes}</strong>  votos, el <strong>${data.segundo.votes_percent}</strong>  
                 por ciento de los sufragios, mientras que en la tercera posición aparece <strong>${data.tercero.candidates[0].fullname}</strong>,
                  de  <strong>${data.tercero.name}</strong>, con <strong>${data.segundo.votes}</strong>  votos (<strong>${data.segundo.votes_percent}</strong> por ciento).</p>`
            },

  
            {
                "type": "text",
                "value": `<p>En tanto, el <strong>${votosEnBlanco[0].votes_percent}%</strong> de los votos son en blanco y un
               <strong>${votosNulos[0].votes_percent}%</strong>  corresponde a votos nulos.</p>`
            },
            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,                   
                "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               ${formatedProvinciaLocalidad} | Elecciones 2023: qué pasa si no voté</a></h2>`
            },
           
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
               Cuánto cuesta la multa por no votar</a></h2>`
            },
           
        ]
    }
};

export const jefeGobiernoA3 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {

    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });
    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);
    const fechasFormateadas = convertirFecha(data.last_update);
    let candidatesList = "<ol>";

    if (data.ganador && data.ganador.candidates && data.ganador.candidates.length > 0) {
        candidatesList += `<li>${data.ganador.candidates[0].fullname}, de ${data.ganador.name}, con ${data.ganador.votes} y el ${data.ganador.votes_percent}% de los votos</li>`;
    }

    if (data.segundo && data.segundo.candidates && data.segundo.candidates.length > 0) {
        candidatesList += `<li>${data.segundo.candidates[0].fullname}, de ${data.segundo.name}, con ${data.segundo.votes} y el ${data.segundo.votes_percent}% de los votos</li>`;
    }

    if (data.tercero && data.tercero.candidates && data.tercero.candidates.length > 0) {
        candidatesList += `<li>${data.tercero.candidates[0].fullname}, de ${data.tercero.name}, con ${data.tercero.votes} y el ${data.tercero.votes_percent}% de los votos</li>`;
    }

    if (data.cuarto && data.cuarto.candidates && data.cuarto.candidates.length > 0) {
        candidatesList += `<li>${data.cuarto.candidates[0].fullname}, de ${data.cuarto.name}, con ${data.cuarto.votes} y el ${data.cuarto.votes_percent}% de los votos</li>`;
    }

    if (data.quinto && data.quinto.candidates && data.quinto.candidates.length > 0) {
        candidatesList += `<li>${data.quinto.candidates[0].fullname}, de ${data.quinto.name}, con ${data.quinto.votes} y el ${data.quinto.votes_percent}% de los votos</li>`;
    }

    const formattedCandidatesList = candidatesList + "</ol>";
    let title = data.localidad != "" ? 
    `Resultados Elecciones 2023 en la ${data.localidad} de la Ciudad de Buenos Aires: quién es el candidato a Jefe de Gobierno más votado`
    :
    `Resultados Elecciones 2023 de la Ciudad de Buenos Aires: quién es el candidato a Jefe de Gobierno más votado`

    return {
        "title": title,
        "summary": `
       <ul>
           <li>Quién gana los comicios en Capital este domingo 22 de octubre.</li>
           <li>Toda la información actualizada y el mapa electoral, minuto a minuto.</li>
       </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>En el marco de las Elecciones 2023 desarrolladas este domingo en la Ciudad de Buenos
               Aires, el candidato a Jefe de Gobierno porteño más votado ${data.localidad != "" ? "en la" : "" } <strong>${data.localidad}</strong> es 
               <strong>${data.ganador.candidates[0].fullname}</strong>, de   <strong>${data.ganador.name}</strong>, quien obtiene
                 <strong>${data.ganador.votes}</strong> votos, que representan el <strong>${data.ganador.votes_percent}</strong> por ciento de las mesas escrutadas.</p>`
            },
            {
                "type": "text",
                "value": formattedCandidatesList
            },


            {
                "type": "text",
                "value": `<p>En paralelo a las elecciones para Presidente y jefe de Gobierno, los porteños también
               debieron elegir 30 legisladores de la Ciudad (se renueva la mitad de la Legislatura) y las 15
               Juntas Comunales.</p>`
            },
            {
                "type": "text",
                "value": `<p>* Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).</p>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                        <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>`,                   
                    "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `<hr class="separator" /><h2 class="read-more">Seguí leyendo</h2>`
            },
           
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html\" target=\"_blank\">
               Elecciones 2023, Ciudad de Buenos Aires: qué pasa si no voté</a></h2>`
            },
            
            {
                "type": "text",
                "value": `<h2 class="more-links"><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
               CABA: de cuánto es la multa por no votar</a></h2>`
            },
           
        ]
    }
};

export const generalPresidenteA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {
    const fechasFormateadas = convertirFecha(data.last_update);


    return {
        "title": `Resultados de las Elecciones 2023 para Presidente en ${formatProvinciaLocalidad(data.provinciaLocalidad)}`,
        "summary": `
       <ul>
           <li>Expectativa por los resultados de los comicios de este domingo 22 de octubre.</li>
           <li>Toda la información actualizada de los candidatos y el mapa electoral, minuto a minuto.</li>
       </ul>
       `,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>
                   Los ciudadanos de <strong> ${formatProvinciaLocalidad(data.provinciaLocalidad)} </strong> acuden nuevamente a las urnas este
                   domingo, con motivo de las <strong>Elecciones presidenciales 2023.</strong> Hay gran expectativa por los
                   <strong>resultados</strong>, que se conocerán después de las 18 y que determinarán si hay ganador en
                   primera vuelta o si debe celebrarse un balotaje entre los dos candidatos más votados.
               </p>`
            },
            {
                "type": "text",
                "value": `<p>
                   En este sentido, <strong>si la fórmula más votada obtiene más del 45%</strong> sus integrantes serán
                   proclamados presidente y vice de manera automática, pero si el porcentaje obtenido es
                   menor y la diferencia con el segundo no llega a los 10 puntos se deberá decidir en segunda
                   vuelta.
               </p>`
            },
            {
                "type": "text",
                "value": `<p>
                   El futuro Presidente asumirá su cargo el 10 de diciembre, en reemplazo de <strong> Alberto
                   Fernández.</strong> Coincidentemente, ese día se cumplirán 40 años de democracia ininterrumpidos
                   en la Argentina, luego de que <strong>Raúl Alfonsín</strong> iniciara su mandato en 1983.
               </p>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/elecciones-2023-candidatos-presidenciales-competiran-22-octubre_0_4NNTdpawDU.html" target="_blank">
               Elecciones 2023: todos los candidatos a Presidente</h2></a>`
            },
            {
                "type": "text",
                "value": `<p>
                   De acuerdo con los resultados de las PASO celebradas el 13 de agosto, cinco fórmulas
                   compiten por la Presidencia este domingo.
               </p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Javier Milei - Victoria Villarruel (La Libertad Avanza)</li>
                           <li>Patricia Bullrich - Luis Petri (Juntos por el Cambio)</li>
                           <li>Sergio Massa - Agustín Rossi (Unión por la Patria)</li>
                           <li>Juan Schiaretti - Florencio Randazzo (Hacemos por Nuestro País)</li>
                           <li>Myriam Bregman - Nicolás Del Caño (Frente de Izquierda y de los Trabajadores Unidad)</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/-balotaje-_0_73QJHZU5xA.html" target="_blank">
                       Elecciones: cuándo sería el balotaje 2023
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   "Por ley, el hipotético balotaje está previsto para un mes después de las generales. En este
                   caso, la segunda vuelta se desarrollaría el 19 de noviembre.
               </p>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/voto-padron-electoral-elecciones-2023_0_nEsUuYHPmI.html" target="_blank">
                       Dónde voto: consultá el padrón electoral
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   "¿Dónde voto?" y "padrón electoral" son dos de las consultas más frecuentes por estas
                   horas en todo el país. Consultá el padrón haciendo <a href="https://www.clarin.com/politica/voto-padron-electoral-elecciones-2023_0_nEsUuYHPmI.html" target="_blank">clic aquí</a>.
               </p>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html" target="_blank">
                   Elecciones 2023: qué pasa si no voto
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   Votar en Argentina es de carácter obligatorio para todos los ciudadanos mayores de edad
                   hasta los 70 años. Quienes no lo hagan tienen hasta 60 días para presentar una
                   justificación ante la Justicia Electoral y evitar multas económicas y administrativas.
               </p>`
            },
            {
                "type": "text",
                "value": `<p>
               Para consultar si se adeuda alguna multa hay que dirigirse a la web de la Cámara Nacional
               Electoral (CNE) e ingresar a la categoría "Registro de Infractores".
               </p>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html" target="_blank">
               Elecciones 2023: de cuánto es la multa por no votar</a></h2>`
            },
            {
                "type": "text",
                "value": `<p>El monto de la multa -que por la inflación quedó desactualizado y sorprende por lo reducido - dependerá de cuántas 
               infracciones pendientes de pago tenga acumuladas el ciudadano:</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Si no tiene infracciones previas por regularizar, son $50.</li>
                           <li>Si tiene 1 infracción previa sin regularizar, $100.</li>
                           <li>Si tiene 2 infracciones previas sin regularizar, $200.</li>
                           <li>Si tiene 3 infracciones previas sin regularizar, $400.</li>
                           <li>Si tiene 4 o más infracciones previas sin regularizar, $500.</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `
              
               <h2>
               <a href="https://www.clarin.com/politica/veda-electoral-puede-hacer-elecciones-2023_0_NTvT77Kj0L.html" target="_blank">
                   Veda electoral: hasta cuándo es y qué no se puede hacer
                </a>
                </h2>
               `
            },
            {
                "type": "text",
                "value": `<p>
                   El artículo 71 del Código Electoral establece que la veda electoral debe comenzar a regir 48
                   horas antes de la apertura de los comicios y finalizará tres horas después de terminar la
                   jornada electoral. Quienes no cumplan con la veda se ven expuestas a posibles denuncias o
                   sanciones, siempre y cuando se compruebe la violación del Código Electoral.
               </p>`
            },
            {
                "type": "text",
                "value": `<p>
                   Entre otras cosas, se encuentra prohibido realizar actos de campañas por parte de los
                   precandidatos presidenciales, como también no permitir la venta de bebidas alcohólicas o
                   declaraciones de propaganda política de funcionarios o medios de comunicación. Además,
                   también se prohíbe la difusión de encuestas que tengan que ver con las elecciones.
               </p>`
            },
        ]
    }
};

export const generalGobernadorA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {
    const fechasFormateadas = convertirFecha(data.last_update);
    const nombresDeCandidatos = data?.candidatos?.map(partido => partido.candidates[0].fullname);
    const listaDeCandidatosHTML = nombresDeCandidatos?.map(nombre => `<li>${nombre}</li>`).join('') || "";
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)

    return {
        "title": `Resultados de las Elecciones 2023 para gobernador en ${formatProvinciaLocalidad(data.provinciaLocalidad)}`,
        "summary": `
           <ul>
               <li>Expectativa por los resultados de los comicios de este domingo 22 de octubre.</li>
               <li>Toda la información actualizada de los candidatos, minuto a minuto.</li>
           </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Los ciudadanos de <strong>${formatedProvinciaLocalidad}</strong> acuden nuevamente a las urnas este
               domingo, en el marco de las <strong>Elecciones 2023</strong>. Hay gran expectativa por los <strong>resultados</strong>, que
               determinarán al futuro Presidente de la Nación y al nuevo gobernador de la provincia.</p>`
            },
            {
                "type": "text",
                "value": `<p>El Código Nacional Electoral establece que los primeros datos oficiales pueden difundirse
               recién tres horas después del cierre de los comicios, es decir, a partir de las 21.</p>`
            },
            {
                "type": "text",
                "value": `<h2>Elecciones 2023 en ${data.provincia}: todos los candidatos a gobernador</h2>`
            },
            {
                "type": "text",
                "value": `<ul>${listaDeCandidatosHTML}</ul>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/elecciones-2023-candidatos-presidenciales-competiran-22-octubre_0_4NNTdpawDU.html" target="_blank">
               Elecciones 2023: todos los candidatos a Presidente</h2></a>`
            },
            {
                "type": "text",
                "value": `<p>De acuerdo con los resultados de las PASO celebradas el 13 de agosto, cinco fórmulas
               compiten por la Presidencia este domingo.</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Javier Milei - Victoria Villarruel (La Libertad Avanza)</li>
                           <li>Patricia Bullrich - Luis Petri (Juntos por el Cambio)</li>
                           <li>Sergio Massa - Agustín Rossi (Unión por la Patria)</li>
                           <li>Juan Schiaretti - Florencio Randazzo (Hacemos por Nuestro País)</li>
                           <li>Myriam Bregman - Nicolás Del Caño (Frente de Izquierda y de los Trabajadores Unidad)</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `<h2><a href=\"https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html\" target=\"_blank\">
               Cuánto cuesta la multa por no votar</a></h2>`
            },
            {
                "type": "text",
                "value": `<p>El monto de la multa -que por la inflación quedó desactualizado y sorprende por lo reducido - dependerá de cuántas 
               infracciones pendientes de pago tenga acumuladas el ciudadano:</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Si no tiene infracciones previas por regularizar, son $50.</li>
                           <li>Si tiene 1 infracción previa sin regularizar, $100.</li>
                           <li>Si tiene 2 infracciones previas sin regularizar, $200.</li>
                           <li>Si tiene 3 infracciones previas sin regularizar, $400.</li>
                           <li>Si tiene 4 o más infracciones previas sin regularizar, $500.</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/-balotaje-_0_73QJHZU5xA.html" target="_blank">
                       Elecciones: cuándo sería el balotaje 2023
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   "Por ley, el hipotético balotaje está previsto para un mes después de las generales. En este
                   caso, la segunda vuelta se desarrollaría el 19 de noviembre.
               </p>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/voto-padron-electoral-elecciones-2023_0_nEsUuYHPmI.html" target="_blank">
                       Dónde voto: consultá el padrón electoral
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   "¿Dónde voto?" y "padrón electoral" son dos de las consultas más frecuentes por estas
                   horas en todo el país. Consultá el padrón haciendo <a href="https://www.clarin.com/politica/voto-padron-electoral-elecciones-2023_0_nEsUuYHPmI.html" target="_blank">clic aquí</a>.
               </p>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html" target="_blank">
                   Elecciones 2023: qué pasa si no voto
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   Votar en Argentina es de carácter obligatorio para todos los ciudadanos mayores de edad
                   hasta los 70 años. Quienes no lo hagan tienen hasta 60 días para presentar una
                   justificación ante la Justicia Electoral y evitar multas económicas y administrativas.
               </p>`
            },
            {
                "type": "text",
                "value": `<p>
                   Para consultar si se adeuda alguna multa hay que dirigirse a la web de la Cámara Nacional
                   Electoral (CNE) e ingresar a la categoría "Registro de Infractores".
               </p>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html" target="_blank">
               Elecciones 2023: de cuánto es la multa por no votar</a></h2>`
            },
            {
                "type": "text",
                "value": `<p>El monto de la multa -que por la inflación quedó desactualizado y sorprende por lo reducido - dependerá de cuántas 
               infracciones pendientes de pago tenga acumuladas el ciudadano:</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Si no tiene infracciones previas por regularizar, son $50.</li>
                           <li>Si tiene 1 infracción previa sin regularizar, $100.</li>
                           <li>Si tiene 2 infracciones previas sin regularizar, $200.</li>
                           <li>Si tiene 3 infracciones previas sin regularizar, $400.</li>
                           <li>Si tiene 4 o más infracciones previas sin regularizar, $500.</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `
              
               <h2>
               <a href="https://www.clarin.com/politica/veda-electoral-puede-hacer-elecciones-2023_0_NTvT77Kj0L.html" target="_blank">
                   Veda electoral: hasta cuándo es y qué no se puede hacer
                </a>
                </h2>
               `
            },
            {
                "type": "text",
                "value": `<p>El artículo 71 del Código Electoral establece que la veda electoral debe comenzar a regir 48
               horas antes de la apertura de los comicios y finalizará tres horas después de terminar la
               jornada electoral. Quienes no cumplan con la veda se ven expuestas a posibles denuncias o
               sanciones, siempre y cuando se compruebe la violación del Código Electoral.</p>`
            },
            {
                "type": "text",
                "value": `<p>Entre otras cosas, se encuentra prohibido realizar actos de campañas por parte de los
               precandidatos presidenciales, como también no permitir la venta de bebidas alcohólicas o
               declaraciones de propaganda política de funcionarios o medios de comunicación. Además,
               también se prohíbe la difusión de encuestas que tengan que ver con las elecciones.</p>`
            }
        ]
    }
};

export const generalJefeGobiernoA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe) => {
    const fechasFormateadas = convertirFecha(data.last_update);

    return {
        "title": `Resultados de las Elecciones 2023 en CABA para jefe de Gobierno`,
        "summary": `
           <ul>
               <li>Expectativa por los resultados de los comicios de este domingo 22 de octubre.</li>
               <li>Toda la información actualizada de los candidatos, minuto a minuto.</li>
           </ul>`,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-10-22T19:05:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `<p>Los ciudadanos de <strong>la Ciudad de Buenos Aires</strong> acuden nuevamente a las urnas este
               domingo, en el marco de las <strong>Elecciones 2023.</strong> Hay gran expectativa por los <strong>resultados</strong>, que
               determinarán al futuro Presidente de la Nación y al nuevo jefe de Gobierno porteño.</p>`
            },
            {
                "type": "text",
                "value": `<p>El Código Nacional Electoral establece que los primeros datos oficiales se difundirán recién
               tres horas después del cierre de los comicios, es decir, cerca de las 21.</p>`
            },
            {
                "type": "text",
                "value": `<p>En paralelo a las elecciones para Presidente y jefe de Gobierno, los porteños también
               deben elegir 30 legisladores de la Ciudad (se renueva la mitad de la Legislatura) y las 15
               Juntas Comunales.</p>`
            },
            {
                "type": "text",
                "value": `<p>En caso de que ningún candidato a jefe de Gobierno supere el 50% de los sufragios, habrá
               un balotaje entre los dos más votados, que se hará dentro de los 30 días siguientes a las
               elecciones generales.</p>`
            },
            {
                "type": "text",
                "value": `<h2>Elecciones 2023 en CABA: todos los candidatos a Jefe de Gobierno porteño</h2>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Jorge Macri (Juntos por el Cambio)</li>
                           <li>Leandro Santoro (Unión por la Patria)</li>
                           <li>Ramiro Marra (La Libertad Avanza)</li>
                           <li>Vanina Biasi (Partido Obrero)</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/elecciones-2023-candidatos-presidenciales-competiran-22-octubre_0_4NNTdpawDU.html" target="_blank">
               Elecciones 2023: todos los candidatos a Presidente</a></h2>`
            },
            {
                "type": "text",
                "value": `<p>De acuerdo con los resultados de las PASO celebradas el 13 de agosto, cinco fórmulas
               compiten por la Presidencia este domingo.</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Javier Milei - Victoria Villarruel (La Libertad Avanza)</li>
                           <li>Patricia Bullrich - Luis Petri (Juntos por el Cambio)</li>
                           <li>Sergio Massa - Agustín Rossi (Unión por la Patria)</li>
                           <li>Juan Schiaretti - Florencio Randazzo (Hacemos por Nuestro País)</li>
                           <li>Myriam Bregman - Nicolás Del Caño (Frente de Izquierda y de los Trabajadores Unidad)</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/-balotaje-_0_73QJHZU5xA.html" target="_blank">
               Elecciones: cuándo sería el balotaje 2023</a></h2>`
            },
            {
                "type": "text",
                "value": `<p>Por ley, el hipotético balotaje está previsto para un mes después de las generales. En este
               caso, la segunda vuelta se desarrollaría el 19 de noviembre</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Si no tiene infracciones previas por regularizar, son $50.</li>
                           <li>Si tiene 1 infracción previa sin regularizar, $100.</li>
                           <li>Si tiene 2 infracciones previas sin regularizar, $200.</li>
                           <li>Si tiene 3 infracciones previas sin regularizar, $400.</li>
                           <li>Si tiene 4 o más infracciones previas sin regularizar, $500.</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/-balotaje-_0_73QJHZU5xA.html" target="_blank">
                       Elecciones: cuándo sería el balotaje 2023
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   "Por ley, el hipotético balotaje está previsto para un mes después de las generales. En este
                   caso, la segunda vuelta se desarrollaría el 19 de noviembre.
               </p>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/voto-padron-electoral-elecciones-2023_0_nEsUuYHPmI.html" target="_blank">
                       Dónde voto: consultá el padrón electoral
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   "¿Dónde voto?" y "padrón electoral" son dos de las consultas más frecuentes por estas
                   horas en todo el país. Consultá el padrón haciendo <a href="https://www.clarin.com/politica/voto-padron-electoral-elecciones-2023_0_nEsUuYHPmI.html" target="_blank">clic aquí</a>.
               </p>`
            },
            {
                "type": "text",
                "value": `
               <h2>
                   <a href="https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html" target="_blank">
                   Elecciones 2023: qué pasa si no voto
                   </a>
               </h2>`
            },
            {
                "type": "text",
                "value": `<p>
                   Votar en Argentina es de carácter obligatorio para todos los ciudadanos mayores de edad
                   hasta los 70 años. Quienes no lo hagan tienen hasta 60 días para presentar una
                   justificación ante la Justicia Electoral y evitar multas económicas y administrativas.
               </p>`
            },
            {
                "type": "text",
                "value": `<p>
                   Para consultar si se adeuda alguna multa hay que dirigirse a la web de la Cámara Nacional
                   Electoral (CNE) e ingresar a la categoría "Registro de Infractores".
               </p>`
            },
            {
                "type": "text",
                "value": `<h2><a href="https://www.clarin.com/politica/multa-votar-dia-puede-pagar_0_Lh7yzMdp4G.html" target="_blank">
               Elecciones 2023: de cuánto es la multa por no votar</a></h2>`
            },
            {
                "type": "text",
                "value": `<p>El monto de la multa -que por la inflación quedó desactualizado y sorprende por lo reducido - dependerá de cuántas 
               infracciones pendientes de pago tenga acumuladas el ciudadano:</p>`
            },
            {
                "type": "text",
                "value": `<ul>
                           <li>Si no tiene infracciones previas por regularizar, son $50.</li>
                           <li>Si tiene 1 infracción previa sin regularizar, $100.</li>
                           <li>Si tiene 2 infracciones previas sin regularizar, $200.</li>
                           <li>Si tiene 3 infracciones previas sin regularizar, $400.</li>
                           <li>Si tiene 4 o más infracciones previas sin regularizar, $500.</li>
                       </ul>`
            },
            {
                "type": "text",
                "value": `
              
               <h2>
               <a href="https://www.clarin.com/politica/veda-electoral-puede-hacer-elecciones-2023_0_NTvT77Kj0L.html" target="_blank">
                   Veda electoral: hasta cuándo es y qué no se puede hacer
                </a>
                </h2>
               `
            },
            {
                "type": "text",
                "value": `<p>El artículo 71 del Código Electoral establece que la veda electoral debe comenzar a regir 48
               horas antes de la apertura de los comicios y finalizará tres horas después de terminar la
               jornada electoral. Quienes no cumplan con la veda se ven expuestas a posibles denuncias o
               sanciones, siempre y cuando se compruebe la violación del Código Electoral.</p>`
            },
            {
                "type": "text",
                "value": `<p>Entre otras cosas, se encuentra prohibido realizar actos de campañas por parte de los
               precandidatos presidenciales, como también no permitir la venta de bebidas alcohólicas o
               declaraciones de propaganda política de funcionarios o medios de comunicación. Además,
               también se prohíbe la difusión de encuestas que tengan que ver con las elecciones.</p>`
            }
        ]
    }
};

export const roundToTwoDecimals = (value) => {
    return parseFloat(value).toFixed(2);
}
export const formatWithCommas = (value) => {
    return value ? value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : value;
}
export const convertirFecha = (timestamp) => {
    const fecha = new Date(timestamp);

    const year = fecha.getUTCFullYear();
    const month = String(fecha.getUTCMonth() + 1).padStart(2, '0');
    const day = String(fecha.getUTCDate()).padStart(2, '0');
    const hours = String(fecha.getUTCHours()).padStart(2, '0');
    const minutes = String(fecha.getUTCMinutes()).padStart(2, '0');
    const seconds = String(fecha.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
}
const propiedadesAFormatear = [
    "ganador",
    "segundo",
    "tercero",
    "cuarto",
    "quinto"
];

function removeAccentsAndSpecialChars(str) {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s]+/g, "-")
        .toLowerCase();
}

export const generateValidUrl = (baseUrl, provincia , distritc, code) => {
    const districtSlug = removeAccentsAndSpecialChars(distritc);
    const provinciaSlug = removeAccentsAndSpecialChars(provincia);
    let urlResp = districtSlug  != "" ? `${baseUrl}${provinciaSlug}-${districtSlug}_8_${code}.html`
    :`${baseUrl}${provinciaSlug}_8_${code}.html`
    return urlResp;
};

export const imagePost = {
    "AR": {
        "code": "AR",
        "url": "https://www.clarin.com/img/2023/10/06/OHw4KnSeM_1256x620__1.jpg"
    },
    "AR-B": {
        "code": "AR-B",
        "url": "https://www.clarin.com/img/2023/10/06/LkE-QQhUR_1256x620__1.jpg"
    },
    "AR-C": {
        "code": "AR-C",
        "url": "https://www.clarin.com/img/2023/10/06/uE6Heryiz_1256x620__1.jpg"
    },
    "AR-K": {
        "code": "AR-K",
        "url": "https://www.clarin.com/img/2023/10/06/FG9DR2O-s_1256x620__1.jpg"
    },
    "AR-H": {
        "code": "AR-H",
        "url": "https://www.clarin.com/img/2023/10/06/T8KyPlGOA_1256x620__1.jpg"
    },
    "AR-U": {
        "code": "AR-U",
        "url": "https://www.clarin.com/img/2023/10/06/j5jeTOqJY_1256x620__1.jpg"
    },
    "AR-X": {
        "code": "AR-X",
        "url": "https://www.clarin.com/img/2023/10/06/u2CRYvfEg_1256x620__1.jpg"
    },
    "AR-W": {
        "code": "AR-W",
        "url": "https://www.clarin.com/img/2023/10/06/cZBFEaYWB_1256x620__1.jpg"
    },
    "AR-E": {
        "code": "AR-E",
        "url": "https://www.clarin.com/img/2023/10/06/ECse7u6Y1_1256x620__1.jpg"
    },
    "AR-P": {
        "code": "AR-P",
        "url": "https://www.clarin.com/img/2023/10/06/7P1R3By2n_1256x620__1.jpg"
    },
    "AR-Y": {
        "code": "AR-Y",
        "url": "https://www.clarin.com/img/2023/10/06/o3_5-gWnE_1256x620__1.jpg"
    },
    "AR-L": {
        "code": "AR-L",
        "url": "https://www.clarin.com/img/2023/10/06/Z_gcz_1Zj_1256x620__1.jpg"
    },
    "AR-F": {
        "code": "AR-F",
        "url": "https://www.clarin.com/img/2023/10/06/hF5vQAcj1_1256x620__1.jpg"
    },
    "AR-M": {
        "code": "AR-M",
        "url": "https://www.clarin.com/img/2023/10/06/g1XTmZou4_1256x620__1.jpg"
    },
    "AR-N": {
        "code": "AR-N",
        "url": "https://www.clarin.com/img/2023/10/06/YUWEO6PW6_1256x620__1.jpg"
    },
    "AR-Q": {
        "code": "AR-Q",
        "url": "https://www.clarin.com/img/2023/10/06/f86TViSS4_1256x620__1.jpg"
    },
    "AR-R": {
        "code": "AR-R",
        "url": "https://www.clarin.com/img/2023/10/06/n8Nh-Iy-o_1256x620__1.jpg"
    },
    "AR-A": {
        "code": "AR-A",
        "url": "https://www.clarin.com/img/2023/10/06/HYCafS1mE_1256x620__1.jpg"
    },
    "AR-J": {
        "code": "AR-J",
        "url": "https://www.clarin.com/img/2023/10/06/maqQ2wqag_1256x620__1.jpg"
    },
    "AR-D": {
        "code": "AR-D",
        "url": "https://www.clarin.com/img/2023/10/06/oHUJ0ysnk_1256x620__1.jpg"
    },
    "AR-Z": {
        "code": "AR-Z",
        "url": "https://www.clarin.com/img/2023/10/06/tkYvhQrqB_1256x620__1.jpg"
    },
    "AR-S": {
        "code": "AR-S",
        "url": "https://www.clarin.com/img/2023/10/06/jcnBI-wpB_1256x620__1.jpg"
    },
    "AR-G": {
        "code": "AR-G",
        "url": "https://www.clarin.com/img/2023/10/06/bM6nrRvxg_1256x620__1.jpg"
    },
    "AR-V": {
        "code": "AR-V",
        "url": "https://www.clarin.com/img/2023/10/06/Mf24XNlRb_1256x620__1.jpg"
    },
    "AR-T": {
        "code": "AR-T",
        "url": "https://www.clarin.com/img/2023/10/06/nBXG-rYB9_1256x620__1.jpg"
    }
}

export const generateValidUrlXml = (name, baseUrl, provincia, code) => {
    const districtSlug = removeAccentsAndSpecialChars(name);
    const provinciaSlug = removeAccentsAndSpecialChars(provincia);
  
    return `${baseUrl}resultados-${provinciaSlug ? `${provinciaSlug}-` : ''}${districtSlug}_8_${code}.html`;
};

export const generateXmlElection = async (data, urlBase, getName, province, publicationDate) => {    
    let xmlString = '';
    for (let index = 0; index < data.length; index++) {
        const element =  data[index]; 
        let lastUpdate;
        let keywords = ''
        if (urlBase.includes('balotaje')) {
            lastUpdate = await getDateUpdateBalotaje(element?.last_update);
            keywords = `<news:keywords>${element.name == "Elecciones" ? 'Argentina' : element.name}, Balotaje, Elecciones 2023, Presidenciales 2023, Presidente, Ganadores, Resultados, Comicios, Fórmula Ganadora, Sergio Massa, Javier Milei, La Libertad Avanza, Unión por la Patria, Victoria Villarruel, Agustín Rossi</news:keywords>\n`;
        } else {
            lastUpdate = (!!element && element?.tables_percent && element.tables_percent > 0) ? getDateFormatZulu(element.last_update) : publicationDate; 
        }
        let url = generateValidUrlXml(element.name, urlBase, province ?? '',element.code)
        xmlString += '  <url>\n';
        xmlString += `    <loc>${url}</loc>\n`; // Cambia la URL base según tu dominio
        xmlString += `    <lastmod>${lastUpdate}</lastmod>\n`;
        xmlString += '    <news:news>\n';
        xmlString += '      <news:publication>\n';
        xmlString += '        <news:name>Clarín.com</news:name>\n';
        xmlString += '        <news:language>es</news:language>\n';
        xmlString += '      </news:publication>\n';
        xmlString += `      <news:publication_date>${publicationDate}</news:publication_date>\n`;
        xmlString += `      <news:title>${getName(element.name)}</news:title>\n`;
        xmlString += `      ${keywords}`;
        xmlString += '    </news:news>\n';
        xmlString += '  </url>\n';        
    }
    return xmlString;
}
export const getDataElections = async (urlApi) => {
    const { data } = await fetchElections(urlApi);
    return data ? data : null;
  };
  
const fetchElections = async (urlApi) => {
try {
    const response = await fetch(urlApi, {agent});
    const data = await response.json();
    return {
    code: 200,
    data: data ?? {},
    error: null,
    };
} catch (error) {
    return false;
}
};

export const presidenteA1Balotaje = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe, infoPresidentOrGovernors, codeLetter, codeNumber, fechasFormateadas, menu) => {
    const firstPlace = infoPresidentOrGovernors.data.parties[0]
    const winnerCandidate = firstPlace.candidates[0].fullname
    const winnerParty = firstPlace.name
    const winnerPercentage = firstPlace.votes_percent
    const secondPlace = infoPresidentOrGovernors.data.parties[1]
    const secondPlaceCandidate = secondPlace.candidates[0].fullname
    const secondPercentage = secondPlace.votes_percent
    const formatedProvinciaLocalidad = formatProvinciaLocalidad(data.provinciaLocalidad)

    propiedadesAFormatear.forEach(propiedad => {
        if (data[propiedad]) {
            data[propiedad].votes = formatWithCommas(data[propiedad].votes);
            data[propiedad].votes_percent = roundToTwoDecimals(data[propiedad].votes_percent);
        }
    });

    votosEnBlanco[0].votes_percent = roundToTwoDecimals(votosEnBlanco[0].votes_percent);
    votosNulos[0].votes_percent = roundToTwoDecimals(votosNulos[0].votes_percent);
    data.mesasEscrutadas = roundToTwoDecimals(data.mesasEscrutadas);

    const extras = menu?.elecciones?.item?.items?.[0]?.extras;
    const newsSeo = (extras?.liveblog_bajadaSEO && extras?.leyenda_bajadaSEO) ? [{value: {url: extras?.liveblog_bajadaSEO}, title: extras?.leyenda_bajadaSEO}] : []
    return {
        "title": `Resultados balotaje 2023 en ${formatedProvinciaLocalidad}: quién ganó para Presidente`,
        "summary": `
            <ul>
                <li>Seguí los resultados de las Elecciones 2023 en ${formatedProvinciaLocalidad}, para saber quién es el Presidente de la Nación electo en este distrito.</li>
                <li>El escrutinio minuto a minuto, el mapa y todo lo que tenés que saber.</li>
            </ul>`,
        "newsSeo": newsSeo,
        "subtitle": "",
        "description": `Los ciudadanos de ${formatedProvinciaLocalidad} acudieron nuevamente a las urnas este domingo con motivo del balotaje de las Elecciones presidenciales 2023 en Argentina. Según los resultados oficiales del escrutinio provisorio, ${data.ganador.candidates[0].fullname}, de ${data.ganador.name}, será el próximo presidente.`,
        "publishedDate": "2023-11-19T13:00:00.000Z",
        "formattedPublishedDate": "11 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `
                <p>
                    Los resultados de las <strong>Elecciones 2023</strong> en <strong>${formatedProvinciaLocalidad}</strong> ubican en primer lugar a <strong>${data.ganador.candidates[0].fullname}</strong>, de <strong>${data.ganador.name}</strong>, quien obtiene el <strong>${data.ganador.votes_percent}</strong> por ciento de los votos. En segundo lugar del balotaje se encuentra <strong>${data.segundo.candidates[0].fullname}</strong>, de <strong>${data.segundo.name}</strong>, que alcanza el <strong>${data.segundo.votes_percent}</strong> por ciento de los sufragios en el escrutinio provisorio, con el <strong>${data.mesasEscrutadas}</strong> por ciento de las mesas escrutadas.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    A nivel nacional, <strong>${winnerCandidate}</strong> logra el <strong>${winnerPercentage}</strong> por ciento de los votos y supera a <strong>${secondPlaceCandidate}</strong>, quien cosecha el <strong>${secondPercentage}</strong> por ciento de los sufragios. 
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    El nuevo <strong>Presidente</strong> asumirá su cargo el 10 de diciembre, en reemplazo de Alberto Fernández. Coincidentemente, ese día se cumplirán 40 años de democracia ininterrumpidos en la Argentina, luego de que Raúl Alfonsín iniciara su mandato en 1983. 
                </p>`
            },
            {
                "type": "text",
                "value": `<h2>Resultados Elecciones 2023 en ${formatedProvinciaLocalidad}, para Presidente </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Con el <strong>${data.mesasEscrutadas}</strong> por ciento de las mesas escrutadas, los resultados del balotaje 2023 para Presidente en ${formatedProvinciaLocalidad}, son:
                </p>`
            },
            {
                "type": "text",
                "value": `
                <ul>
                    <li>1. ${data.ganador.candidates[0].fullname}, de ${data.ganador.name}, con ${data.ganador.votes} votos y el ${data.ganador.votes_percent} por ciento de los sufragios.</li>
                    <li>2. ${data.segundo.candidates[0].fullname}, de ${data.segundo.name}, con ${data.segundo.votes} votos y el ${data.segundo.votes_percent} por ciento de los sufragios.</li>
                </ul>`
            },
            {
                "_id": "12345678",
                "type": "freehtml",
                "value": {
                    "title": null,
                    "summary": null,
                    "name": "INFO_PAIS_Intención de voto por fórmulas para la presidencial",
                    "html":`
                    <iframe class="elections-iframe" src="${urlIframe}" title="Intención de voto por fórmulas para la presidencial" scrolling="no" frameborder="0" data-external="1"></iframe>
                        <script type="text/javascript" src="https://ecp.clarin.com/demo/js/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({checkOrigin:false,heightCalculationMethod:'documentElementOffset',});</script>
                `,
                "htmlAmp": null
                }
            },
            {
                "type": "text",
                "value": `
                <p>
                    * Este artículo fue generado de forma automática sobre la base de los datos oficiales provistos por la Dirección Nacional Electoral (DINE).
                </p>`
            }
        ]
    }
};

export const balotajePresidenteA1 = (data, votosEnBlanco, votosImpugnados, votosNulos, urlIframe, infoPresidentOrGovernors, codeLetter, codeNumber, fechasFormateadas, menu) => {
    const date = new Date();
    const argTime = new Intl.DateTimeFormat('en-US', { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: false, 
      timeZone: 'America/Argentina/Buenos_Aires' 
    }).format(date);
  
    const hour = parseInt(argTime.split(':')[0]);
    const extras = menu?.elecciones?.item?.items?.[0]?.extras;
    
    // hardcodeo que se debe borrar
    /* const hardcodeo = getHardcodeo(codeLetter, codeNumber, data, fechasFormateadas, extras);
    if(hardcodeo?.enable){
        return hardcodeo.data;
    } */
    // fin hardcodeo que se debe borrar
    if (hour < 14) {
        return balotajePresidenteA1V1(data, fechasFormateadas, extras);
    } else if (hour >= 14 && hour < 18) {
        return balotajePresidenteA1V2(data, fechasFormateadas, extras);
    } else {
        return balotajePresidenteA1V3(data, fechasFormateadas, extras);
    }
};

const getHardcodeo = (codeLetter, codeNumber, data, fechasFormateadas, extras) => {
    if(`${codeLetter}${codeNumber}` == 'AR-B') {
        return {
           enable: true, 
           data: balotajePresidenteA1V1(data, fechasFormateadas, extras)
        }
    }
    if(`${codeLetter}${codeNumber}` == 'AR-B001') {
        return {
           enable: true, 
           data: balotajePresidenteA1V2(data, fechasFormateadas, extras)
        }
    }
    if(`${codeLetter}${codeNumber}` == 'AR-B002') {
        return {
           enable: true, 
           data: balotajePresidenteA1V3(data, fechasFormateadas, extras)
        }
    }
    return {
       enable: false
    }
}

const balotajePresidenteA1V1 = (data, fechasFormateadas, extras) => {
    const newsSeo = (extras?.liveblog_bajadaSEO && extras?.leyenda_bajadaSEO) ? [{value: {url: extras?.liveblog_bajadaSEO}, title: extras?.leyenda_bajadaSEO}] : []
    const freeText = extras?.parrafo ? {type: "text", value: `<p>${extras?.parrafo}</p>`} : {type: "text", value: ``};
    return {
        "title": `Resultados de las Elecciones 2023 para Presidente en ${formatProvinciaLocalidad(data.provinciaLocalidad)}: se define el balotaje entre Sergio Massa y Javier Milei`,
        "summary": `
            <ul>
                <li>Los argentinos eligen en segunda vuelta al nuevo Presidente.</li>
                <li>El escrutinio minuto a minuto, el mapa y todo lo que tenés que saber.</li>                
            </ul>
        `,
        "newsSeo": newsSeo,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-11-19T13:00:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `
                <p>
                    Los ciudadanos de <strong> ${formatProvinciaLocalidad(data.provinciaLocalidad)} </strong> eligen hoy al nuevo Presidente argentino y hay gran expectativa por los <strong>resultados</strong> de las <strong>Elecciones 2023</strong> entre <strong>Sergio Massa</strong> y <strong>Javier Milei</strong>. Horas después de las 18 se conocerá <strong>quién ganó el balotaje</strong> y qué fórmula gobernará los próximos cuatro años en la Argentina.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    El desempate electoral consagrará a la fórmula que logre la mayor cantidad de votos afirmativos válidamente emitidos. En la segunda vuelta de este domingo sólo se eligen los máximos cargos ejecutivos, ya que senadores y diputados fueron elegidos en la primera ronda.
                </p>`
            },
            freeText,
            {
                "type": "text",
                "value": `
                <p>
                    Seguí toda la información del escrutinio de las Elecciones y el mapa nacional, minuto a minuto.
                </p>`
            },
            {
                "type": "text",
                "value": `<h2>Resultados Elecciones 2023: quién ganó en las generales del 22 de octubre</h2>`
            },
            {
                "type": "text",
                "value": `<ul>
                        <li>Sergio Massa (36,78% de los votos)</li>
                        <li>Javier Milei (29,99%)</li>
                        <li>Patricia Bullrich (23,81%)</li>
                        <li>Juan Schiaretti (6,73%)</li>
                        <li>Myriam Bregman (2,70%)</li>
                    </ul>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Los datos corresponden al <a href="https://www.clarin.com/politica/termino-escrutinio-definitivo-quedo-diferencia-massa-milei_0_6YLPlwjnmh.html">escrutinio definitivo</a> publicado por la Justicia Electoral sobre las elecciones del pasado domingo 22 de octubre. El balotaje se confirmó una vez que ningún candidato obtuvo el 45% de los sufragios o más del 40% con diez puntos de diferencia sobre el segundo.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/voto-elecciones-2023-consulta-padron-electoral-balotaje_0_WqLxvGDp38.html" target="_blank">Dónde voto en ${formatProvinciaLocalidad(data.provinciaLocalidad)}: consultá el padrón electoral para el balotaje 2023 </a>.
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/pasa-voto-22-octubre_0_WoZDqEIlAu.html" target="_blank">
                    Elecciones 2023: qué pasa si no voto
                    </a>
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    En el <strong>padrón electoral</strong> figura <strong>el lugar</strong> donde se debe sufragar, el <strong>número de mesa y el número de orden</strong>, con el objetivo de que los votantes les brinden esa información a las autoridades de mesa y que la votación sea más ágil y práctica. Para chequear los datos del padrón, hay que ingresar en el link dispuesto arriba y así conocer dónde votar.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/boca-urna-elecciones-2023-funciona_0_VlXXeWJLZ6.html" target="_blank">
                    ¿Qué es el boca de urna y a qué hora se conocerán los primeros resultados oficiales?
                    </a>
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    <strong>Boca de urna</strong> es uno de los términos más buscados tanto en redes sociales como en Google a la hora de anticiparse a los <strong>resultados oficiales</strong> de cada elección. Se trata de encuestas que se realizan a la salida de los establecimientos electorales luego de que los votantes emitieron su sufragio. Los medios de comunicación no pueden publicar estas cifras porque <strong>la ley nacional Nº 25.610 prohíbe cualquier tipo de difusión masiva de datos</strong> hasta tres horas posteriores a un acto electoral.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Los primeros resultados oficiales podrían comenzar a difundirse a partir de 21, según confirmó la Dirección Nacional Electoral (DINE).
                </p>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/cuenta-voto-blanco-balotaje-argentina-2023_0_OryqR95Oo4.html" target="_blank">
                    Cómo se cuenta el voto en blanco en este balotaje
                    </a>
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Una de las dudas más frecuentes es cómo funciona el <strong>voto en blanco</strong>. El voto en blanco es considerado válido, pero no afirmativo para ninguno de los candidatos. Se considera voto en blanco a todo sobre que esté vacío o que contenga un papel de cualquier color, sin inscripciones ni imágenes.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Aunque el <strong>voto en blanco</strong> y el voto nulo tienen diferencias, en el <strong>balotaje de 2023</strong> no se contabilizan, ya que la <strong>Constitución Nacional</strong> establece que la victoria presidencial se define por "los votos afirmativos válidamente emitidos". La clave: el voto en blanco y el voto nulo no benefician a ningún candidato en particular ni afectan el resultado aunque ayudan de manera indirecta a mantener la ventaja de quien lidere la primera posición.
                </p>`
            },
        ]
    }
}

const balotajePresidenteA1V2 = (data, fechasFormateadas, extras) => {
    const newsSeo = (extras?.liveblog_bajadaSEO && extras?.leyenda_bajadaSEO) ? [{value: {url: extras?.liveblog_bajadaSEO}, title: extras?.leyenda_bajadaSEO}] : []
    const freeText = extras?.parrafo ? {type: "text", value: `<p>${extras?.parrafo}</p>`} : null;
    return {
        "title": `Resultados de las Elecciones 2023 para Presidente en ${formatProvinciaLocalidad(data.provinciaLocalidad)}: a qué hora se conocerán los primeros datos oficiales`,
        "summary": `
        <ul>
            <li>La segunda vuelta se define entre Sergio Massa y Javier Milei.</li>
            <li>El escrutinio minuto a minuto, el mapa y todo lo que tenés que saber.</li>
        </ul>
        `,
        "newsSeo": newsSeo,
        "subtitle": "",
        "description": "",
        "publishedDate": "2023-11-19T13:00:00.000Z",
        "formattedPublishedDate": "18 SEP 2023",
        "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
        "gtmPublishedDate": "2023-09-18T",
        "modificatedAt": `${fechasFormateadas}`,
        "body": [
            {
                "type": "text",
                "value": `
                <p>
                    Los ciudadanos de <strong> ${formatProvinciaLocalidad(data.provinciaLocalidad)} </strong> eligen hoy al nuevo Presidente argentino y hay gran expectativa por los <strong>resultados</strong> de las <strong>Elecciones 2023</strong> entre <strong>Sergio Massa</strong> y <strong>Javier Milei</strong>. A partir de las 21 podrán comenzar a difundirse los primeros resultados oficiales y se conocerá <strong>quién ganó el balotaje</strong> y qué fórmula gobernará los próximos cuatro años en la Argentina. Seguí toda la información del escrutinio de las <strong>Elecciones</strong> y el mapa nacional, minuto a minuto.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    El desempate electoral consagrará a la fórmula que logre la mayor cantidad de votos afirmativos válidamente emitidos. En la segunda vuelta de este domingo sólo se eligen los máximos cargos ejecutivos, ya que senadores y diputados fueron elegidos en la primera ronda.
                </p>`
            },
            freeText,
            {
                "type": "text",
                "value": `<h2>Resultados Elecciones 2023: quién ganó en las generales del 22 de octubre</h2>`
            },
            {
                "type": "text",
                "value": `
                <ul>
                    <li>Sergio Massa (36,78% de los votos)</li>
                    <li>Javier Milei (29,99%)</li>
                    <li>Patricia Bullrich (23,81%)</li>
                    <li>Juan Schiaretti (6,73%)</li>
                    <li>Myriam Bregman (2,70%)</li>
                </ul>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Los datos corresponden al <a href="https://www.clarin.com/politica/termino-escrutinio-definitivo-quedo-diferencia-massa-milei_0_6YLPlwjnmh.html">escrutinio definitivo</a> publicado por la Justicia Electoral sobre las elecciones del pasado domingo 22 de octubre. El balotaje se confirmó una vez que ningún candidato obtuvo el 45% de los sufragios o más del 40% con diez puntos de diferencia sobre el segundo.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/cuenta-voto-blanco-balotaje-argentina-2023_0_OryqR95Oo4.html" target="_blank">Resultados Elecciones 2023: cómo se cuenta el voto en blanco en este balotaje </a>.
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Una de las dudas más frecuentes es cómo funciona el <strong>voto en blanco</strong>. El blanco es considerado válido, pero no afirmativo para ninguno de los candidatos. Se considera voto en blanco a todo sobre que esté vacío o que contenga un papel de cualquier color, sin inscripciones ni imágenes.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Aunque el <strong>voto en blanco</strong> y el voto nulo tienen diferencias, en el balotaje de 2023 no se contabilizan, ya que la Constitución Nacional establece que la victoria presidencial se define por "los votos afirmativos válidamente emitidos". <strong>La clave</strong>: el voto en blanco y el voto nulo no benefician a ningún candidato en particular ni afectan el resultado aunque <strong>ayudan de manera indirecta</strong> a mantener la ventaja de quien lidera la primera posición.
                </p>`
            },
            {
                "type": "text",
                "value": `<h2>Qué es el boca de urna y cómo debe usarse el día de la elección</h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    <strong>Boca de urna</strong> es uno de los términos más buscados tanto en redes sociales como en Google a la hora de anticiparse a los <strong>resultados oficiales</strong> de cada elección. Se trata de encuestas que se realizan a la salida de los establecimientos electorales luego de que los votantes emitieron su sufragio. Los medios de comunicación no pueden publicar estas cifras porque <strong>la ley nacional Nº 25.610 prohíbe cualquier tipo de difusión masiva de datos</strong> hasta tres horas posteriores a un acto electoral.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/voto-elecciones-2023-consulta-padron-electoral-balotaje_0_WqLxvGDp38.html" target="_blank">Dónde voto en ${formatProvinciaLocalidad(data.provinciaLocalidad)}: consultá el padrón electoral para el balotaje 2023 </a>.
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    En el <strong>padrón electoral</strong> figura <strong>el lugar</strong> donde se debe sufragar, el <strong>número de mesa y el número de orden</strong>, con el objetivo de que los votantes les brinden esa información a las autoridades de mesa y que la votación sea más ágil y práctica. Para chequear los datos del padrón, hay que ingresar en el link dispuesto arriba y así poder chequear la información.
                </p>`
            },
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/multa-votar-balotaje-2023-dia-puede-pagar_0_sapHtp3dyx.html" target="_blank">Multa por no votar en el balotaje: dónde y hasta qué día se puede pagar</a>
                </h2>`
            },
            {
                "type": "text",
                "value": `
                <p>
                    Quienes no voten <strong>tienen hasta 60 días</strong> para presentar una justificación ante la Justicia Electoral y evitar <strong>multas</strong> económicas y administrativas. De lo contrario, se les aplicará una <strong>sanción</strong> que a su vez constará automáticamente en el Registro de Infractores. Para consultar si se adeuda alguna multa de anteriores elecciones hay que dirigirse a la web de la Cámara Nacional Electoral (CNE) e ingresar a la categoría "Registro de Infractores".
                </p>`
            }, 
            {
                "type": "text",
                "value": `
                <h2>
                    <a href="https://www.clarin.com/politica/asume-nuevo-presidente-argentina-elecciones-2023_0_8mNcOwMDyd.html" target="_blank">Elecciones 2023: ¿cuándo asume el nuevo Presidente?</a>
                </h2>`
            },               
            {
                "type": "text",
                "value": `
                <p>
                    El nuevo <strong>Presidente</strong> asumirá su cargo el 10 de diciembre, en reemplazo de Alberto Fernández. Coincidentemente, ese día se cumplirán 40 años de democracia ininterrumpidos en la Argentina, luego de que Raúl Alfonsín iniciara su mandato en 1983.
                </p>`
            },
        ]
    }
}

const balotajePresidenteA1V3 = (data, fechasFormateadas, extras) => {
    const newsSeo = (extras?.liveblog_bajadaSEO && extras?.leyenda_bajadaSEO) ? [{value: {url: extras?.liveblog_bajadaSEO}, title: extras?.leyenda_bajadaSEO}] : []
    const freeText = extras?.parrafo ? {type: "text", value: `<p>${extras?.parrafo}</p>`} : null;
    return {
            "title": `Resultados de las Elecciones 2023 para Presidente en ${formatProvinciaLocalidad(data.provinciaLocalidad)}: quién gana el balotaje para Presidente`,
            "summary": `
            <ul>
                <li>Entre Sergio Massa y Javier Milei se define quién será el nuevo Presidente de la Nación.</li>
                <li>El escrutinio minuto a minuto, el mapa y todo lo que tenés que saber.</li>
            </ul>
            `,
            "newsSeo": newsSeo,
            "subtitle": "",
            "description": "",
            "publishedDate": "2023-11-19T13:00:00.000Z",
            "formattedPublishedDate": "18 SEP 2023",
            "lastModified": "Mon, 18 Sep 2023 23:01:20 GMT",
            "gtmPublishedDate": "2023-09-18T",
            "modificatedAt": `${fechasFormateadas}`,
            "body": [
                {
                    "type": "text",
                    "value": `
                    <p>
                        Los ciudadanos de <strong> ${formatProvinciaLocalidad(data.provinciaLocalidad)} </strong> esperan por los <strong>resultados</strong> de las <strong>Elecciones 2023</strong> entre <strong>Sergio Massa</strong> y <strong>Javier Milei</strong>, que definirán al nuevo <strong>Presidente de la Nación</strong>. Cada vez falta menos para conocer <strong>quién ganó el balotaje</strong> y qué fórmula gobernará los próximos cuatro años en la Argentina. Seguí toda la información del escrutinio de las <strong>Elecciones</strong> y el mapa nacional, minuto a minuto.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        El <strong>futuro Presidente</strong> asumirá su cargo el 10 de diciembre, en reemplazo de Alberto Fernández. Coincidentemente, ese día se cumplirán 40 años de democracia ininterrumpidos en la Argentina, luego de que Raúl Alfonsín iniciara su mandato en 1983.
                    </p>`
                },
                freeText,
                {
                    "type": "text",
                    "value": `
                    <h2>
                        <a href="https://www.clarin.com/politica/cuenta-voto-blanco-balotaje-argentina-2023_0_OryqR95Oo4.html" target="_blank">Cómo se cuenta el voto en blanco en este balotaje</a>
                    </h2>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        Una de las dudas más frecuentes es cómo funciona el voto en blanco. El blanco es considerado válido, pero no afirmativo para ninguno de los candidatos. Se considera voto en blanco a todo sobre que esté vacío o que contenga un papel de cualquier color, sin inscripciones ni imágenes.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        Aunque el <strong>voto en blanco</strong> y el voto nulo tienen diferencias, en el balotaje de 2023 no se contabilizan, ya que la Constitución Nacional establece que la victoria presidencial se define por "los votos afirmativos válidamente emitidos". <strong>La clave</strong>: el voto en blanco y el voto nulo no benefician a ningún candidato en particular ni afectan el resultado aunque <strong>ayudan de manera indirecta</strong> a mantener la ventaja de quien lidera la primera posición.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `<h2>Qué es el boca de urna y cómo debe usarse el día de la elección</h2>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        <strong>Boca de urna</strong> es uno de los términos más buscados tanto en redes sociales como en Google a la hora de anticiparse a los <strong>resultados oficiales</strong> de cada elección. Se trata de encuestas que se realizan a la salida de los establecimientos electorales luego de que los votantes emitieron su sufragio.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        Los medios de comunicación no pueden publicar estas cifras porque <strong>la ley nacional Nº 25.610 prohíbe cualquier tipo de difusión masiva de datos</strong> hasta tres horas posteriores a un acto electoral.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        Los datos corresponden al <a href="https://www.clarin.com/politica/termino-escrutinio-definitivo-quedo-diferencia-massa-milei_0_6YLPlwjnmh.html">escrutinio definitivo</a> publicado por la Justicia Electoral sobre las elecciones del pasado domingo 22 de octubre. El balotaje se confirmó una vez que ningún candidato obtuvo el 45% de los sufragios o más del 40% con diez puntos de diferencia sobre el segundo.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `<h2>Qué son las mesas testigo</h2>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        Las mesas testigo son elegidas por los partidos políticos para tener una proyección propia sobre los resultados de las elecciones. Los cómputos son suministrados por los fiscales de mesa y dan una idea aproximada de cómo avanza el escrutinio a nivel general.
                    </p>`
                },
                {
                    "type": "text",
                    "value": `<h2>Resultados Elecciones 2023: quién ganó en las generales del 22 de octubre</h2>`
                },
                {
                    "type": "text",
                    "value": `
                    <ul>
                        <li>Sergio Massa (36,78% de los votos)</li>
                        <li>Javier Milei (29,99%)</li>
                        <li>Patricia Bullrich (23,81%)</li>
                        <li>Juan Schiaretti (6,73%)</li>
                        <li>Myriam Bregman (2,70%)</li>
                    </ul>`
                },
                {
                    "type": "text",
                    "value": `
                    <p>
                        Los datos corresponden al escrutinio definitivo publicado por la Justicia Electoral sobre las elecciones del pasado domingo 22 de octubre.
                    </p>`
                },
            ]
        }
}

export const getDateUpdateBalotaje = async (lastUpdate) => {
    const dateElections = "2023-11-19";
    async function getMenu() {
        return await fetch(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/menus/elecciones`)
        .then(async (response) => {
            const { item } = await response.json();
            return {
            code: 200,
            data: item,
            error: null,
            };
        })
        .catch((err) => ({
            code: 500,
            data: null,
            error: "Internal server error",
        }));
    }
    const currentDay = new Date();
    const targetDate = new Date(`${dateElections}T13:00:00.000Z`);
    const afternoonDate = new Date(`${dateElections}T17:00:00.000Z`);
    const eveningDate = new Date(`${dateElections}T21:00:00.000Z`);
    const presidentDate = new Date(lastUpdate);
  
    const response = await getMenu();
    const customDate = response?.data?.modificatedAt 
    ? new Date(response.data.modificatedAt) 
    : new Date(0); 
    console.log("customDate",response)
  
    let returnDate = targetDate;
  
    const hour = currentDay.getUTCHours();
  
    if (hour < 17) {
      returnDate = targetDate;
    } else if (hour >= 17 && hour < 21) {
      returnDate = afternoonDate;
    } else if (hour >= 21) {
      returnDate = eveningDate;
    }
    if (presidentDate.getTime() > returnDate.getTime()) {
      returnDate = presidentDate;
    }
    if (customDate.getTime() > returnDate.getTime()) {
      returnDate = customDate;
    }
  
    return returnDate.toISOString();
}