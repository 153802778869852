import { Service } from "models/Service";

export class Newsletter extends Service {
  constructor() {
    super({
      adsPath: "newsletters",
      meta: {
        description: `Newsletters - Clarin.com`,
        keywords: '',
        title: `Newsletters - Clarin.com`,
        url: `newsletters`, 
        extra: [],
      },
      section: "newsletters",
      template: "Newsletter",
      title: `Newsletters`,
      slug: "Newsletters",
      widthFull: true,
      slug:"newsletters",
      titleHeader:"Newsletters"
    });    
  }


  
  parseData = async (query, menu) => {
    if(query[0] === "newsletters" && menu.newsletters?.item?.items?.length) {
      this.setData({newsletters: menu.newsletters?.item?.items ?? [] });
    }
    if(query[0] === "newsletter" && query[1]) {
      this.setTitleHeaderLink("/newsletters");
      this.setTitleHeaderTag("p");
      this.setWidthFull(false);
      let dataNewsletter = {};
      if(menu.newsletters?.item?.items?.length) {
        let index = menu.newsletters.item.items.findIndex((element) => {
          let itemSlug = element.slug[element.slug.length - 1] === '/' ? element.slug.slice(0, -1) : element.slug;
          return itemSlug.toLowerCase() === `/newsletter/${query[1].toLowerCase()}`
        })
        if(index != "-1") {
          dataNewsletter = menu.newsletters.item.items[index];
          this.setBreadcrumb([
            {
              title: "Newsletters",
              url: "/newsletters",
            },
            {
              title: dataNewsletter.name,
              url: "",
            },
          ])
        }
      }
      this.setData({slug: query[1], data: dataNewsletter})
      this.setTemplate("NewsletterDescription");
    }
  }
}