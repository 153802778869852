import { Service } from "models/Service";

export class FrequentlyAskedQuestions extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Preguntas frecuentes de Clarin.com`,
        keywords: '',
        title: `Preguntas frecuentes`,
        url: "preguntas-frecuentes",
        extra: [],
      },
      section: "preguntas-frecuentes",
      sectionName: "Preguntas frecuentes",
      template: "FrequentlyAskedQuestions",
      title: `Preguntas frecuentes`,
      widthFull: false,
      slug: "preguntas-frecuentes",
      titleHeader:"Preguntas frecuentes"
    });    
  }
}