import { useEffect, useState } from "react";

/**
 *  Busca una tapa en los ultimos 14 dias.
 */
const useLastCover = () => {

    const [coverUrl, setCoverUrl] = useState("");

    const getCover = async () => {

        const domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN;
        const limit = 14;
        let date = new Date();

        if ((date.getTimezoneOffset() / -60) !== -3) {
            date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' }));
        }
        if (date.getHours() <= 4) {
            date.setDate(date.getDate() - 1);
        }

        let year = "";
        let month = "";
        let day = "";
        let param = "";
        let dataUrl = "";

        for (let index = 0; index < limit; index++) {

            year = date.getFullYear();
            month = ((date.getMonth() + 1).toString().length < 2) ? `0${(date.getMonth() + 1).toString()}` : `${(date.getMonth() + 1).toString()}`;
            day = (date.getDate().toString().length < 2) ? `0${date.getDate().toString()}` : `${date.getDate().toString()}`;
            param = `${year}${month}${day}`;

            try {

                const response = await fetch(`/api/contentsCover/${param}`);
                const { data, code } = await response.json();

                if (code === 200 && data.length > 0) {

                    data.forEach(element => {
                        if (element._id == "tapa2021") {
                        //if (element._id == "tapa3") {
                            dataUrl = element.url;
                            //dataUrl = `${domain}${element.url}`;
                        }
                    });

                    if (dataUrl !== "") {
                        setCoverUrl(dataUrl);
                        break;
                    }

                }
            } catch (error) {
                console.error(`Error fetch useLastCover`);
            }
            date.setDate(date.getDate() - 1);
        }

    }

    useEffect(() => {
        getCover()
    }, []);

    return {
        coverUrl,
    }
}

export default useLastCover;