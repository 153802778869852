export const modules = [
  {
    turnos: ["previa", "primera", "matutina", "vespertina", "nocturna"],
    nombre: "Quiniela Nacional",
    descripcion:
      "Conocé los números ganadores del sorteo de hoy de la Quiniela Nacional, así como las letras y los restantes 19 números que completan los 20 primeros puestos. Los sorteos se realizan cuatro veces al día: la Primera (a las 12 horas), la Matutina (a las 14.30 horas), la Vespertina (a las 17.30 horas) y la Nocturna (a las 21 horas).\n\nEl sorteo de los números se realiza en las salas de la Lotería de la Ciudad de Buenos Aires y se utilizan cuatro bolilleros que van a conformar los distintos números, el primero corresponde al millar, el segundo a la centena, el tercero a la decena y el cuarto a la unidad, y cada uno de esos bolilleros tienen diez bolillas (esas bolillas tienen números desde el 0 al 9) y un quinto bolillero que va a conformar las distintas ubicaciones en el tablero (este tiene veinte bolillas que van desde el 1 al 20, que corresponden a las veinte posiciones en el tablero).\n",
    endpoint: "quiniela_nacional",
    ancla: "nacional",
    clase: "nacional",
    active: true,
    type: "quiniela",
  },
  {
    turnos: ["previa", "primera", "matutina", "vespertina", "nocturna"],
    nombre: "Quiniela Provincia",
    descripcion:
      "Seguí en directo los sorteos de de hoy de la Quiniela de la Provincia de Buenos Aires. Conocé los números ganadores de La Primera, la Matutina, la Vespertina y la Nocturna, cuál salió a la cabeza, cuáles completan los 20 primeros y el significado de los sueños de cada uno de ellos. \n\nEste juego tradicional es administrado por Lotería de la Provincia Y durante el sorteo se extraen 20 números del 0000 al 9999. La apuesta mínima en la Quiniela de la Provincia de Buenos Aires es de 2 pesos que, de resultar ganadora, puede multiplicarse en siete, setenta, seiscientos o tres mil quinientas veces, según la variante utilizada.\n \n¿Qué tipos de apuestas hay? Apuesta directa: se efectúa a un número de 1, 2, 3 o 4 dígitos, pudiendo apostar a la cabeza, a los premios o a ambos. O apuesta redoblona, que se efectúa en forma  combinada, a dos números de dos cifras, distintos o iguales. En el último caso, los números deberán figurar como mínimo dos veces en el extracto dentro del lugar de ubicación elegido.\n",
    endpoint: "quiniela_buenos_aires",
    ancla: "provincia",
    clase: "provincia",
    active: true,
    type: "quiniela",
  },
  {
    turnos: [
      "previa",
      "primera de la Mañana",
      "matutina",
      "vespertina",
      "nocturna",
    ],
    nombre: "Quiniela de Córdoba",
    descripcion:
      "Conocé los números ganadores, cuáles salieron hoy a la cabeza y todo lo que necesitás saber sobre la Quiniela de Córdoba en sus cuatro cuatro sorteos: la Primera de la Mañana, la Matutina, la Vespertina y la Nocturna.\n\nOrganizado por la Lotería de Córdoba, el juego de la Quiniela consiste en apostar una cierta cantidad de dinero a números de 1 cifra, 2 cifras, 3 cifras o 4 cifras.\n",
    endpoint: "quiniela_cordoba",
    ancla: "cordoba",
    clase: "cordoba",
    active: true,
    type: "quiniela",
  },
  {
    turnos: ["previa", "primera", "matutina", "vespertina", "nocturna"],
    nombre: "Quiniela Santa Fe",
    descripcion:
      "Conocé los números ganadores del sorteo de hoy de la Quiniela de Santa Fe, cuáles salieron a la cabeza y cuáles en segunda y tercera posición. También qué números completan los primeros diez puestos y el listado de desde el 11° al 20° premio.\n\nLa Lotería de Santa Fe organiza el sorteo de la Quiniela desde 1982, en cuatro modalidades: la Primera, la Matutina, la Vespertina y la Nocturna.\n",
    endpoint: "quiniela_santa_fe",
    ancla: "santafe",
    clase: "santafe",
    active: true,
    type: "quiniela",
  },
  {
    turnos: ["matutina", "vespertina", "La Tardecita", "nocturna"],
    nombre: "Quiniela Santiago del Estero",
    descripcion:
      "Seguí los sorteos de hoy de la Tómbola Santiagueña (también conocida como Quiniela de Santiago del Estero), en sus tres modalidades: la Matutina, la Vespertina y la Nocturna.\n\nCon extraordinarios premios y una cifra muy alta de jugadores, la Tómbola Santiagueña es organizada por la Lotería de Santiago del Estero.\n",
    endpoint: "quiniela_santiago_del_estero",
    ancla: "santiago",
    clase: "santiago",
    active: true,
    type: "quiniela",
  },
  {
    turnos: ["vespertina", "nocturna"],
    nombre: "Quiniela Montevideo",
    descripcion:
      "Conocé el resultado de los sorteos de hoy de la Quiniela de Montevideo, que se realizan de lunes a viernes a las 15 horas (vespertino) y a las 21 horas (nocturno). Ojo, los días sábados sólo se lleva a cabo el sorteo nocturno.\n",
    endpoint: "quiniela_montevideo",
    ancla: "montevideo",
    clase: "montevideo",
    active: true,
    type: "quiniela",
  },
  {
    jugadas: [
      "_tradicional_1",
      "_tradicional_2",
      "_revancha",
      "_segunda_vuelta",
      "_siempre_sale",
      "_premio_extra",
    ],
    nombre: "Quini 6",
    descripcion:
      "Conocé los números ganadores del sorteo de hoy en las modalidades el Tradicional, la Segunda, la Revancha, el Siempre Sale y el Premio Extra.\n",
    endpoint: "quini_6",
    ancla: "quini6",
    clase: "quini6",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_telekino", "_rekino"],
    nombre: "Telekino",
    descripcion:
      "Comprobá cuáles son los 15 números ganadores del pozo máximo del sorteo del Telekino de hoy y los del Rekino, que se reparte entre los ganadores que logran 14 coincidencias en su cartón.\n",
    endpoint: "telekino",
    ancla: "telekino",
    clase: "telekino",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_tradicional", "_sale_o_sale", "_desquite", "_match"],
    nombre: "Loto",
    descripcion:
      "Conocé los números ganadores del sorteo de hoy en sus tres modalidades: el Tradicional, el Desquite y el Sale o Sale.\n",
    endpoint: "loto",
    ancla: "loto",
    clase: "loto",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_loto_5"],
    nombre: "Loto 5",
    descripcion:
      "Conocé los 5 números ganadores del sorteo de hoy del Loto 5.\n",
    endpoint: "loto_5",
    ancla: "loto5",
    clase: "loto5",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_pozo_millonario", "_gana_o_gana"],
    nombre: "Toto Bingo",
    descripcion:
      "Enterate cuáles son los números ganadores del sorteo de hoy del Toto Bingo.\n",
    endpoint: "toto_bingo",
    ancla: "totobingo",
    clase: "totobingo",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_poceada"],
    nombre: "Quiniela Poceada",
    descripcion:
      "Seguí minuto a minuto el sorteo y conocé los números ganadores de la Poceada de hoy.\n",
    endpoint: "quiniela_poceada",
    ancla: "poceada",
    clase: "poceada",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_brinco", "_brinco_junior"],
    nombre: "Brinco",
    descripcion:
      "Conocé cuáles son los núneros del sorteo de hoy del Brinco.\n",
    endpoint: "brinco",
    ancla: "brinco",
    clase: "brinco",
    active: true,
    type: "loteria",
  },
  {
    jugadas: ["_quiniela_plus", "_chance_plus", "_super_plus"],
    nombre: "Quiniela Plus",
    descripcion: "Conocé el resultado del sorteo de hoy del Plus.\n",
    endpoint: "quiniela_plus",
    ancla: "plus",
    clase: "plus",
    active: true,
    type: "loteria",
  },
  {
    jugadas: [
      "_mono_bingo",
      //"_borra_mono",
      "_codigo_de_la_suerte",
      "_solo_4_fechas",
    ],
    nombre: "Mono Bingo",
    descripcion:
      "Enterate antes que nadie del resultado del sorteo de hoy del Mono.\n",
    endpoint: "mono_bingo",
    ancla: "monobingo",
    clase: "mono",
    active: true,
    type: "loteria",
  },
];
export const suenios = {
  "00": "Huevos",
  "01": "Agua",
  "02": "Niño",
  "03": "San Cono",
  "04": "La cama",
  "05": "Gato",
  "06": "Perro",
  "07": "Revolver",
  "08": "Incendio",
  "09": "Arroyo",
  10: "La leche",
  11: "Palito",
  12: "Soldado",
  13: "La yeta",
  14: "Borracho",
  15: "Niña bonita",
  16: "Anillo",
  17: "Desgracia",
  18: "La sangre",
  19: "Pescado",
  20: "La fiesta",
  21: "La mujer",
  22: "El loco",
  23: "Mariposa",
  24: "Caballo",
  25: "Gallina",
  26: "La misa",
  27: "El peine",
  28: "El cerro",
  29: "San Pedro",
  30: "Santa Rosa",
  31: "La luz",
  32: "Dinero",
  33: "Cristo",
  34: "Cabeza",
  35: "Pajarito",
  36: "Manteca",
  37: "Dentista",
  38: "Aceite",
  39: "La lluvia",
  40: "Cura",
  41: "Cucho",
  42: "Zapatilla",
  43: "Balcón",
  44: "La cárcel",
  45: "El vino",
  46: "Tomates",
  47: "Muerto",
  48: "Muerto habla",
  49: "La carne",
  50: "El pan",
  51: "Serrucho",
  52: "Madre",
  53: "El barco",
  54: "La vaca",
  55: "Los gallegos",
  56: "La caída",
  57: "Trabajo",
  58: "Ahogado",
  59: "Planta",
  60: "Virgen",
  61: "Escopeta",
  62: "Inundación",
  63: "Casamiento",
  64: "Llanto",
  65: "Cazador",
  66: "Lombrices",
  67: "Vibora",
  68: "Sobrinos",
  69: "Vicios",
  70: "Muerto sueño",
  71: "Excremento",
  72: "Sorpresa",
  73: "Hospital",
  74: "Negros",
  75: "Payasos",
  76: "Llamas",
  77: "Las piernas",
  78: "Remera",
  79: "Virgen",
  80: "La bocha",
  81: "Flores",
  82: "Pelea",
  83: "Mal tiempo",
  84: "Iglesia",
  85: "Linterna",
  86: "Humo",
  87: "Piojos",
  88: "El papa",
  89: "La rata",
  90: "El miedo",
  91: "Excusado",
  92: "Médico",
  93: "Enamorado",
  94: "Cementerio",
  95: "Anteojos",
  96: "Marido",
  97: "La mesa",
  98: "Lavandera",
  99: "Hermanos",
};

export const texts = [
  {
    game: "Quiniela Nacional",
    text: `<p>Conocé los números ganadores del sorteo de hoy de la Quiniela Nacional, así como las letras y los
    restantes 19 números que completan los 20 primeros puestos. Los sorteos se realizan cuatro veces al
    día: la Primera (a las 12 horas), la Matutina (a las 14.30 horas), la Vespertina (a las 17.30 horas) y la
    Nocturna (a las 21 horas).</p><p>El sorteo de los números se realiza en las salas de la Lotería de la Ciudad de Buenos Aires y se
    utilizan cuatro bolilleros que van a conformar los distintos números, el primero corresponde al
    millar, el segundo a la centena, el tercero a la decena y el cuarto a la unidad, y cada uno de esos
    bolilleros tienen diez bolillas (esas bolillas tienen números desde el 0 al 9) y un quinto bolillero que
    va a conformar las distintas ubicaciones en el tablero (este tiene veinte bolillas que van desde el 1 al
    20, que corresponden a las veinte posiciones en el tablero).</p>`,
  },
  {
    game: "Quiniela Provincia",
    text: `<p>Seguí en directo los sorteos de de hoy de la Quiniela de la Provincia de Buenos Aires. Conocé los
    números ganadores de La Primera, la Matutina, la Vespertina y la Nocturna, cuál salió a la cabeza,
    cuáles completan los 20 primeros y el significado de los sueños de cada uno de ellos.</p><p>Este juego tradicional es administrado por Lotería de la Provincia Y durante el sorteo se extraen 20
    números del 0000 al 9999. La apuesta mínima en la Quiniela de la Provincia de Buenos Aires es de
    2 pesos que, de resultar ganadora, puede multiplicarse en siete, setenta, seiscientos o tres mil
    quinientas veces, según la variante utilizada.</p><p>¿Qué tipos de apuestas hay? Apuesta directa: se efectúa a un número de 1, 2, 3 o 4 dígitos, pudiendo
    apostar a la cabeza, a los premios o a ambos. O apuesta redoblona, que se efectúa en forma
    combinada, a dos números de dos cifras, distintos o iguales. En el último caso, los números deberán
    figurar como mínimo dos veces en el extracto dentro del lugar de ubicación elegido.</p>`,
  },
  {
    game: "Quiniela de Córdoba",
    text: `<p>Conocé los números ganadores, cuáles salieron hoy a la cabeza y todo lo que necesitás saber sobre
    la Quiniela de Córdoba en sus cuatro cuatro sorteos: la Primera de la Mañana, la Matutina, la
    Vespertina y la Nocturna.</p><p>Organizado por la Lotería de Córdoba, el juego de la Quiniela consiste en apostar una cierta
    cantidad de dinero a números de 1 cifra, 2 cifras, 3 cifras o 4 cifras.</p>`,
  },
  {
    game: "Quiniela Santa Fe",
    text: `<p>Conocé los números ganadores del sorteo de hoy de la Quiniela de Santa Fe, cuáles salieron a la
    cabeza y cuáles en segunda y tercera posición. También qué números completan los primeros diez
    puestos y el listado de desde el 11° al 20° premio.</p><p>La Lotería de Santa Fe organiza el sorte de la Quiniela desde 1982, en cuatro modalidades: la
    Primera, la Matutina, la Vespertina y la Nocturna.</p>`,
  },
  {
    game: "Quiniela Santiago del Estero",
    text: `<p>Seguí los sorteos de hoy de la Tómbola Santiagueña (también conocida como Quiniela de Santiago
      del Estero), en sus tres modalidades: la Matutina, la Vespertina y la Nocturna.</p><p>Con extraordinarios premios y una cifra muy alta de jugadores, la Tómbola Santiagueña es
      organizada por la Lotería de Santiago del Estero.</p>`,
  },
  {
    game: "Quiniela Montevideo",
    text: `<p>Conocé el resultado de los sorteos de hoy de la Quiniela de Montevideo, que se realizan de lunes a
    viernes a las 15 horas (vespertino) y a las 21 horas (nocturno). Ojo, los días sábados sólo se lleva a
    cabo el sorteo nocturno.</p>`,
  },
  {
    game: "Quini 6",
    text: `<p>Conocé los números ganadores del sorteo de hoy en las modalidades el Tradicional, la Segunda, la
    Revancha, el Siempre Sale y el Premio Extra.</p>`,
  },
  {
    game: "Telekino",
    text: `<p>Comprobá cuáles son los 15 números ganadores del pozo máximo del sorteo del Telekino de hoy y
    los del Rekino, que se reparte entre los ganadores que logran 14 coincidencias en su cartón.</p>`,
  },
  {
    game: "Loto",
    text: `<p>Conocé los números ganadores del sorteo de hoy en sus tres modalidades: el Tradicional, el
    Desquite y el Sale o Sale.</p>`,
  },
  {
    game: "Loto 5",
    text: `<p>Conocé los 5 números ganadores del sorteo de hoy del Loto 5.</p>`,
  },
  {
    game: "Toto Bingo",
    text: `<p>Enterate cuáles son los números ganadores del sorteo de hoy del Toto Bingo.</p>`,
  },
  {
    game: "Quiniela Poceada",
    text: `<p>Seguí minuto a minuto el sorteo y conocé los números ganadores de la Poceada de hoy.</p>`,
  },
  {
    game: "Brinco",
    text: `<p>Conocé cuáles son los núneros del sorteo de hoy del Brinco.</p>`,
  },
  {
    game: "Quiniela Plus",
    text: `<p>Conocé el resultado del sorteo de hoy del Plus.</p>`,
  },
  {
    game: "Mono Bingo",
    text: `<p>Enterate antes que nadie del resultado del sorteo de hoy del Mono.</p>`,
  },
];
