import { Service } from "models/Service";
import axios from 'axios';
import { cities } from "./data";


export class RuralWeather extends Service {
  constructor() {
    super({
      adsPath: "servicios/clima",
      meta: { 
        description: `Temperatura actual en rural. Enterate el pronóstico de hoy y cómo va a estar el clima los próximos días en rural - Clarín.com`, 
        keywords: "noticias, política, deportes, economía, internacionales, mundo, espectáculos, Argentina, sociedad, ciudades, policiales, internet, tecnología, infografías, fotos, videos, audios, multimedia, clima, claringrilla, humor, blogs, mapas, archivo, edición impresa, ", 
        title: `Clima de hoy en rural`,
        url: `/rural/clima`, 
        extra: [] 
      },
      section: "clima",
      sectionAds: "rural",
      template: "RuralWeather",
      title: "Clima",
      slug: "clima",
      titleHeader:"Clima"
    });
  }

  parseData = async (query) => {    
    let weatherCity = 'buenos aires';
    const slug= this.getSlug()
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=9`);
    if(query[2]) {
        weatherCity = query[2];
        const city = cities.find((element) => element.realName.normalize("NFC").toLowerCase() == weatherCity.normalize("NFC").toLowerCase()) ?? { "realName": "Buenos Aires", "lat": "-34.6083000", "long": "-58.3712000" }
        this.setMeta(this.parseMeta({ 
          description: `Temperatura actual en ${city.realName}. Enterate el pronóstico de hoy y cómo va a estar el clima los próximos días en ${city.realName} - Clarín.com`, 
          keywords: "noticias, política, deportes, economía, internacionales, mundo, espectáculos, Argentina, sociedad, ciudades, policiales, internet, tecnología, infografías, fotos, videos, audios, multimedia, clima, claringrilla, humor, blogs, mapas, archivo, edición impresa", 
          title: `Clima de hoy en ${city.realName}`, 
          url: `/rural/clima/${city.realName}`, 
          extra: [] 
        }));
    }    
    const searchCity = cities.find(
      (element) =>
        element.realName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ñ/g, "n").replace(/Ñ/g, "N").toLowerCase() ===
        weatherCity.replace(/-/g, ' ').normalize("NFC").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    );
    const proxy = {
      protocol: 'http',
      host: '192.168.248.178',
      port: 5889,
    };
    const weather = await axios.get(`https://gateway.cultivointeligente.com.br/weather_forecast?latitude=${searchCity?.lat}&longitude=${searchCity?.long}&gmt_offset=-3&country_code=ar&apikey=rfdEDNLIQoJEv97Ovcyg1w`, {    
      proxy: proxy,
      headers: {
        Accept: "application/json"
      },
      responseType: 'json',
    });
    const dataWeather = searchCity  != undefined && { name: searchCity.realName, ...weather?.data }
    
    this.setData({
      weather: dataWeather,
      cities,
      notes: notes?.data?.items ?? []
    });
  }
}