import { AppWrapper } from "@pagegrowler_nextjs";
import widgets from "@/widgets/index";
import templates from "@/templates/index";
import "../config.css";
import "../reset.css";
import "../base.css";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import { Auth0Provider } from "@auth0/auth0-react";

import { pagegrowlerConfigFrontend } from "../pagegrowler.config";
import { useAppTrue } from "../hooks";

function Application({ Component, pageProps }) {
  /* Se importan los widgets desde la aplicacion padre hacia la libreria */
  pageProps.widgets = widgets;
  pageProps.templates = templates;
  pageProps.lila_url = process.env.NEXT_PUBLIC_LILAX_URL;
  pageProps.pagegrowler_backend_url =
    process.env.NEXT_PUBLIC_PAGEGROWLER_BACKEND_URL;
  pageProps.pagegrowler_frontend_api_url =
    process.env.NEXT_PUBLIC_PAGEGROWLER_FRONTEND_API;

  pageProps.matchConfig = pageProps.matchConfig || { query: {} };
  pageProps.externalConfig = pagegrowlerConfigFrontend;
  
  const isApp = useAppTrue();
  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
      useRefreshTokensFallback={true}
      authorizationParams={{
        redirect_uri: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/login`,
        issuer: process.env.NEXT_PUBLIC_AUTH0_ISSUER,
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        scope: 'openid email profile offline_access'
      }}
    >
      <div style={{marginTop: isApp ? "30px" : "0px"}}>
        <AppWrapper>
          <Component {...pageProps} />
        </AppWrapper>
      </div>
    </Auth0Provider>
  );
}
export default Application;
