import HttpsProxyAgent from "https-proxy-agent";

export const getDataComments = async (idNews) => {
  const { data } = await fetchData(idNews);
  return data;
};

const fetchData = async (idNews) => {
  const agent = new HttpsProxyAgent("http://192.168.248.178:5889");
  try {
    const response = await fetch(
      `https://livecomments.viafoura.co/v4/livecomments/00000000-0000-4000-8000-82628f44cd3d/contentcontainer/id?container_id=${idNews}`,
      {
        agent,
        headers: {
          'X-Web-TTL': '60s',
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();
    return {
      code: 200,
      data: data ?? {},
      error: null,
    };
  } catch (error) {
    return {
      code: 500,
      data: {},
      error: "Internal server error",
    };
  }
};
