import { useEffect, useState } from "react";

const useGetMoneyPrice = (type = 'dolar') => {

    const [officialSale, setOfficialSale] = useState({ local: 0, localBank: '', [type]: 0, aliasMoney: '' });
    const [isLoading, setIsLoading] = useState(true);

    const getPrice = async () => {
        try {
            const response = await fetch(`/api/getMoneyPrice/${type}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            const resp_data = await response.json();
            const { data } = resp_data.data[0];
            
            if (resp_data.code == 200 && data) {
                switch (type) {
                    case 'dolar':
                        if(data.oficial_venta){
                            setOfficialSale({ 
                                local: data.oficial_venta,
                                localBank: 'Banco Nación',
                                [type]: data.blue_venta,
                                aliasMoney: 'Blue',
                            });
                            setIsLoading(false);
                        }
                        break;                
                    default:
                        break;
                }                
            }
        } catch (error) {
            console.error('Error: fetch api '+ type);
        }
    }

    useEffect(() => {
        getPrice();
    }, []);

    return {
        officialSale,
        isLoading,
    }
}

export default useGetMoneyPrice;