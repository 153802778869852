import { datePost, getImageForWidgetNews, getVideoLoopUrl } from "../helpers";
import { useClippingForPreview } from "./useClippingForPreview";
import { useRouter } from "next/router";

const useNewsDataModules = (props, clippingsRequired = { desktop: [], mobile: []}) => {  
  const router = useRouter();
  const payWallExclusive = props?.metadata?.payWallAccessControl === "suscribers-only";
  const { imagePreview } = useClippingForPreview(
    clippingsRequired,
    props?.images,
    props?.customClippings,
    props?.selectedImageId,
    props?.croppedImages
  );

  let imageWeb = getImageForWidgetNews({
    clippingsRequired,
    images: props?.images,
    selectedImageId: props?.selectedImageId,
    customClippings: props?.customClippings,
    croppedImages: props?.croppedImages,
    imageOriginUrl: props?.imageOriginUrl
  })  

  if(props?.epigraphe && imageWeb) {
    imageWeb.epigraphe = props.epigraphe
  }

  const urlSelected = props?.urlAlternative?.trim() ? props?.urlAlternative : props?.url;

  const containerName = props?.__extra?.containerName ?? "";

  const urlTarget = props?.urlTarget ? props?.urlTarget : '_self';

  const date = datePost(props?.publishedDate);

  let videoLoop =  "";
  if(props.videosOpenLoop?.enabled){
    videoLoop = getVideoLoopUrl(props.videosOpenLoop,props.video);
  }

  return {
    ...props,
    asPath: router.asPath,
    containerName,
    date,
    imageWeb,
    imagePreview,
    payWallExclusive,
    url: urlSelected,
    urlTarget, 
    videoLoop
  };
}

export default useNewsDataModules;