import {
    AppWrapper as ImportedAppWrapper, 
    useAppContext as ImportedUseAppContext,
    PagegrowlerInstance as ImportedPagegrowlerInstance,
} from 'pagegrowler-nextjs';

import 'pagegrowler-nextjs/index.css';

export function AppWrapper({ children }) {
    return ImportedAppWrapper({ children });
}

export function useAppContext() {
    return ImportedUseAppContext();
}

export function PagegrowlerInstance() {
    return ImportedPagegrowlerInstance();
}