import { futbolData, getTournamentData } from "@/helpers/getEstadisticas";
import { Service } from "models/Service";

export class SportsCalendar extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Agenda Deportiva - Clarin.com`,
        keywords: '',
        title: `Agenda Deportiva - Clarin.com`,
        url: `deportes/agenda-deportiva`, 
        extra: [],
      },
      section: "deportes",
      sectionName: "Deportes",
      template: "SportsCalendar",
      title: `Agenda Deportiva`,
      widthFull: false,
      slug: "deportes/agenda-deportiva/",
      titleHeader:"Agenda Deportiva"
    });    
  }
  
  parseData = async (query) => {
    const slug = "deportes";
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`);
    const parseUrl = query.join('/')
    this.setSlug(parseUrl);
    this.setData({
      notes: notes?.data?.items ?? [],
    });
    switch (query?.[1]) {
      case "futbol-masculino-juegos-panamericanos-2023-santiago-chile-fixture-resultados":
        this.setTemplate("PanamericanGames");
        this.setTitleHeader(`Futbol masculino`);
        this.setTitle(`Futbol masculino | Juegos Panamericanos 2023: fixture y resultados de las selecciones en Santiago de Chile`);
        this.setWidthFull(true);
        this.setMeta(this.parseMeta({ 
          description: `Tabla, fixture, resultados y próximo partidos del fútbol masculino en los Juegos Panamericanos en Chile 2023.`, 
          keywords: "juegos panamericanos, futbol", 
          title: `Futbol masculino | Juegos Panamericanos 2023: fixture y resultados de las selecciones en Santiago de Chile`, 
          url: parseUrl, 
          extra: [] 
        }));
        this.setData({
          iframe: "https://gamecenter.clarin.com/v3/index.html?channel=deportes.futbol.futbolmascpan.fixture&lang=es_LA",
          height: "550",
          notes: notes?.data?.items ?? [],
        });
        break;
      case "futbol-femenino-juegos-panamericanos-2023-santiago-chile-seleccion-argentina-fixture-resultados":
        this.setTemplate("PanamericanGames");
        this.setTitleHeader(`Futbol femenino`);
        this.setTitle(`Futbol femenino | Juegos Panamericanos 2023: fixture y resultados de la Selección Argentina en Santiago de Chile`);
        this.setWidthFull(true);
        this.setMeta(this.parseMeta({ 
          description: `Tabla, fixture, resultados y próximo partidos de la Selección Argentina de fútbol Femenino en los Juegos Panamericanos en Chile 2023.`, 
          keywords: "juegos panamericanos, futbol", 
          title: `Fútbol femenino | Juegos Panamericanos 2023: fixture y resultados de la Selección Argentina en Santiago de Chile`, 
          url: parseUrl, 
          extra: [] 
        }));
        this.setData({
          iframe: "https://gamecenter.clarin.com/v3/index.html?channel=deportes.futbol.futbolfempan&lang=es_LA",
          height: "550",
          notes: notes?.data?.items ?? [],
        });
        break;
      case "juegos-panamericanos-2023-santiago-chile-medallero-resultados-posiciones":
        this.setTemplate("PanamericanGames");
        this.setTitleHeader(`Medallero y resultados`);
        this.setTitle(`Medallero y resultados | Juegos Panamericanos 2023: todas las posiciones y quién lidera la tabla del torneo`);
        this.setWidthFull(true);
        this.setMeta(this.parseMeta({ 
          description: `El medallero y las últimas noticias de Argentina y el resto de países que compiten en los Juegos Panamericanos en Chile 2023.`, 
          keywords: "juegos panamericanos, futbol", 
          title: `Medallero y resultados | Juegos Panamericanos 2023: todas las posiciones y quién lidera la tabla del torneo`, 
          url: parseUrl, 
          extra: [] 
        }));
        this.setData({
          iframe: "https://gamecenter.clarin.com/jjpp/es/medallero.html",
          height: "1000",
          notes: notes?.data?.items ?? [],
        });
        break;
      case "estadisticas":
        let sport = query?.[2]
        let competition = query?.[3].replace(".html", "")
        this.setTemplate("SportsStatics");
        this.setTitleHeader("Estadísticas");
        this.setWidthFull(false);
        this.setMeta(this.parseMeta({ 
          description: `Estadísticas de deportes de Clarín`, 
          keywords: "estadisticas", 
          title: `Estadísticas de deportes de Clarín`, 
          url: parseUrl, 
          extra: [] 
        }));
        if(sport === 'futbol'){
          let tournamentData = await futbolData(competition)
          let nameCompetition = competition == "primera_a" ? "Primera División" : tournamentData?.tournamentConf?.name || competition.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, c => c.toUpperCase());
          this.setTitleHeader(`Estadísticas  ${nameCompetition}`);
          this.setTitle(`Estadísticas ${nameCompetition}`);
          this.setData({
            tournamentData: tournamentData ?? [],
            notes: notes?.data?.items ?? [],
          });
        }
        break;
    }
  }
}