import { Service } from "models/Service";

export class Tags extends Service {
    constructor(query) {
        super({
            adsPath: "servicios/home",
            meta: { 
                description: `Temperatura actual en Ciudad de Buenos Aires. Enterate el pronóstico de hoy y cómo va a estar el clima los próximos días en Ciudad de Buenos Aires - Clarín.com`, 
                keywords: "noticias, política, deportes, economía, internacionales, mundo, espectáculos, Argentina, sociedad, ciudades, policiales, internet, tecnología, infografías, fotos, videos, audios, multimedia, clima, claringrilla, humor, blogs, mapas, archivo, edición impresa, ", 
                title: `Noticias por temas, índice de temas – Clarín.com`, 
                url: `temas`, 
                extra: [] 
            },
            section: "temas",
            template: "Tags",
            title: "Temas",
            slug: "temas",
            titleHeader:"Temas"
        });   
    }

    parseData = async (query) => {  
        let search = "";
        let filteredTags = [];
        if(query[1]) {
            search = query[1];
            const titleNew = search.length === 1 ? `Noticias por temas, índice de temas: letra ${search} – Clarín.com` :  `Noticias por temas, índice de temas: ${search} – Clarín.com`
            this.setMeta(
                this.parseMeta({ 
                    description: `Temperatura actual en ${search}. Enterate el pronóstico de hoy y cómo va a estar el clima los próximos días en ${search} - Clarín.com`, 
                    keywords: "noticias, política, deportes, economía, internacionales, mundo, espectáculos, Argentina, sociedad, ciudades, policiales, internet, tecnología, infografías, fotos, videos, audios, multimedia, clima, claringrilla, humor, blogs, mapas, archivo, edición impresa", 
                    title: titleNew, 
                    url: `temas/${search}`, 
                    extra: [] 
                })
            );
        }    
        /* const apiUrl = `${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/tags/?limit=100&offset=0`;
        const tags = await this.fetchData(apiUrl);
        filteredTags = tags?.data?.items.filter(tag => tag.isPublished === true && tag.name.toLowerCase().includes(search.toString().toLowerCase()));
        if(filteredTags){
            await Promise.all(filteredTags.map(async (item, index) => {
                let listTags = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/tags/${item.slug}?limit=100&offset=0`);
                item.relatedTags = listTags.data;
            }));
        } */
        this.setData({
            tags: [filteredTags, search]
        });
    }

}