import { useEffect, useState } from "react";

const useGetLocation = () => {

    const [location, setLocation] = useState({ name: '', expires: '' });
    const [zone, setZone] = useState({ name: '', expires: '' });

    const getLocation = async () => {

        try {

            const url = 'https://api-editoriales.clarin.com/0778a0d2d20704cc978389c74565352e/042505ecee3f71651eced5be4c90905e';
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`geolocalizacion`);
            }

            const { 
                item: {
                    data,
                    details: {
                        region,
                    }
                },
                status,
            } = await response.json();            

            if (data && region && data.length && region.length && status == "success") {
                const date = new Date();
                date.setMonth(date.getMonth() + 3);
                const expires = date.toUTCString();
                localStorage.setItem("location", JSON.stringify({ name: data, expires }));
                localStorage.setItem("region", JSON.stringify({ name: region, expires }));

                setLocation({ name: data, expires });
                setZone({ name: region, expires });
            }

        } catch (error) {
            console.error('Error: fetch api geolocalizacion');
        }

    }

    useEffect(() => {

        const locationStorage = JSON.parse(localStorage.getItem('location'));
        const regionStorage = JSON.parse(localStorage.getItem('region'));

        let flag = false;
        if (locationStorage
            && locationStorage.name
            && locationStorage.name !== ''
            && locationStorage.expires
            && locationStorage.expires !== ''
            && regionStorage
            && regionStorage.name
            && regionStorage.name !== ''
            && regionStorage.expires
            && regionStorage.expires !== '') {

            const dateExpires = new Date(locationStorage.expires);
            const date = new Date();
            const difference = dateExpires.getTime() - date.getTime();
            const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

            if (totalDays > 0) {
                setLocation(locationStorage);
                setZone(regionStorage);
                flag = true;
            }

        }

        if (!flag) getLocation();

        if (zone.name === "Europe") {
            // implementar
            // GDPRInit();
        }

    }, []);

    //console.log({ location });
    //console.log({ zone });

    return {
        location,
        zone,
    }
}

export default useGetLocation;