import { useEffect, useState } from 'react';

const useSetAcTiveScroll = () => {

    const [activate, setActivate] = useState(false);

    const handleActivate = () => {
        if (!activate) {
            setActivate(true);
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', handleActivate);
        return () => {
            document.removeEventListener('scroll', handleActivate);
        }
    }, []);

    return {
        activate,
    }

}

export default useSetAcTiveScroll;