import { useEffect, useState } from "react";

const useMostViewed = (provider =  "bi", limit = 6) => {

    const [mostViewed, setMostViewed] = useState([]);

    const getMostViewed = async () => {
        try {
            const response = await fetch(`/api/viewed/${limit}/${provider}`);
            const { data, code } = await response.json();
            if (code === 200 && data.length > 0) {
                setMostViewed(data);
            }
        } catch (error) {
            console.error(`Error fetch useMostViewed`);
            console.error(error)            
        }        
    }
    useEffect(() => {
        getMostViewed();
    }, []);

    return {
        mostViewed,
    }

}

export default useMostViewed;