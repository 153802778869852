export const deleteLinkByHref = (text, contentHref) => {
  if (contentHref && text) {
    const regex = new RegExp(`<a\\s+href=["'][^"']*undefined[^"']*["'][^>]*>(.*?)<\/a>`, "g");
    return text.toString().replace(regex, "$1");
  } else {
    return text;
  }
};

export const formatTextLinkTags = (textForRender) => {
  const urlTag = `href="${process.env.NEXT_PUBLIC_DOMAIN_URL}/tema/`;
  const regexReplace = /href="(?!https)/;
  const regexMatch = /href="(?!https)([a-zA-Z0-9-]+)/g;
  let search = textForRender?.match(regexMatch);
  if (search && search.length > 0) {
    for (let index = 0; index < search.length; index++) {
      const elementSearch = search[index];
      if (!elementSearch.includes("http") && !elementSearch.includes("www")) {
        if (!textForRender.match(`${elementSearch}.html`)) {
          textForRender = textForRender.replace(
            elementSearch,
            `${elementSearch}.html`
          );
        }
        textForRender = textForRender.replace(regexReplace, urlTag);
        textForRender = textForRender.replace('target="_blank"', "");
      }
      if(elementSearch.includes("www")) {
        textForRender = textForRender.replace("www","https://www");        
      }
    }
  }
  return textForRender;
}

export const normalizeString = (str) => {
  if (str) {
    str = str
    .normalize("NFD") // Normaliza los caracteres a sus equivalentes sin acentos
    .replace(/[\u0300-\u036f]/g, "") // Elimina los acentos diacríticos
    .replace(/[^a-zA-Z0-9]+/g, "-") // Reemplaza caracteres especiales y espacios con "-"
    .toLowerCase(); // Convierte a minúsculas

    // Elimina "-" al principio y al final, si los hay
    str = str.replace(/^-+|-+$/g, "");

    return str;
  }
  return '';
}

export const isJson = (text) => {
  try {
      JSON.parse(text);
  } catch (e) {
      return false;
  }
  return true;
}

export const normalizeWord = (word) => {
    return word != "" ? word.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "";
}

export const maskEmail = (email = "") => {
  const [localPart, domain] = email.split('@');
  if (localPart.length <= 4) {
    return email;
  }
  const maskedLength = localPart.length - 4;
  const maskedLocalPart = `${localPart.slice(0, 2)}${'*'.repeat(maskedLength)}${localPart.slice(-2)}`;
  return `${maskedLocalPart}@${domain}`;
};

export const isEmailMasked = (email = "") => {
  const [localPart] = email.split('@');
  const maskedPattern = /^[a-zA-Z0-9]{2}\*+[a-zA-Z0-9]{2}$/;
  return maskedPattern.test(localPart);
};