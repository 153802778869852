import { isJson, maskEmail } from "helpers";

export const getUserProfile = () => {
  let userProfile = window.localStorage.getItem("wads_userProfile")
    ? window.localStorage.getItem("wads_userProfile")
    : null;
  if (isJson(userProfile)) {
    userProfile = JSON.parse(userProfile);
    if (userProfile?.value) {
      return userProfile.value;
    }
  }
  return userProfile;
};

export const setUserLocalStorage = (user, response) => {
  let suscription =
    response.user != null
      ? response.user.data.subscriptions.map((sus) => {
          return sus.pId;
        })
      : "";
  let userStorage = {
    uid: user.sub,
    name: user.hasOwnProperty("https://clarin.com/etiquetaUsuario")
      ? user["https://clarin.com/etiquetaUsuario"]
      : user.name,
    email: user.email,
    genero: response.hasOwnProperty("https://clarin.com/genero")
      ? response["https://clarin.com/genero"]
      : "",
    picture: user.picture,
    fechaNacimiento: response.hasOwnProperty(
      "https://clarin.com/fechaNacimiento"
    )
      ? response["https://clarin.com/fechaNacimiento"]
      : "",
    apodo: user.nickname,
    estado: "ACTIVO",
    // proveedorDeIdentidad: user.sub.split("|")[0],
    // proveedorDeLogin: user.sub.split("|")[0],
    siteUID: true,
    subscriptionStatus: suscription.includes("CLARIN_C") || suscription.includes("CLARIN_D") || suscription.includes("CLA_OLE") ? "subscribed" : '',
    subscriptionProducts: suscription,
    id: user.sub,
    sessionId: response.user != null ? response.user._id : "",
    sessionStatus: response.user != null ? "valid" : "",
    userSubscriptionDate: response.user != null ? response?.user?.createdAt : "",
    jwt: response.jwt != null ? response.jwt : "",
    auth0: user,
  };
  window.localStorage.setItem("wads_userProfile", JSON.stringify(userStorage));
  if (userStorage.subscriptionStatus == "subscribed") {
    let emailSuscription = {
      email: maskEmail(userStorage.email),
      type: userStorage?.id?.split("|")?.[0] ?? "auth0",
    };
    window.localStorage.setItem("email_suscription", JSON.stringify(emailSuscription));
  }
};

export const setToken = (token) => {
  let expDate = new Date();
  expDate.setMinutes(expDate.getMinutes() + 5);
  document.cookie =`__token_auth0=${token}; expires=${expDate.toUTCString()}; path=/`;
};

export const getExpireDate = (expire) => {
  let expDate = new Date();
  expDate.setHours(expDate.getHours() + expire);
  return expDate;
};

export const deleteCookie = (cookie) => {
  document.cookie = cookie +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}