export const generatePDF = (nroClaringrilla) => {
    const element = document.getElementById('gridContent'); // Obtener el elemento padre

    html2pdf(element, {
      margin: [0,2,0,3],
      filename: 'claringrilla_Nro_'+ nroClaringrilla +'.pdf',
      html2canvas:  { scale: 3, scrollY: 0 },
      jsPDF: {
        unit: 'mm',
        format: 'a4', // Formato de página (por ejemplo, 'a4', 'letter', etc.)
        orientation: 'portrait'
      },
      output: 'save', // Opciones: 'save', 'download', 'arraybuffer', 'blob', 'pdfobject'
    });

    console.log("Pdf generado. Descargando...");
    

  };  