import { Service } from "models/Service";

const apiUrl = "http://api.lilax.agea.com.ar/services/v1/weather";

export class Weather extends Service {
  constructor() {
    super({
      adsPath: "servicios/clima",
      meta: { 
        description: `Estado actual del tiempo en Argentina, condiciones climáticas, temperatura y pronóstico para los próximos días del clima en Capital Federal, Buenos Aires y todo el país.`, 
        keywords: `Servicio metereológico nacional, clima del día, pronóstico del tiempo, hoy, día, ola de calor, lluvias`, 
        title: `Clima de hoy en Argentina. El pronóstico del tiempo en Clarín.com`, 
        url: `clima`, 
        extra: [] 
      },
      section: "clima",
      sectionName: "Clima",
      sectionAds: "clima",
      template: "Weather",
      title: "Clima de hoy en Argentina. El pronóstico del tiempo en Clarín.com",
      slug: "clima",
      titleHeader: "Clima",
      titleHeaderTag: "p",
      breadcrumb: [
        {
          title: "Clima",
          url: "/clima",
        },
        {
          title: "Argentina",
          url: "/clima",
        }
      ]
    });
  }

  parseData = async (query) => {    
    let weatherCity = 'ciudad-de-buenos-aires';
    const slug = this.getSlug();
    const cities = await this.fetchData(apiUrl);
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`);

    let weather = {};
    
    if(query[1]) {
      this.setTemplate("WeatherCity");
      this.setTitleHeaderLink("/clima");
      this.setTitleHeaderTag("p");
      weatherCity = query[1];
      const city = cities?.data?.items?.find((element) => element.slug == weatherCity) ?? {realName: "Ciudad de Buenos Aires"}
      const urlNormalize = city.realName.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "-")
          .toLowerCase();
      this.setMeta(this.parseMeta({ 
        description: `Consultá el pronóstico del tiempo y el clima hoy en ${city.realName}. Conocé cómo estará el pronóstico extendido y las tempraturas máximas y mínimas, para anticiparse a cualquier fenómeno meteorológico. - Clarín.com`, 
        keywords: `clima, servicio meteorológico, pronóstico del tiempo, hoy, día, ${city.realName}`, 
        title: `Pronóstico del tiempo y clima hoy en ${city.realName}`, 
        url: `clima/${urlNormalize}`, 
        extra: [] 
      }));
      this.setTitle(`Pronóstico del tiempo y clima hoy en ${city.realName}`)
      this.setBreadcrumb([
        {
          title: "Clima",
          url: "/clima",
        },
        {
          title: city.realName,
          url: "",
        }
      ])
      this.setSectionAds(urlNormalize);
      weather = await this.fetchData(`${apiUrl}/${weatherCity}`);
    }    
    
    this.setData({
      weather: weather?.data?.item ?? {},
      cities: cities?.data?.items ?? [],
      notes: notes?.data?.items ?? [],
    });
  }
}
