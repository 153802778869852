import { Service } from "models/Service";

export class Olympics extends Service {
  constructor() {
    let year = new Date().getFullYear();
    super({
      adsPath: "servicios/home",
      meta: { 
        description: `Toda la información actualizada de los Juegos Olímpicos Paris 2024, con especial foco en las principales figuras del deporte y en los atletas de la delegación argentina. Podrás encontrar la agenda de cada una de las disciplinas minuto a minuto, los récords y el medallero olímpico.`,
        keywords: "Juegos Olímpicos Paris 2024, Juegos Olímpicos, Argentina en los Juegos Olímpicos", 
        title: `Juegos Olímpicos París ${year}`, 
        url: `juegos-olimpicos`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
        extra: [] 
      },
      section: "juegosolimpicos",
      sectionAds: "juegosolimpicos",
      template: "Olympics",
      title: `Juegos Olímpicos 2024: noticias, horarios, competiciones y resultados | Clarín`, 
      slug: "olimpicos",
      titleHeader: "Juegos Olímpicos París 2024",
      titleHeaderTag: "h1",
      breadcrumb: [
        {
          title: "Juegos Olímpicos",
          url: "/juegos-olimpicos",
        }
      ]
    });
  }

  parseData = async (query) => {
    const slug = "juegos-olimpicos";
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`);
    const manageQuery = (string) => {
      if(string=="azerbaiyan"){
          string="azerbaiyán";
      }
      if(string=="belgica"){
          string="bélgica";
      }
      if(string=="mexico"){
          string="méxico";
      }
      if(string=="hungria"){
        string="hungría";
      }
      if(string=="canada"){
        string="canadá";
      }
      if(string=="corea-del-sur"){
        string="Corea del Sur";
      }
      if(string=="espana"){
        string="españa";
      }
      if(string=="gran-bretana"){
        string="Gran Bretaña";
      }
      if(string=="hongkong"){
        string="Hong Kong";
      }
      if(string=="japon"){
        string="japón";
      }
      if(string=="kazajistan"){
        string="kazajistán";
      }
      if(string=="sudafrica"){
        string="sudáfrica";
      }
      if(string=="turquia"){
        string="turquía";
      }
      if(string=="tunez"){
        string="túnez";
      }
      if(string=="uzbekistan"){
        string="uzbekistán";
      }
      if(string=="triatlon"){
        string="triatlón";
      }
      if(string=="badminton"){
        string="badmintón";
      }
      if(string=="basquet"){
        string="básquet";
      }
      if(string=="natacion"){
        string="natación";
      }
      if(string=="futbol"){
        string="fútbol";
      }
      if(string=="ciclismo-de-montana"){
        string="Ciclismo de Montaña";
      }
      if(string=="gimnasia-artistica"){
        string="Gimnasia Artística";
      }
      if(string=="tenis-de-mesa"){
        string="Tenis de mesa";
      }
      if(string=="tiro-con-arco"){
        string="Tiro con arco";
      }
      if(string=="paises-bajos"){
        string="Países Bajos";
      }
      const exceptions = ['de'];
      if (string?.includes('-')) {
        return string.split('-').map(word => {
          if (exceptions.includes(word.toLowerCase())) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      }
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    };
    if(query[1] && query[2] === "medallero") {
      this.setTemplate("MedalTable");
      this.setMeta(this.parseMeta({
        description: "Medallero de los Juegos Olímpicos de París 2024 en Francia: clasificación por países de medallas de oro, plata y bronce conseguidas en los Juegos Olímpicos. Celebrá los logros de los mejores deportistas del año.",
        seoText: "Medallero de los Juegos Olímpicos de París 2024 en Francia: clasificación por países de medallas de oro, plata y bronce conseguidas en los Juegos Olímpicos. Celebrá los logros de los mejores deportistas del año.",
        keywords: "Juegos Olímpicos 2024, París 2024, Argentina",
        title: "Medallero, resultados y ganadores de cada competencia de los Juegos Olímpicos de París 2024",
        url: `juegos-olimpicos/paris/medallero`,
    }));
    this.setTitle("Medallero, resultados y ganadores de cada competencia de los Juegos Olímpicos de París 2024");
    this.setTitleHeader("Medallero, resultados y ganadores de cada competencia de los Juegos Olímpicos de París 2024");
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Medallero", url: "medallero" },
    ]);
    this.setData({
      notes: notes?.data?.items ?? [],
    });
    }

    if(query[1] && query[2] === "agenda") {
      this.setTemplate("CoverAgenda");
      this.setMeta(this.parseMeta({
        description: "Consulta la agenda oficial de los Juegos Olímpicos de París 2024 y descubre los horarios de eventos, resultados en tiempo real y las medallas ganadas por cada país participante.",
        seoText: "Seguí los resultados, el último momento y el calendario oficial de los Juegos Olímpicos de París del 26 de julio al 11 de agosto de 2024. Medallero, fotos, videos y más de las ceremonias, los atletas y las competiciones.",
        keywords: "Juegos Olímpicos 2024, calendario, Agenda deportiva, Francia",
        title: "Juegos Olímpicos París 2024: calendario, transmisión, horarios y fechas en directo de cada deporte | Clarín",
        url: `juegos-olimpicos/paris/agenda`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Agenda oficial de los Juegos Olímpicos de París 2024");
    this.setTitleHeader("Juegos Olímpicos París 2024: calendario, transmisión, horarios y fechas en directo de cada deporte");
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Agenda", url: "agenda" },
    ]);
    this.setData({
      landing: query[2] ? true : false,
      notes: notes?.data?.items ?? [],
    });
    }

    if(query[1] && query[2] === "records") {
      this.setTemplate("Records");
      this.setMeta(this.parseMeta({
        description: "Explorá los récords olímpicos establecidos en París 2024 y maravillate con las hazañas deportivas más impresionantes. Descubrí quiénes superaron los límites y dejaron su marca en la historia de los Juegos Olímpicos.",
        seoText: "Explorá los récords olímpicos establecidos en París 2024 y maravillate con las hazañas deportivas más impresionantes. Descubrí quiénes superaron los límites y dejaron su marca en la historia de los Juegos Olímpicos.",
        keywords: "Récords Olímpicos París 2024, Logros Históricos Juegos Olímpicos París 2024, Hazañas Deportivas París 2024,  Récords de Velocidad París 2024, Momentos Destacados Juegos Olímpicos París 2024",
        title: "Récords Olímpicos de París 2024 | Clarín",
        url: `juegos-olimpicos/paris/records`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Récords Olímpicos de París 2024");
    this.setTitleHeader("Récords Olímpicos de París 2024");
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Récords", url: "records" },
    ]);
    this.setData({
      notes: notes?.data?.items ?? [],
    });
    }

    if(query[1] && query[2] === "futbol-masculino") {
      this.setTemplate("Soccer");
      this.setMeta(this.parseMeta({
        description: "El fixture completo con los horarios y sedes de los partidos, los resultados, las tablas de posiciones y goleadores, las formaciones y toda la información del fútbol masculino en los Juegos Olímpicos París 2024.",
        seoText: "El fixture completo con los horarios y sedes de los partidos, los resultados, las tablas de posiciones y goleadores, las formaciones y toda la información del fútbol masculino en los Juegos Olímpicos París 2024.",
        keywords: "Récords Olímpicos París 2024, Logros Históricos Juegos Olímpicos París 2024, Hazañas Deportivas París 2024,  Récords de Velocidad París 2024, Momentos Destacados Juegos Olímpicos París 2024",
        title: "Fútbol Masculino en los juegos Olímpicos de París 2024",
        url: `juegos-olimpicos/paris/futbol-masculino`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Fútbol masculino en los Juegos Olímpicos París 2024 | Clarín");
    this.setTitleHeader("Fútbol masculino en los Juegos Olímpicos París 2024");
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Fútbol Masculino", url: "futbol-masculino" },
    ]);
    this.setData({
      type: "masculino",
      notes: notes?.data?.items ?? [],
    });
    }
    if(query[1] && query[2] === "futbol-femenino") {
      this.setTemplate("Soccer");
      this.setMeta(this.parseMeta({
        description: "El fixture completo con los horarios y sedes de los partidos, los resultados, las tablas de posiciones y goleadores, las formaciones y toda la información del fútbol femenino en los Juegos Olímpicos París 2024.",
        seoText: "El fixture completo con los horarios y sedes de los partidos, los resultados, las tablas de posiciones y goleadores, las formaciones y toda la información del fútbol femenino en los Juegos Olímpicos París 2024.",
        keywords: "Récords Olímpicos París 2024, Logros Históricos Juegos Olímpicos París 2024, Hazañas Deportivas París 2024,  Récords de Velocidad París 2024, Momentos Destacados Juegos Olímpicos París 2024",
        title: "Fútbol Femenino en los juegos Olímpicos de París 2024",
        url: `juegos-olimpicos/paris/futbol-femenino`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Fútbol femenino en los Juegos Olímpicos París 2024 | Clarín ");
    this.setTitleHeader("Fútbol femenino en los Juegos Olímpicos París 2024");
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Fútbol Femenino", url: "futbol-femenino" },
    ]);
    this.setData({
      type: "femenino",
      notes: notes?.data?.items ?? [],
    });
    }

    if(query[1] && query[2] === "podios" && query[3] == undefined) {
      this.setTemplate("Podium");
      this.setMeta(this.parseMeta({
        description: "Explorá los podios olímpicos de París 2024 y descubrí a los medallistas en cada evento deportivo. Resultados actualizados en tiempo real y momentos destacados de los ganadores en los Juegos Olímpicos de verano en París 2024.",
        seoText: "Explorá los podios olímpicos de París 2024 y descubrí a los medallistas en cada evento deportivo. Resultados actualizados en tiempo real y momentos destacados de los ganadores en los Juegos Olímpicos de verano en París 2024.",
        keywords: "Juegos Olímpicos 2024, París 2024, Juegos Olímpicos",
        title: "Podios de los Juegos Olímpicos de París 2024 | Clarín",
        url: `juegos-olimpicos/paris/podios`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Podios de los Juegos Olímpicos de París 2024");
    this.setTitleHeader("Podios de los Juegos Olímpicos de París 2024");
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Podios", url: "podios" },
    ]);
    this.setData({
      notes: notes?.data?.items ?? [],
    });
    }

    if(query[1] && query[2] && query[3] === "argentina") {
      
      this.setTemplate("Podium");
      this.setMeta(this.parseMeta({
        description: "Seguí los resultados, últimas noticias, entrevistas y competencias de los atletas de Argentina en los Juegos Olímpicos de París 2024. Medallero, fotos, videos y más de las ceremonias, los deportistas argentinos y las competiciones.",
        seoText: "Seguí los resultados, últimas noticias, entrevistas y competencias de los atletas de Argentina en los Juegos Olímpicos de París 2024. Medallero, fotos, videos y más de las ceremonias, los deportistas argentinos y las competiciones.",
        keywords: "atletas argentinos, podios de los medallistas argentinos, medallas argentina Paris 2024",
        title: "Podios Olímpicos de París 2024",
        url: `juegos-olimpicos/paris/podios/argentina`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Podios Olímpicos de París 2024");
    this.setTitleHeader("Podios Olímpicos de París 2024 | Atletas argentinos");
    query[3] ?
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Podios", url: "/juegos-olimpicos/paris/podios" },
      { title: `Podios ${query[3]}`, url: `${query[3]}`},
    ]) :  
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Podios", url: "/juegos-olimpicos/paris/podios" },
    ]);  
    this.setData({
      podium: query[3] ? manageQuery(query[3]) : null,
      notes: notes?.data?.items ?? [],
    });
    }


    if(query[1] &&  query[2] === "podios" && query[3] !== "argentina" && query[3] !== undefined) {      
      this.setTemplate("Podium");
      this.setMeta(this.parseMeta({
        description: "Explorá el podio de los deportistas de " + `${manageQuery(query[3])}` + " en los Juegos Olímpicos de París 2024. Resultados actualizados en tiempo real y momentos destacados de los ganadores en los Juegos Olímpicos en Francia.",
        seoText: "Explorá el podio de los deportistas de " + `${manageQuery(query[3])}` + " en los Juegos Olímpicos de París 2024. Resultados actualizados en tiempo real y momentos destacados de los ganadores en los Juegos Olímpicos en Francia.",
        keywords: "atletas argentinos, podios de los medallistas argentinos, medallas argentina Paris 2024",
        title: "Podios Olímpicos de París 2024",
        url: `juegos-olimpicos/paris/podios/` + `${(query[3])}`,
        urlImage: process.env.NEXT_PUBLIC_DOMAIN_URL + `/img/jjoo/jjoo-paris-2024.webp`,
    }));
    this.setTitle("Podio Olímpico de los ganadores de " + `${manageQuery(query[3])}` + " en París 2024 | Clarín");
    this.setTitleHeader("Podios de los Juegos Olímpicos de París 2024");
    query[3] ?
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Podios", url: "/juegos-olimpicos/paris/podios" },
      { title: `Podios ${manageQuery(query[3])}`, url: `${query[3]}`},
    ]) :  
    this.setBreadcrumb([
      { title: "Juegos Olímpicos", url: "/juegos-olimpicos" },
      { title: "Podios", url: "/juegos-olimpicos/paris/podios" },
    ]);  
    this.setData({
      podium: query[3] ? manageQuery(query[3]) : null,
      notes: notes?.data?.items ?? [],
    });
    }
  }
}

