import { useEffect, useState } from "react";

const UseSupportedBrowser = () => {
  const [supportedBrowser, setSupportedBrowser] = useState(false);

  useEffect(() => {
    navigator.sayswho= (function(){
      var ua= navigator.userAgent;
      var tem; 
      var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    })();
    if (navigator.sayswho != "Safari 11" && navigator.sayswho != "Safari 14" && navigator.sayswho != "Safari 13" && navigator.sayswho != "Safari 12") {
      setSupportedBrowser(true)
    }
  }, []);

  return supportedBrowser;
};
export default UseSupportedBrowser;