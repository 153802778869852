import { getYearPredictionsZodiac } from "@/helpers/getZodiacPredictions";
import { getChineseHoroscope, getChineseHoroscopeNew } from "@/helpers/getChineseHoroscope";
import { Service } from "models/Service";
//import YearPredictionsZodiac from "@/templates/Clarin/Service/components/Horoscope/YearPredictionsZodiac";

export class WesternAstrology extends Service {
  constructor() {
    let year = new Date().getFullYear();
    super({
      adsPath: "servicios/horoscopo",
      meta: { 
        description: `Consultá el horóscopo de hoy y mañana, gratis, de todos los signos del zodiaco: Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. Descubre las fechas de los 12 signos del zodiaco. Las predicciones del ${year} de Ludovica Squirru para los 12 signos del Horoscopo Chino.`, 
        keywords: "Signos zodiaco, horóscopo chino, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. Rata, Búfalo, Tigre, Conejo, Dragón, Serpiente, Caballo, Cabra, Mono, Gallo, Perro, Chancho.", 
        title: `Horóscopo: Signos del Zodiaco, Predicciones y Horóscopo Chino`, 
        url: `horoscopo`, 
        urlImage: `https://www.clarin.com/img/2020/07/07/Fpk73rLHR_1200x630__1.jpg`,
        extra: [] 
      },
      section: "horoscopo",
      sectionAds: "horoscopo",
      template: "Astrology",
      title: "Horóscopo: Astrología, Signos del Zodiaco, Predicciones y Horóscopo Chino",
      slug: "horoscopo",
      titleHeader: "Horóscopo",
      breadcrumb: [
        {
          title: "Horóscopo",
          url: "/horoscopo",
        }
      ]
    });
  }


  parseData = async (query) => {    
    let year = new Date().getFullYear();
    // slug = "astr";
    const zodiac = await this.fetchData(`http://api_editoriales.agea.com.ar:8000/api/zodiac/show/`);
    const zodiacChinese = getChineseHoroscope();
    const rawSignName = (query) => query ? (query.split('-')[2] || '') : '';
    let signName = rawSignName(query[1]); 
    const urlNormalize = signName?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
      console.log('miguel', signName)
    let slug = signName != "" ? `signo-de-${signName.toLowerCase()}` : "horoscopo"

    const urlNotes= `contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`
    const urlTags = `contents/v1/tags/${slug}?type=lilanews&limit=6&offset=0`    
    let notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}${urlTags}`);

    const prediction = await this.fetchData(`http://api_editoriales.agea.com.ar:8000/api/zodiac/weekly/${signName}`);
    const predictionItems = Array.isArray(prediction?.data?.items) ? prediction.data.items : [];

    if(query[2] && query[2] == "horoscopo-diario") {
      this.setTitleHeaderLink("/horoscopo");
      this.setTitleHeaderTag("p");      
      if(!signName) {
        signName = ""; 
      } else {
        signName = signName.charAt(0).toUpperCase() + signName.slice(1);
        if (signName == "Cancer") {
          signName = "Cáncer";
        } else if (signName == "Geminis") {
         signName = "Géminis";
        } 
      
      }

      this.setTemplate("DailyHoroscope");
      if(signName == ""){
        this.setMeta(this.parseMeta({ 
          description: `Consultá, según las astrología, las características de cada signo del zodiaco. Sus fortalezas y debilidades. Descubrí la compatibilidad de cada signo con otros signos. Cuales son sus elementos, que planeta los rige, sus metales, piedras, signos opuestos complementarios, sus partes del cuerpo asociadas.`, 
          keywords: "Signos zodiaco, características, fortalezas, debilidades, horóscopo chino, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. ", 
          title: `Astrología: Los 12 signos del Zodiaco y sus características.`, 
          url: `cosa golda`, 
          extra: [] 
        }));
      }else{
        this.setMeta(this.parseMeta({ 
          description: `Consultá el horóscopo de hoy y mañana para ${signName}. Predicción diaria del signo ${signName} para el ${year}. Toda la información, características y compatibilidad entre los signos del zodiaco. Todo sobre astrología y horóscopos lo encontras en Clarín.com`, 
          keywords: "Signos zodiaco, Astrología, características, fortalezas, debilidades, horóscopo chino, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis.", 
          title: `Astrología - Horóscopo Diario para  ${signName} -  Signos del Zodiaco.`, 
          url: `horoscopo/signos-zodiaco-${urlNormalize}/${[query[2]]}`, 
          extra: []
        }));
      }
      this.setSectionAds(urlNormalize);
      this.setTitle(`Horóscopo Diario ${signName}: Astrología, Signos del Zodiaco, Predicciones y Horóscopo Chino`)
      const zodiacItems = zodiac?.data?.items ?? [];
      const sign =  zodiacItems && zodiacItems?.filter(
        (value) => {
            if(value.sign == signName) {
                return value;
            };
        }
      );
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Horóscopo de hoy",
          url: "/horoscopo/horoscopo-diario",
        },
        {
          title: signName,
          url: `/horoscopo/signos-zodiaco-${urlNormalize}/horoscopo-diario`,
        },
      ])
      this.setData({
        sign : sign,
        zodiac : zodiacItems,
        notes: notes?.data?.items ?? [],
        isZodiacChinese: false,
        prediction: predictionItems,
        isTomorrow : false,
        isToday : true,
        slug: slug
      });
    } 

    if(query[2] && query[2] == "horoscopo-manana" ){
      const dateToday = new Date();
      let tomorrow = new Date(dateToday);
      tomorrow.setDate(dateToday.getDate() + 1);
      // Formatear la fecha en el formato deseado
      const dateTomorrow = tomorrow.toISOString().split('T')[0].replace(/-/g, '');
      const rawSignName = (query) => query ? (query.split('-')[2] || '') : '';
      let signName = rawSignName(query[1]); 
      signName = signName.charAt(0).toUpperCase()+ signName.slice(1);
      
      const zodiacTomorrow = await this.fetchData(`http://api_editoriales.agea.com.ar:8000/api/zodiac/daily/${signName}?date=${dateTomorrow}`);
      const zodiacItems = zodiac?.data?.items;
      const tomorrowItem = zodiacTomorrow?.data?.items;
      const sign =  tomorrowItem && tomorrowItem?.filter(
        (value) => {
            if(value.sign.charAt(0).toUpperCase()+ signName.slice(1) == signName) {
                return value;
            };
        }
      );
      // Reemplaza 'Hoy' por 'Mañana' en el array de datos si existe
      if (sign && sign[0]?.data) {
          sign[0].data.forEach(entry => {
              entry.nombre = entry.nombre.replace('Hoy', 'Mañana');
          });
      }
      this.setTemplate("TomorrowHoroscope");
      this.setTitle(`Horóscopo Mañana ${signName}: Astrología, Signos del Zodiaco, Predicciones y Horóscopo Chino`);
      this.setTitleHeader(`Horóscopo`);
      this.setMeta(this.parseMeta({ 
        description: `Consultá el horóscopo de mañana para ${signName}. Predicción diaria y semanal del signo ${signName} para el 2024. Toda la información, Amor, Riqueza Bienestar, características y compatibilidad entre los signos del zodiaco. Todo sobre astrología y horóscopos lo encontras en Clarín.com`, 
        keywords: `Signos zodiaco, Astrología, características, fortalezas, debilidades, horóscopo chino, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis.`,
        title: `Astrología - Horóscopo del día de mañana para ${signName}`, 
        url: `horoscopo/signos-zodiaco-${urlNormalize}/${[query[2]]}`,
      }));
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Horóscopo de Mañana",
          url: "/horoscopo/horoscopo-manana",
        },
        {
          title: signName,
          url: `/horoscopo/signos-zodiaco-${urlNormalize}/horoscopo-manana`,
        },
      ])
      this.setData({
        sign : sign ?? [],
        zodiac : zodiacItems,
        notes: notes?.data?.items ?? [],
        isZodiacChinese: false,
        prediction: predictionItems,
        isTomorrow : true,
        isToday : false,
        slug: slug
      });
    }

    if(query[1] && query[1] == "horoscopo-diario"){
      this.setTemplate("DailyHoroscopeLanding");
      this.setTitle(`Horóscopo Diario: Astrología, Signos del Zodiaco, Predicciones y Horóscopo Chino`);
      this.setMeta(this.parseMeta({ 
        description:"El signo solar se refiere al lugar donde se encontraba el Sol en el momento del nacimiento de una persona. Es “el signo” al que pertenecemos. Conocé las características, fortalezas, compatabilidades y debilidades de cada signo del zodiaco.",
        keywords:"horóscopo, año 2024, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. signos,zodiaco.",
        url: `horoscopo/horoscopo-diario`, 
      }));
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Horóscopo de Hoy",
          url: "/horoscopo/horoscopo-diario",
        },
      ])
      this.setData({
        zodiac : zodiac?.data?.items ?? [],
        notes: notes?.data?.items ?? [],
        zodiacChinese: zodiacChinese,
        prediction: "",
        isToday: true,
        seoText: "Descubrí el horóscopo de hoy para tu signo: qué te deparan hoy los astros en relación al amor, dinero, trabajo y salud. Predicciones del día para todos los signos del zodíaco.",
        slug: slug
      });
    }   

    if(query[1] && query[1] == "horoscopo-manana"){
      this.setTemplate("TomorrowHoroscopeLanding");
      this.setTitle(`Horóscopo Mañana: Astrología, Signos del Zodiaco, Predicciones y Horóscopo Chino`);
      this.setMeta(this.parseMeta({ 
        description:"El signo solar se refiere al lugar donde se encontraba el Sol en el momento del nacimiento de una persona. Es “el signo” al que pertenecemos. Conocé las características, fortalezas, compatabilidades y debilidades de cada signo del zodiaco.",
        keywords:"horóscopo, año 2024, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. signos,zodiaco.",
        url: `horoscopo/horoscopo-manana`, 
      }));
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Horóscopo de Mañana",
          url: "/horoscopo/horoscopo-manana",
        },
      ])
      this.setData({
        zodiac : zodiac?.data?.items ?? [],
        notes: notes?.data?.items ?? [],
        zodiacChinese: zodiacChinese,
        prediction: "",
        isTomorrow: true,
        slug: slug
      });
    }  

    if(query[1] && query[1] == "signos-zodiaco" && query[2] == undefined){
      this.setTemplate("HowIsHoroscopeLanding");
      this.setTitle(`Astrología, Predicciones y Características del Zodiaco`);
      this.setMeta(this.parseMeta({ 
        description:"El signo solar se refiere al lugar donde se encontraba el Sol en el momento del nacimiento de una persona. Es “el signo” al que pertenecemos. Conocé las características, fortalezas, compatabilidades y debilidades de cada signo del zodiaco.",
        keywords:"horóscopo, año 2024, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. signos,zodiaco.",
        url: `horoscopo/signos-zodiaco`, 
      }));
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Signos del Zodíaco",
          url: "/horoscopo/signos-zodiaco",
        },
      ])
      this.setData({
        zodiac : zodiac?.data?.items ?? [],
        notes: notes?.data?.items ?? [],
        zodiacChinese: zodiacChinese,
        prediction: [],
        isHowIs: true,
        slug: slug
      });
    }  

    if(query[1] && query[1] == "signos-zodiaco-"+signName && query[2] == undefined){
      notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}${urlTags}`);
      const zodiacItems = zodiac?.data?.items;
      const rawSignName = (query) => query ? (query.split('-')[2] || '') : '';
      let signName = rawSignName(query[1]); 
      signName = signName.charAt(0).toUpperCase()+ signName.slice(1);
      const zodiacHowIs = await this.fetchData(`http://api_editoriales.agea.com.ar:8000/api/zodiac/${signName}`);
      const howIsItem = zodiacHowIs?.data?.item;
      const signData = [
        {
          "sign": howIsItem?.nombre ?? null,
          "since": howIsItem?.caracteristicas?.fechaDesde ?? null,
          "until": howIsItem?.caracteristicas?.fechaHasta ?? null,
          "data": [
            {
              "nombre": "",
              "id": "1",
              "descripcion": howIsItem?.descripcion ?? "",
              "caracteristicas": howIsItem?.caracteristicas ?? {},
              "fortalezas": howIsItem?.fortalezas ?? "",
              "debilidades": howIsItem?.debilidades ?? "",
              "compatibilidad": howIsItem?.compatibilidad ?? "",
            }
          ]
        }
      ];
        
      if(signName == "Geminis"){
        signName = "Géminis"
      } else if (signName == "Cancer"){
        signName = "Cáncer"
      }
      this.setTemplate("HowIsHoroscope");
      this.setTitle(`Horóscopo ${signName}: Astrología, Signos del Zodiaco, Predicciones y Horóscopo Chino`);
      this.setMeta(this.parseMeta({ 
        description:"Horóscopo: características, fortalezas, compatabilidades y debilidades de cada signo del zodiaco.",
        keywords:"horóscopo, año 2024, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. signos,zodiaco.",
        url: `horoscopo/signos-zodiaco-${urlNormalize}`, 
      }));
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Horóscopo de "+signName,
          url: `/horoscopo/signos-zodiaco-${urlNormalize}`,
        },
      ])
      this.setData({
        sign : signData ?? [],
        zodiac : zodiacItems,
        notes: notes?.data?.items ?? [],
        zodiacChinese: zodiacChinese,
        prediction: predictionItems,
        isTomorrow: false,
        isHowIs: true,
        slug: slug
      });
    }  

    if(query[0] === "horoscopo" && query[1] == undefined ){
      this.setData({
        zodiac: zodiac?.data?.items ?? [],
        isZodiacChinese: false,
        notes: notes?.data?.items ?? [],
        zodiacChinese: zodiacChinese,
        slug: slug
      });
    }    

    if(query[1] && query[1] == "signos-zodiaco" && query[2] && query[2] == "predicciones-2024" && query[3] == undefined){
        
      this.setTemplate("YearPredictionsZodiacLanding");
      this.setTitle(`Horóscopo: Predicciones del 2024 para todos los signos del zodíaco`);
      this.setMeta(this.parseMeta({ 
        description:"¿Cómo será el año 2024 para cada uno de los signos del zodiaco? Conocé el horóscopo 2024 para tu signo zodiacal y cuáles son las predicciones según los tránsitos planetarios que irán cambiando el panorama astrológico. Todas las predicciones del año para los 12 signos.",
        keywords:"horóscopo, año 2024, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. signos,zodiaco.",
        url: `horoscopo/signos-zodiaco/${[query[2]]}`, 
      }));
      this.setTitleHeader(`Horóscopo - Predicciones 2024`);
      this.setBreadcrumb([
        {
          title: "Horóscopo",
          url: "/horoscopo",
        },
        {
          title: "Predicciones Signos del Zodíaco 2024",
          url: "/horoscopo/signos-zodiaco/predicciones-2024",
        },
      ])
      this.setData({
        zodiac : zodiac?.data?.items ?? [],
        notes: notes?.data?.items ?? [],
        zodiacChinese: zodiacChinese,
        prediction: [],
        isYearPredictionsZodiac: true,
        slug: slug
      });
    }

    if(query[1] && query[1] == "signos-zodiaco-"+signName && query[2] && query[2] == "predicciones-2024" && query[3] == undefined){
      const zodiacItems = zodiac?.data?.items;
      const rawSignName = (query) => query ? (query.split('-')[2] || '') : '';
      let signName = rawSignName(query[1]); 
      signName = signName.charAt(0).toUpperCase()+ signName.slice(1);
      const YearPredictionsZodiac = getYearPredictionsZodiac();
      if(signName == "Geminis"){
        signName = "Géminis"
      }else if(signName == "Cancer"){
        signName = "Cáncer"
      }
      const signYearData = YearPredictionsZodiac.filter((value) => value.signZodiac === signName);
      const yearData = signYearData[0];
      const characteristics = yearData?.characteristics.map(prediction => ({
        title: prediction.title,
        content: prediction.content
      }));
      const signData = [
        {
          "sign": yearData?.signZodiac ? yearData?.signZodiac : "",
          "since": yearData?.fechaDesde ? yearData?.fechaDesde : "",
          "until": yearData?.fechaHasta ? yearData?.fechaHasta : "",
          "data": [
            {
              "nombre": "",    
              "id": "1",
              "descripcion": yearData?.description ? yearData?.description : "",
              "caracteristicas": characteristics ? characteristics : [],
              "fortalezas": "",
              "debilidades": "",
              "compatibilidad": "",
            }
          ]
        }
      ];

      this.setTemplate("YearPredictionsZodiac");
      this.setTitle(`Horóscopo: Predicciones del año 2024 para el signo ${signName}`);
      this.setMeta(this.parseMeta({ 
        description: `¿Cómo será el año 2024 para ${signName}? Conocé el horóscopo 2024 para tu signo zodiacal y cuáles son las predicciones según los tránsitos planetarios que irán cambiando el panorama astrológico. Todas las predicciones del año para los 12 signos`,
        keywords:"horóscopo, año 2024, Aries, Tauro, Géminis, Cáncer, Leo, Virgo, Libra, Escorpio, Sagitario, Capricornio, Piscis. signos,zodiaco.",
        url: `horoscopo/signos-zodiaco-${urlNormalize}/${[query[2]]}`, 
      }));
      this.setTitleHeader(`Horóscopo - Predicciones 2024 para ${signName}`);
      this.setBreadcrumb([
        { title: "Horóscopo", url: "/horoscopo" },
        { title: "Predicciones 2024", url: "/horoscopo/signos-zodiaco/predicciones-2024" },
        { title: signName.charAt(0).toUpperCase()+ signName.slice(1), url: `/horoscopo/signos-zodiaco-${urlNormalize}/predicciones-2024` }
      ])

      this.setData({
        sign: signData ?? [],
        zodiac : zodiacItems,
        notes: notes?.data?.items ?? [],
        isZodiacChinese: false,
        prediction: predictionItems,
        isTomorrow: false,
        isHowIs: false,
        isYearPredictionsZodiac: true,
        slug: slug
      });
    }
  }
}
