import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getCookie } from "helpers";

const useAppTrue = (cookieApp) => {
  const router = useRouter();
  const [isApp, setIsApp] = useState(router.query.app === "true");

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    if (router.query.app === "true") {
      setIsApp(true);
      document.cookie = `app=true; expires=${date.toUTCString()}; path=/`;
    } else if (getCookie("app") === "true") {
      setIsApp(true);
    }
  }, []);

  return isApp;
};
export default useAppTrue;