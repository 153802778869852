export class Service {
  constructor({
    adsPath = "",
    breadcrumb = [],
    meta = [],
    section = "",
    sectionAds = "",
    slug = "",
    template = "",
    title = "",
    titleHeader = "",
    titleHeaderLink = "",
    titleHeaderTag = "h1",
    widthFull = false,
    showGoolgeOneTap,
    autorefresh,
    width100,
    bannersOn,
    enableHeader,
    enableFooter,
    pageType = ""
  }) {
    this.adsPath = adsPath;
    this.breadcrumb = breadcrumb;
    this.data = {};
    this.meta = this.parseMeta(meta);
    this.section = section;
    this.sectionAds = sectionAds;
    this.slug = slug;
    this.template = template;
    this.title = title;
    this.titleHeader = titleHeader;
    this.titleHeaderLink = titleHeaderLink;
    this.titleHeaderTag = titleHeaderTag;
    this.widthFull = widthFull;
    this.showGoolgeOneTap = showGoolgeOneTap;
    this.autorefresh = autorefresh;
    this.width100 = width100;
    this.bannersOn = bannersOn;
    this.enableHeader = enableHeader;
    this.enableFooter = enableFooter;
    this.pageType = pageType;
  }

  getAdsPath = () => {
    return this.adsPath;
  };

  setAdsPath = (adsPath) => {
    this.adsPath = adsPath;
  };

  getPageType = () => {
    return this.pageType;
  };

  setPageType = (pageType) => {
    this.pageType = pageType;
  };

  getData = () => {
    return this.data;
  };

  setData = (data) => {
    this.data = data;
  };

  getMeta = () => {
    return this.meta;
  };

  setMeta = (meta) => {
    this.meta = meta;
  };

  getWidthFull = () => {
    return this.widthFull;
  };

  setWidthFull = (widthFull) => {
    this.widthFull = widthFull;
  };

  getSection = () => {
    return this.section;
  };

  getSectionAds = () => {
    return this.sectionAds;
  };

  setSectionAds = (sectionAds) => {
    this.sectionAds = sectionAds;
  };

  getTemplate = () => {
    return this.template;
  };

  setTemplate = (template) => {
    this.template = template;
  };

  setTitle = (title) => {
    this.title = title;
  };

  getTitle = () => {
    return this.title;
  };

  setTitleHeader = (titleHeader) => {
    this.titleHeader = titleHeader;
  };

  getTitleHeader = () => {
    return this.titleHeader;
  };

  setTitleHeaderLink = (titleHeaderLink) => {
    this.titleHeaderLink = titleHeaderLink;
  };

  getTitleHeaderLink = () => {
    return this.titleHeaderLink;
  };

  setTitleHeaderTag = (titleHeaderTag) => {
    this.titleHeaderTag = titleHeaderTag;
  };

  getTitleHeaderTag = () => {
    return this.titleHeaderTag;
  };

  setBreadcrumb = (breadcrumb) => {
    this.breadcrumb = breadcrumb;
  };

  getBreadcrumb = () => {
    return this.breadcrumb;
  };

  getSlug = () => {
    return this.slug;
  };

  setSlug = (slug) => {
    this.slug = slug;
  };

  getEnableHeader = () => {
    return this.enableHeader;
  };

  setEnableHeader = (enableHeader) => {
    this.enableHeader = enableHeader;
  };

  getEnableFooter = () => {
    return this.enableFooter;
  };

  setEnableFooter = (enableFooter) => {
    this.enableFooter = enableFooter;
  };

  parseDataToFront() {
    return {
      adsPath: this.adsPath ?? null,
      breadcrumb: this.breadcrumb ?? null,
      data: this.data ?? null,
      meta: this.meta ?? null,
      section: this.section ?? null,
      sectionAds: this.sectionAds ?? null,
      slug: this.slug ?? null,
      template: this.template ?? null,
      title: this.title ?? null,
      titleHeader: this.titleHeader ?? null,
      titleHeaderLink: this.titleHeaderLink ?? null,
      titleHeaderTag: this.titleHeaderTag ?? null,
      widthFull: this.widthFull ?? null,
      showGoolgeOneTap: this.showGoolgeOneTap ?? true,
      autorefresh: this.autorefresh ?? true,
      width100: this.width100 ?? false,
      bannersOn: this.bannersOn ?? true,
      enableHeader: this.enableHeader ?? true,
      enableFooter: this.enableFooter ?? true,
      pageType: this.pageType ?? null
    };
  }

  parseMeta({
    description = "",
    keywords = "",
    title = "",
    url = "",
    urlImage = "",
    extra = []
  }) {
    return [
      { "http-equiv": "Content-Type", content: "text/html; charset=UTF-8" },
      { name: "viewport", content: "width=device-width, maximum-scale=5" },
      { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
      { name: "description", content: description },
      { name: "keywords", content: keywords },
      { name: "author", content: "Clarin.com" },
      { name: "twitter:card", content: "summary_large_image" },
      {
        name: "twitter:image:src",
        content: urlImage ?? "https://www.clarin.com/img/default.svg",
      },
      { name: "twitter:image:alt", content: "clarin.com" },
      { name: "twitter:site", content: "@clarincom" },
      { name: "twitter:creator", content: "@clarin" },
      { name: "twitter:domain", content: "clarin.com" },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      {
        property: "og:image",
        content: urlImage ?? "https://www.clarin.com/img/default.svg",
      },
      { property: "og:title", content: title },
      {
        property: "og:url",
        content: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/${url}`,
      },
      { property: "og:description", content: description },
      { property: "fb:pages", content: "156057357762712" },
      { property: "fb:app_id", content: "437491989970687" },
      { property: "og:type", content: "article" },
      { property: "article:section", content: "Clarín - Servicios" },

      ...extra,
    ];
  }

  fetchData = async (url, options = {}) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      return {
        code: 200,
        data: data,
        error: null,
      };
    } catch (error) {
      return {
        code: 500,
        data: null,
        error: error?.message ?? "",
      };
    }
  };

  parseData = () => {};
}
