import { useEffect, useState } from "react";
import { getImageForWidgetNews } from "helpers";

export const useClippingForPreview = (
  clippingsRequired,
  images,
  customClippings,
  selectedImageId,
  croppedImages
) => {
  const [imagePreview, setImagePreview] = useState();

  useEffect(() => {        
    let imageData = getImageForWidgetNews({
      clippingsRequired,
      images,
      selectedImageId,
      customClippings,
      croppedImages
    })  
    setImagePreview(imageData);
  }, [images, customClippings, selectedImageId, croppedImages]);

  return { imagePreview };
};
