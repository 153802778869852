const socialNetworking = [
  {
    name: "facebook",
    url: "https://www.facebook.com/clarincom",
  },
  {
    name: "twitter",
    url: "https://twitter.com/clarincom",
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/clarin/?originalSubdomain=ar",
  },
  {
    name: "pinterest",
    url: "https://ar.pinterest.com/clarincom",
  },
  {
    name: "whatsapp",
    url: "",
  },
  {
    name: "mail",
    url: "",
  },
  {
    name: "mail-b",
    url: "",
  },
  {
    name: "share",
    url: "https://www.clarin.com",
  },
];

export const getSocialNetworking = (filter = "") => {
  if (filter && Array.isArray(filter))
    return socialNetworking.filter((social) => {
      return filter.includes(social.name);
    });
  return socialNetworking;
};

export const showSharePopUp = (data, urlShare, title) => {
  let target = "";
  let parameter = "";
  let url = urlShare;

  if (typeof window !== "undefined" && window.innerWidth > 425) {
    const left = window.innerWidth / 2 - 300;
    const top = window.innerHeight / 2 - 50;
    parameter +=
      "menubar=no,toolbar=no,resizable=no,scrollbars=no,height=300,width=600,top=" +
      top +
      ",left=" +
      left;
    target = "_blank";
  }
  if (typeof data.name != "undefined" && data.name == "facebook") {
    if (typeof FB != "undefined") {
      FB.ui(
        {
          method: "share",
          href: url,
        },
        function (response) {}
      );
    } else {
      url = "https://www.facebook.com/sharer/sharer.php?u=https://www.clarin.com" + url;
      if (typeof window !== "undefined") {
        window.open(url, target, parameter);
      }
    }
  }
/*   if (typeof data.name != "undefined" && data.name == "twitter") {
    url =
      "https://twitter.com/share?url=www.clarin.com" +
      url +
      "?fromRef=twitter&text=" +
      title +
      "%20-";
    if (typeof window !== "undefined") {
      window.open(url, target, parameter);
    }
  } */
  if (typeof data.name !== "undefined" && data.name === "twitter") {
    const encodedUrl = encodeURIComponent(url);
    const encodedText = encodeURIComponent(title + "\n");
    
    const twitterUrl =
      `https://twitter.com/intent/tweet?url=https://www.clarin.com${encodedUrl}&text=${encodedText}`;
  
    if (typeof window !== "undefined") {
      window.open(twitterUrl, target, parameter);
    }
  }
  if (typeof data.name != "undefined" && data.name == "whatsapp") {
    url = "https://api.whatsapp.com/send?text=https://www.clarin.com" + url;
    if (typeof window !== "undefined") {
      window.open(url);
    }
  }
  if (typeof data.name != "undefined" && data.name == "mail") {
    url = "mailto:?subject=" + title + "&body=https://www.clarin.com" + url;
    if (typeof window !== "undefined") {
      window.open(url);
    }
  }
  if (typeof data.name != "undefined" && data.name == "share") {
    url = "mailto:?subject=" + title + "&body=https://www.clarin.com" + url;
    if (typeof window !== "undefined") {
      window.open(url);
    }
  }
};

