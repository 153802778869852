import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const useSetPageLastPage = (activate, offset, limit, total) => {

    const [page, setPage] = useState();
    const [lastPage, setLastPage] = useState();
    const [path, setPath] = useState();
    const [pathFirst, setPathFirst] = useState();
    const router = useRouter();

    useEffect(() => {

        setPage(Math.ceil(parseInt(offset) / parseInt(limit)) + 1);
        setLastPage(Math.ceil(parseInt(total) / parseInt(limit)));
        const regex = /\/page\/[0-9]{0,4}/;

        /* let pathModified = "";
        if (router.asPath.startsWith('/tema') && router.asPath.endsWith('.html')) {
            pathModified = router.asPath.replace('.html', '');
        } else {
            pathModified = router.asPath;
        }
        setPath(pathModified.replace(regex, '')); */
        let first = router.asPath.replace(regex, '')
        if((router.query?.match?.[0] === "autor" || router.query?.match?.[0] === "tema") && !first.includes('.html')) {
            first += ".html"
        }
        setPath(router.asPath.replace(/\.html$/, '').replace(regex, ''));
        setPathFirst(first);
        
    }, [activate])

    return {
        page,
        lastPage,
        path,
        pathFirst
    }

}

export default useSetPageLastPage;