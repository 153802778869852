import NodeRSA from 'node-rsa';

const pubKeyMrf = `
  -----BEGIN PUBLIC KEY-----
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3TXGyYwHoNN1erI/UKPDudVAQhSV+mguc0aYjMWXj6ck98gSP/8Kaum/etDqkkLFhE075T+PH4zkNUsAgT+ZhnzbCQQJKGSWQa5oJEuokX+tD+x2kyvnGZtJQboIDhBU1h3MEgQlkXZmbiBNm0gobX0Hw9CoZHk0NPbjSP19PQFMF0gvVJKLp24MFjSzsS6R27H0a88HmChy0fiO5ClrBDKCAek5d/ZA5tVzV7X1ERqGYTHY912vM9/M4GggdrJycZrvKq9ZZF6FiccQfEOoWBvMcPhYXEle+D+5rjepryvCsChXcouwCfI2gWTkjFrQhzPHoriUiQ485UEebbk2T+JC3hsT3uZl2PjnQb5l6PAG+M33FVai1hCp39YNny6X1a+vE3wW9K8g6mJO7B4s4Lx/Pa45Epmd4vajMqskKKWCXumg7Yoru+steI8M7jn39NJ+gmzpmaOR0NDrxDlZfK5zG5D0cL//wB9f1WnpkYBvxVKS2V/O02UQU9c/w9tls9uHFJWnIOSKLA81xSX1LdkcdVTa5SAPll/RsRiyAJAnYYiM95t/dmFEYAK9NPw43wbu8+7NiQLQYxwV8/mDhRZkLw35xc0YxTkwxZjBYah8AFWmYS3nXhe0O3WvIkH/h6TR5DZLh4omic8U4YRorGLFmRav31PCT0YZFg3P/o0CAwEAAQ==
  -----END PUBLIC KEY-----
`;
export const encryptText = async (data) => {
    const clientKey = new NodeRSA(pubKeyMrf);
    clientKey.setOptions({encryptionScheme: 'pkcs1_oaep'});
    const encrypted = await clientKey.encrypt(data, 'base64');
    return encrypted;
};