import { modules, suenios } from "@/templates/Clarin/Service/components/Lottery/dataLottery.js";
import { getDateWithPeriod, getMonthDay, getMonthDayYear, getWeekDayDayMonthDayYear } from "helpers";
import { Service } from "models/Service";

export class Lottery extends Service {
  constructor() {
    super(
      {
        adsPath: "loteriasyquinielas/home",
        meta: {
          description: "Encontrá los resultados de la lotería y quiniela: A la cabeza, Quiniela Nacional, Quiniela Provincia, Quini 6, Telekino, Loto, Loto 5, Toto Bingo, Quiniela Poceada, Brinco y más. Todos los sorteos en Clarin.com", 
          keywords: "Lotería, quiniela, nacional, provincial, córdoba, santa fe, telekino, quini 6, sorteos, jugadas", 
          title: "Loterías y quinielas: mirá los resultados de los sorteos más importantes de la Argentina", 
          url: `loterias-y-quinielas`, 
          extra: []
        },
        section: "loteriasyquinielas",
        sectionName: "Loterias y Quinielas",
        sectionAds: "loteria",
        template: "Lottery",
        title: "Loterías y quinielas: resultados de los sorteos en Argentina",
        description: `Loterías y quinielas: mirá los resultados del sorteo. Conocé los números ganadores de esta jugada y otros sorteos. Averigua a que número jugar según tus sueños. - Clarín.com`,
        slug:"loterias-y-quinielas",
        titleHeader: "Loterías y quinielas",
        breadcrumb: [
          {
            title: "Loterías y quinielas",
            url: "/loterias-y-quinielas",
          }
        ]
      }
    );
  }

  parseData = async (query) => {
    let parseData;
    const slug= this.getSlug();
    const { data } = await this.fetchData(`${process.env.NEXT_PUBLIC_API_CONTENTS}/api/loteria/v2/latest`);   
    if (query?.[1] === "suenos-significado") {
      this.setTemplate("LotteryDreams");
      this.setTitle(`Loterías y quinielas: Explicación de los sueños`)
      this.setBreadcrumb([
        {
          title: "Loterías y quinielas",
          url: "/loterias-y-quinielas",
        },
        {
          title: "Explicación de los sueños",
          url: "",
        }
      ]);
      this.setSectionAds("expliacionsuenos");
      this.setTitleHeaderLink("/loterias-y-quinielas");
      this.setTitleHeaderTag("p"); 
      this.setMeta(this.parseMeta({  
        url: `${slug}/${query?.[1]}`, 
        description: `Loterías y quinielas: Encontrá el significado de los números de la lotería y quiniela y a qué números jugar según tus sueños. Conocé todos los resultados de las jugadas más importantes - Clarín.com`, 
        keywords: "lotería, quiniela, sueños, explicación, números, suerte, jugadas, azar",
      })); 
    } else {       
      const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=9`);
      if(data?.items) {
        if (query?.[1]) {          
          this.setTitleHeaderLink("/loterias-y-quinielas");
          this.setTitleHeaderTag("p");
          const findModule = modules.find((element) => element.ancla == query[1]);
          const urlNormalize = findModule.nombre.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "")
          .toLowerCase();
          this.setSectionAds(urlNormalize);
          
          this.setBreadcrumb([
            {
              title: "Loterías y quinielas",
              url: "/loterias-y-quinielas",
            },
            {
              title: findModule.nombre,
              url: "",
            }
          ])
          if (findModule.type == "quiniela") {
            this.setTemplate("LotteryQuiniela")
            this.setTitle(`Loterías y quinielas: resultados de ` + findModule.nombre + ' hoy')
            parseData = {
              result: this.getDataQuinielas(data.items,[findModule]),
              notes: notes?.data?.items ?? [],
            };
          } else if (findModule.type == "loteria") {
            this.setTemplate("LotteryGame")
            this.setTitle(`Loterías y quinielas: resultados de ` + findModule.nombre)
            parseData = {
              result: this.getDataLottery(data.items,[findModule]),
              notes: notes?.data?.items ?? [],
            };
          }
        } else {
          parseData = {
            head: this.getDataHeadLottery(data.items,modules),
            quinielas: this.getDataQuinielas(data.items,modules),
            lottery: this.getDataLottery(data.items,modules),
            notes: notes?.data?.items ?? [],
          };
        }
      }
      if(query?.[1] != undefined){
        const findModule = modules.find((element) => element.ancla == query[1]);
        const urlNormalize = findModule.nombre.normalize("NFD");
        if (findModule.type == "quiniela") {
        this.setMeta(this.parseMeta({  
          url: `${slug}/${query?.[1]}`,
          description: `Loterías y quinielas: mirá los resultados de los sorteos de ` + findModule.nombre + ` hoy. Conocé los números ganadores de esta jugada y otros sorteos. Averigua a que número jugar según tus sueños. - Clarín.com`, 
          keywords: findModule.nombre + `, lotería, quiniela, sueños, explicación, números, suerte, jugadas, azar`, 
        })); } else {
          this.setMeta(this.parseMeta({  
            url: `${slug}/${query?.[1]}`,
            description: `Loterías y quinielas: mirá los resultados de los sorteos de ` + findModule.nombre + `. Conocé los números ganadores de esta jugada y otros sorteos. Averigua a que número jugar según tus sueños. - Clarín.com`, 
            keywords: findModule.nombre + `, lotería, quiniela, sueños, explicación, números, suerte, jugadas, azar`, 
          }));
        }
      }      
    }
    this.setData(parseData);
  }
  
  getDataHeadLottery = (items, modules) => {
    let head = [];
    modules.forEach((element) => {
      if (element.type == "quiniela") {
        const data = {
          name: element.nombre,
          games: [],
        };
        element.turnos.forEach((turno) => {
          const finded = items.find(
            (item) => item._id.slug === `${element.endpoint}_${turno}`
          );
          if (finded?._id?.jugada)
            data.games.push({
              game: `${finded._id.jugada} del ${getMonthDay(
                finded.juego?.fecha?.fecha
              )}`,
              number: finded.juego?.numeros?.[0]?.valor ?? "-",
            });
        });
        head.push(data);
      }
    });
    return head;
  };

  getDataQuinielas = (items, modules) => {
    let head = [];
    modules.forEach((element) => {
      if (element.type == "quiniela") {
        const data = {
          name: element.nombre,
          ancla: element.ancla,
          endpoint: element.endpoint,
          games: [],          
        };
        element.turnos.forEach((turno) => {
          const finded = items.find(
            (item) => item._id.slug === `${element.endpoint}_${turno}`
          );
          if (finded?._id?.jugada)
            data.games.push({
              lastSorteos: getDateWithPeriod(finded.lastSorteos?.fecha),
              game: `${finded._id.jugada} del ${getMonthDay(
                finded.juego?.fecha?.fecha
              )}`,
              numbers: finded.juego?.numeros,
              head: finded.juego?.numeros?.[0]?.valor,
              dream:
                suenios[
                  finded.juego?.numeros?.[0]?.valor
                    ?.toString()
                    .substring(
                      finded.juego?.numeros?.[0]?.valor?.toString().length - 2,
                      finded.juego?.numeros?.[0]?.valor?.toString().length
                    )
                ] ?? "-",
              letters: finded.juego?.letras ?? "",
            });
        });
        head.push(data);
      }
    });
    return head;
  };

  getDataLottery = (items,modules) => {
    let head = [];
    modules.forEach((element) => {
      if (element.type == "loteria") {
        const data = {
          name: element.nombre,
          ancla: element.ancla,
          endpoint: element.endpoint,
          games: [],
        };
        element.jugadas.forEach((jugada) => {
          const finded = items.find(
            (item) => item._id.slug === `${element.endpoint}${jugada}`
          );
          if (finded?._id?.jugada)
            data.games.push({
              lastSorteos: getDateWithPeriod(finded.juego?.fecha?.fecha),
              titleDate: getWeekDayDayMonthDayYear(finded.juego?.fecha?.fecha),
              game: finded._id.jugada,
              date: getMonthDayYear(finded.juego?.fecha?.fecha),
              numbers: finded.juego?.numeros ?? [],
              awards: finded.juego?.premios ?? [],
            });
        });
        head.push(data);
      }
    });
    return head;
  };
}
