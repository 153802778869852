import { useEffect, useState } from "react";
import { getUserProfile } from "helpers";

const useNewsletter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userAuth0, setUserAuth0] = useState(null);
  const [showNewsletters, setShowNewsletters] = useState(false);
	const [userId, setUserId] = useState("");
	const [dataSubscription, setDataSubscription] = useState([]);

  const getUserIdByEmail = async () => {
    const response = await fetch(`/api/eloqua/userIdByEmail/${userAuth0.email}`,{method: 'POST'});
    const { data } = await response.json();
    setUserId(data?.[0]?.id ?? "");
	}

  const getDataSubscription = async () => {
    const response = await fetch(`/api/eloqua/isSubscribed/${userId}`,{method: 'POST'});
    const { data } = await response.json(); 
    if(data){
      const suscribed = data.filter(element => element.isSubscribed);
      setDataSubscription(suscribed)
    }
	}

  useEffect(() => {
    let data = getUserProfile();
    setUserAuth0(data);
  }, []);

  useEffect(() => {
    if (userAuth0?.email) {
      setShowNewsletters(true);
      getUserIdByEmail();
    }
  }, [userAuth0]);

  useEffect(() => {
    if(userId) {
      getDataSubscription(userId)
    };
    setIsLoading(false);
	}, [userId])

  return { dataSubscription, showNewsletters, userAuth0, userId, userEmail: userAuth0?.email ?? "" };
};

export default useNewsletter;
