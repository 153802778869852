const getImageFromClippingList = (images, clipping, domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN) => {
  const imgIndex = images.findIndex((image) => {
    return image?._id === clipping || image?.id === clipping;
  });
  return imgIndex >= 0
    ? {
        image: images[imgIndex].url.includes(domain)
          ? images[imgIndex].url
          : domain + images[imgIndex].url,
        height: images[imgIndex].height || images[imgIndex].dimensions?.height,
        width: images[imgIndex].width || images[imgIndex].dimensions?.width
      }
    : {
        image: "#",
        height: "100",
        width: "100",
      };
};

export const getClipping = (clippingsRequired, images, domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN) => {
  let imageData = {
    image: "#",
    height: "100",
    width: "100",
  };
  if (Array.isArray(images) && images.length > 0) {
    for (let index = 0; index < clippingsRequired.length; index++) {
      imageData = getImageFromClippingList(images, clippingsRequired[index], domain);
      if (imageData.image !== "#") {
        break;
      }
    }
  }
  return imageData;
};

export const getImageByClippings = (
  clippingsRequired,
  images = [],
  domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN
) => {
  const { desktop = [], mobile = [] } = clippingsRequired;
  return {
    desktop: getClipping(desktop, images, domain),
    mobile: getClipping(mobile, images, domain),
  };
};

export const getCustomImage = (selectedImageId, customClippings, mobile, domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN) => {
  let desktop = {
    image: "#",
    height: "100",
    width: "100",
  };
  const customClippingList = customClippings[selectedImageId];
  if (customClippingList.length > 0) {
    const customClipping = customClippingList[customClippingList.length - 1];
    desktop = {
      image: domain + customClipping.url,
      width: customClipping.realWidth,
      height: customClipping.realHeight,
    };
  }
  return {
    desktop: desktop,
    mobile: mobile,
  };
};

export const getDataImageSelectedFromRelated = (related, domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN) => {
  if (
    related &&
    related.images &&
    related.images[0] &&
    related.images[0].selectedClipping &&
    related.images[0].selectedClipping.url
  ) {
    return {
      image: domain + related.images[0].selectedClipping.url,
      width: related.images[0].selectedClipping.dimensions?.width ?? "100",
      height: related.images[0].selectedClipping.dimensions?.height ?? "100",
    };
  }
  return {
    image: "#",
    height: "100",
    width: "100",
  };
};

export const getImageBySelectedClipping = (selectedClipping, domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN) => {
  if (selectedClipping && selectedClipping.url) {
    return {
      image: domain + selectedClipping.url,
      width: selectedClipping.dimensions?.width ?? "100",
      height: selectedClipping.dimensions?.height ?? "100",
    };
  }
  return {
    image: "#",
    height: "100",
    width: "100",
  };
};

export const getImageByCropped = (clippings = [], selectedImageId, domain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN) => {
  const desktop = clippings.find((clipping) => clipping.id === "Desktop" && clipping._id === selectedImageId);  
  const mobile = clippings.find((clipping) => clipping.id === "Mobile" && clipping._id === selectedImageId);
  return {
    desktop: {
      image: desktop?.url ? domain + desktop.url : "#",
      height: desktop?.height ? desktop.height : "100",
      width: desktop?.width ? desktop.width : "100",
    },
    mobile: {
      image: mobile?.url ? domain + mobile.url : "#",
      height: mobile?.height ? mobile.height : "100",
      width: mobile?.width ? mobile.width : "100",
    },
  };
}

export const getImageForWidgetNews = ({
  clippingsRequired,
  images,
  selectedImageId,
  customClippings,
  croppedImages,
  imageOriginUrl
}) => {
  let imageWeb =getImageByClippings(clippingsRequired, images, imageOriginUrl);
  const cropped = getImageByCropped(croppedImages, selectedImageId, imageOriginUrl);
  if(cropped.desktop?.image !== "#") imageWeb.desktop = cropped.desktop
  if(cropped.mobile?.image !== "#") imageWeb.mobile = cropped.mobile
  if(images?.[0]?.title) imageWeb.epigraphe = images[0].title
  return imageWeb;
};
