export const parseData = (elements, name, isArray = false) => {
  let list = [];
  let result = isArray ? "[]" : "";
  let separator = isArray ? ", " : "|";
  if (elements != undefined) {
    if (Array.isArray(elements)) {
      if (elements.length > 1) {
        elements.map((element) => {
          if (element[name]) {
            list.push(element[name]);
          } else if (!Array.isArray(element) && typeof element !== "object") {
            list.push(element);
          }
        });
        result = isArray ? "[" : "";
        list.forEach((element, index) => {
          let text =
            typeof element === "string" || element instanceof String
              ? element.trim()
              : element;
          if (isArray) {
            result += `'${text}'`;
          } else {
            result += `${text}`;
          }
          if (list[index + 1]) {
            result += `${separator}`;
          }
        });
        result += isArray ? "]" : "";
      } else {
        if (elements.length > 0 && elements[0].hasOwnProperty('name')) {
          if (isArray) {
            result = `['${elements[0].name}']`;
          } else {
            result = elements[0].name;
          }
        } else if (!Array.isArray(elements)) {
          if (isArray) {
            result = `['${elements[0]}']`;
          } else {
            result = elements[0];
          }
        }
      }
    } else {
      result = `['${elements}']`;
    }
  }
  return result;
};

export const parseSummary = (summary) => {
  let result = "[]";
  if (summary) {
    const withoutCommas = summary.replace("/,/g", "");
    const withoutPeriod = withoutCommas.replace("/./g", "");
    const toArray = withoutPeriod.split(" ");
    result = "[";
    toArray.forEach((element, index) => {
      let text =
        typeof element === "string" || element instanceof String
          ? element.trim()
          : element;
      result += `'${text}'`;
      if (toArray[index + 1]) {
        result += ", ";
      }
    });
    result += "]";
  }
  return result;
};

export const parseKeyword = (path) => {
  if (!path) {
    return "[]";
  }
  let toArray = path.split("_");
  toArray = toArray[0].split("/");
  toArray = toArray.slice(-1).pop();
  toArray = toArray.split("-");
  let result = "[";
  toArray.forEach((element, index) => {
    let text =
      typeof element === "string" || element instanceof String
        ? element.trim()
        : element;
    result += text && `'${text}'`;
    if (toArray[index + 1]) {
      result += ", ";
    }
  });
  result += "]";
  return result;
};

export const nsSite = (sections = []) => {
  let nsSiteString = "";
  for (let index = 0; index < sections.length; index++) {
    if (sections[index] && sections[index].nsSite) {      
      nsSiteString += sections[index].nsSite;
      /* if (parseInt(index) + 1 !== sections.length && sections[index + 1].nsSite) {
        nsSiteString += "|";
      } */
    }
    break;
  }
  return nsSiteString || "-2";
};

/* export const cdSections = (sections = []) => {
  let cdSections = "";
  for (let index = 0; index < sections.length; index++) {
    if (sections[index] && sections[index].name) {      
      cdSections += sections[index].name;
      if (parseInt(index) + 1 !== sections.length && sections[index + 1].name) {
        cdSections += "|";
      }
    }
  }
  return cdSections || "-2";
};
 */
export const cdSections = (sections = []) => {
  let cdSections = "";
  for (let index = 0; index < sections.length; index++) {
    if (sections[index] && sections[index]) {      
      cdSections += sections[index];
      if (parseInt(index) + 1 !== sections.length && sections[index + 1]) {
        cdSections += "|";
      }
    }
  }
  return cdSections || "-2";
};
