import { Service } from "models/Service";

export class Dialogues extends Service {
  constructor() {
    super({
      adsPath: "servicios/home",
      meta: {
        description: `Dialogos`,
        keywords: '',
        title: `Dialogos`,
        url: "dialogos",
        extra: [],
      },
      section: "dialogos",
      sectionName: "Dialogos",
      template: "Dialogues",
      title: `Dialogos`,
      widthFull: false,
      slug: "dialogos",
      titleHeader:"Dialogos"
    });    
  }
}