export const getStringWithoutAcute = (texto = "") => {
    var acentos = [
        { original: /&aacute;/g, reemplazo: "á" },
        { original: /&eacute;/g, reemplazo: "é" },
        { original: /&iacute;/g, reemplazo: "í" },
        { original: /&oacute;/g, reemplazo: "ó" },
        { original: /&uacute;/g, reemplazo: "ú" },
        { original: /&Aacute;/g, reemplazo: "Á" },
        { original: /&Eacute;/g, reemplazo: "É" },
        { original: /&Iacute;/g, reemplazo: "Í" },
        { original: /&Oacute;/g, reemplazo: "Ó" },
        { original: /&Uacute;/g, reemplazo: "Ú" }
      ];
    
      for (var i = 0; i < acentos.length; i++) {
        texto = texto.replace(acentos[i].original, acentos[i].reemplazo);
      }
    
      return texto;
}